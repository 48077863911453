import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { WebApiResponse } from '../model/ApiResponse';
import { AccountService } from '../services/account.service';
import { LoginService } from '../services/login.service';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-vieworder',
  templateUrl: './vieworder.component.html',
  styleUrls: ['./vieworder.component.css']
})
export class VieworderComponent implements OnInit {
  company_name: any;
  company_address1: any;
  company_address2: any;
  company_country: any;
  company_state: any;
  company_city: any;
  company_zipcode: any;
  company_logo: any;
  order_id: any;
  entity_unique_id: any;
  itemlist: any = [];
  display_order_id: any;
  order_date: any;
  total_amount: any;
  displaycustid: any;
  amount: any;
  discount: any;
  notes: string = '';
  IsLinkValid: boolean = false;
  IsLinkInvalid: boolean = false;
  isnoteserror: boolean = false;
  IsRejected: boolean = false;
  constructor(private activatedroute: ActivatedRoute, private accountservice: AccountService, private _subscriptionservice: SubscriptionService,
    private _loginservice: LoginService, private _router: Router) {

    this.order_id = this.activatedroute.snapshot.params["OrderId"];
    this.entity_unique_id = this.activatedroute.snapshot.params["EntityId"];
  }

  async ngOnInit() {
    await this.ValidateLink();

  }

  async ValidateLink() {
    let request = { entity_unique_id: this.entity_unique_id, order_unique_id: this.order_id };
    const response: WebApiResponse = await this._loginservice.VerifyOrderLink(request);
    if (response.code == 1) {
      let data: any = response.data;
      sessionStorage.setItem('access_token', data.token);
      this.IsLinkValid = true;
      await this.GetCompanyDetials();
      await this.GetOrderDetail();
    }
    else {
      this.IsLinkInvalid = true;
      alert(response.message);
    }
  }

  async GetCompanyDetials() {
    let request = { entity_unique_id: this.entity_unique_id };
    const response: WebApiResponse = await this.accountservice.GetCompanyDetials(request);
    if (response.code == 1) {
      let data: any = response.data;
      let filterresult = data.filter(x => x.entity_unique_id == this.entity_unique_id);
      if (filterresult.length > 0) {
        this.company_name = filterresult[0].entity_name;
        this.company_address1 = filterresult[0].entity_address1;
        this.company_address2 = filterresult[0].entity_address2;
        this.company_country = filterresult[0].entity_country_name;
        this.company_state = filterresult[0].entity_state_name;
        this.company_city = filterresult[0].entity_city;
        this.company_zipcode = filterresult[0].entity_zipcode;
        if (filterresult[0].entity_logo != undefined && filterresult[0].entity_logo != '') {
          this.company_logo = filterresult[0].entity_logo;
        }
        else {
          this.company_logo = 'assets/images/Infify-logo.png';
        }
      }
    }
  }

  async GetOrderDetail() {
    let request = { entity_unique_id: this.entity_unique_id, order_unique_id: this.order_id }
    const response: WebApiResponse = await this._subscriptionservice.GetOrderDetail(request);
    if (response.code == 1) {
      let data: any = response.data;
      this.itemlist = data[0].order_items;
      if (this.itemlist.length > 0) {
        for (let i of this.itemlist) {
          i.selling_price = i.selling_price.toFixed(2);
          i.amount = i.amount.toFixed(2);
        }
      }
      this.order_date = moment(data[0].order_date).format('MM/DD/YYYY');
      this.display_order_id = data[0].display_order_id;
      this.total_amount = data[0].total_amount.toFixed(2);
      this.amount = data[0].amount.toFixed(2);
      this.discount = data[0].discount_amount.toFixed(2);
      this.displaycustid = data[0].customer_unique_id.substring(0, 7);
    }
  }

  async onclickapproveorder() {
    let request = {
      entity_unique_id: this.entity_unique_id,
      order_unique_id: this.order_id,
      order_state: 1
    };
    const response: WebApiResponse = await this._subscriptionservice.ManageOrderState(request);
    if (response.code == 1) {
      alert(response.message);
      this._router.navigate(['vieworderinvoice/' + this.entity_unique_id + '/' + response.data]);
    }
    else {
      alert(response.message);
    }
  }

  async onclickrejectorder() {
    if (this.notes == undefined || this.notes == '') {
      this.isnoteserror = true;
      return;
    }
    let request = {
      entity_unique_id: this.entity_unique_id,
      order_unique_id: this.order_id,
      order_notes: this.notes
    };
    const response: WebApiResponse = await this._subscriptionservice.ManageOrderState(request);
    if (response.code == 1 && this.notes == '') {
      alert(response.message);
      this._router.navigate(['vieworderinvoice/' + this.entity_unique_id + '/' + response.data]);
    }
    else if (this.notes != '') {
      this.IsLinkValid = false;
      this.IsRejected = true;
      alert("Order Rejected");
    }
    else {
      alert(response.message);
    }
  }


}
