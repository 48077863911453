<header class="header">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <div class="fs-5 fw-bold cursor-pointer" (click)="Redirectback()">
      <i class="fas fa-chevron-left me-2"></i>Payment History
    </div>
    <div class="cursor-pointer">
      <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
    </div>
  </div>
</header>
<div class="container-xxl mx-0 px-lg-5 pt-20">
  <div class="section">
    <div class="1row 1justify-content-center">
      <div class="1col-md-8">
        <div class="1row">
          <div class="1col-md-12">
            <div class="shadow-panel p-4">
              <div class="d-flex justify-content-end mb-3">
                <div (click)="pdf.saveAs('SubscriptionInvoice.pdf')" class="btn 1btn-sm btn-primary btn-print">Download</div>
              </div>
              <kendo-pdf-export #pdf paperSize="A4" [scale]="0.7" margin="5mm">
                <div class="card border-0 mb-5 mb-xl-8">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="row mb-3">
                        <div class="col-md-12">
                          <img alt="Logo" [src]="company_logo" class="h-50px logo" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7">
                          <div>
                            <div>{{company_name}}</div>
                            <div>{{company_address1}} {{company_address2}}</div>
                            <div>{{company_city}}, {{company_state}} {{company_zipcode}}</div>
                            <div>{{company_country}}</div>
                            <div class="w-100px"><hr /></div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div>
                            <h2>Invoice</h2>
                          </div>
                          <div>INV- <span class="fw-bold">{{subscription_invoice_id}}</span></div>
                          <div>Date <span class="fw-bold">{{invoice_date}}</span></div>
                          <div>Duration <span class="fw-bold">{{invoice_from}} to {{invoice_to}}</span></div>
                          <div>Amount <span class="fw-bold">$ {{total_after_discount}} (USD)</span></div>
                          <div>Customer ID <span class="fw-bold">{{displaycustid}}</span></div>
                          <div><span class="fw-bold text-success" *ngIf="paid_status == 1">PAID</span></div>
                          <div><span class="fw-bold text-info" *ngIf="paid_status != 1">Pending</span></div>
                          <div class="w-100px"><hr /></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7">
                          <div>
                            <div>BILLED TO</div>
                            <div>{{name}}</div>
                            <div>{{shipping_address1}} {{shipping_address2}}</div>
                            <div>{{shipping_city}} {{shipping_state}} {{shipping_zipcode}}</div>
                            <div>{{shipping_country}}</div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="fw-bold">
                            Subscription
                          </div>
                          <div>ID <span class="fw-bold">169luJT1YkpkTkk6</span></div>
                        </div>
                      </div>
                      <div class="row mt-10">
                        <div class="col-md-12">
                          <div class="table-responsive mb-9">
                            <table class="table mb-3 border-bottom border-secondary" aria-describedby="Invoice Details">
                              <thead>
                                <tr class="border-bottom border-top border-secondary fs-6 fw-bold">
                                  <th class="min-w-175px pb-2">Description</th>
                                  <th class="min-w-70px text-end pb-2">Unit</th>
                                  <th class="min-w-80px text-end pb-2">Item Price</th>
                                  <th class="min-w-100px text-end pb-2">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="fw-bold fs-6 text-end" *ngFor="let item of sub_invoice_details">
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <i class="{{item.item_icon}} me-2"></i>
                                      {{item.item_description}}
                                    </div>
                                    <div class="d-flex align-items-center text-gray-600 fs-7" *ngFor="let i of item.response_price_breakdown">$ {{i.price}} * {{i.unit}} Units</div>
                                  </td>
                                  <td class="pt-6" *ngIf="item.item_unit > 0">{{item.item_unit}}</td>
                                  <td class="pt-6" *ngIf="item.item_unit == 0 || item.item_unit == null">-</td>
                                  <td class="pt-6" *ngIf="item.response_price_breakdown.length == 0">$ {{item.item_price}}</td>
                                  <td class="pt-6" *ngIf="item.response_price_breakdown.length > 0">-</td>
                                  <td class="pt-6 text-dark fw-bold"><label *ngIf="item.item_type == 4">- </label>$ {{item.total_amount}}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table mb-3" aria-describedby="Invoice Total">
                              <tbody>
                                <tr class="fw-bold fs-6 text-end">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2">Amount</td>
                                  <td class="min-w-100px text-end pb-2">$ {{total_amount}}</td>
                                </tr>
                                <tr class="fw-bold fs-6 text-end" *ngIf="discount_amount !='0.00'">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2">Discount</td>
                                  <td class="min-w-100px text-end pb-2">$ {{discount_amount}}</td>
                                </tr>
                                <tr class="fw-bold fs-6 text-end" *ngIf="credit_amount !='0.00'">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2">Payments</td>
                                  <td class="min-w-100px text-end pb-2">$ {{credit_amount}}</td>
                                </tr>
                                <tr class="fw-bold fs-6 text-end py-3" >
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2 border-top border-bottom border-secondary">Total</td>
                                  <td class="min-w-100px text-end pb-2 border-top border-bottom border-secondary">$ {{total_after_discount}}</td>
                                </tr>
                                <tr></tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </kendo-pdf-export>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
