<header class="header">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <div class="fs-5 fw-bold cursor-pointer" (click)="Redirectback()">
      <i class="fas fa-chevron-left me-2"></i>Invoice History
    </div>
    <div class="cursor-pointer">
      <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
    </div>
  </div>
</header>
<div class="container-xxl px-lg-5 pt-20">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="section">
    <div class="row mt-3">
      <div class="col-md-8 d-flex mb-3">
        <div class="row flex-grow-1">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row justify-content-between bg-primary bg-opacity-25 p-4 fw-bold fs-5 gx-5 border-top-left-radius-sm border-top-right-radius-sm mx-0">
                      <div class="col-3">Id</div>
                      <div class="col-2 col-md-2 text-end">Amount</div>
                      <div class="col-2 col-md-2">Status</div>
                      <div class="col-3 col-md-3">Date</div>
                      <div class="col-2 col-md-1"></div>
                    </div>
                    <div class="row justify-content-between p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let Invoice of InvoiceList; let i=index">
                      <div class="col-3">
                        <div>{{Invoice.subscription_invoice_id}}</div>
                      </div>
                      <div class="col-2 col-md-2 text-end">
                        $ {{Invoice.invoice_amount}}
                      </div>
                      <div class="col-2 col-md-2">
                        {{Invoice.paid_status}}
                      </div>
                      <div class="col-3 col-md-3">
                        {{Invoice.created_date}}
                      </div>
                      <div class="col-2 col-md-1">
                        <a class="text-decoration-underline" (click)="RedirectViewInvoice(Invoice.subscription_invoice_unique_id)">View</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex flex-column">
        <div class="row flex-grow-1 mb-3">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Quick Links</div>
              </div>
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Edit Subscription</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Pause Subscription</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Cancel Subscription</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Payment Method</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Payment History</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Update billing details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      
    </div>
  </div>
</div>

