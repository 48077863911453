import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { WebApiResponse } from '../model/ApiResponse';
import { AccountService } from '../services/account.service';
import { CustomerService } from '../services/customer.service';
import { SubscriptionService } from '../services/subscription.service';
export declare var CardTokenize: Function;


@Component({
  selector: 'app-vieworderinvoice',
  templateUrl: './vieworderinvoice.component.html',
  styleUrls: ['./vieworderinvoice.component.css']
})

export class VieworderinvoiceComponent implements OnInit {
  cardForm: FormGroup;
  customer_info: any;
  sub_invoice: any;
  sub_invoice_details: any;
  total_amount: any = 0;
  total_after_discount: any = 0;
  discount_amount: any = 0;
  invoice_id: any;
  entity_unique_id: any;
  invoice_date: any;
  credit_amount: any;
  name: any;
  shipping_address1: any;
  shipping_address2: any;
  shipping_city: any;
  shipping_state: any;
  shipping_country: any;
  shipping_zipcode: any;
  company_name: any;
  company_address1: any;
  company_address2: any;
  company_country: any;
  company_state: any;
  company_city: any;
  company_zipcode: any;
  paid_status: any;
  company_logo: any = 'assets/images/Infify-logo.png';
  subscription_invoice_id: any;
  customer_unique_id: any;
  entity_info: any;
  displaycustid: any;
  card_list: any = [];
  IsAddCard: boolean = false;
  static GetInstance: any;
    CardType: string;
    image: string;
    hideCardError: boolean;
  GatewaySettings: any;
  constructor(private _fb: FormBuilder, private _subscriptionservice: SubscriptionService, private activatedroute: ActivatedRoute, private _customerService: CustomerService, private _accountservice: AccountService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document, private _router: Router) {
    VieworderinvoiceComponent.GetInstance = this;
    this.cardForm = this._fb.group({
      "account_first_four": [''],
      "account_last_four": [''],
      "account_number": ['', Validators.required],
      "account_type": [''],
      "account_expiry_date": [''],
      "expMonth": ['', [Validators.required]],
      "expYear": ['', [Validators.required]],
      "account_token": [''],
      "account_cvv": [''],
      "entity_id": [''],
      "customer_unique_id": [''],
      "company_unique_id": [''],
      "is_default": false
    });


    this.invoice_id = this.activatedroute.snapshot.params["InvoiceId"];
    this.entity_unique_id = this.activatedroute.snapshot.params["EntityId"];

  }

  async ngOnInit() {
    if (this.invoice_id != '' && this.invoice_id != undefined) {
      await this.GetSubscriptionInvoiceDetail(this.invoice_id);
      await this.GetCustomerAccountInfo();
    }
  }


  async GetSubscriptionInvoiceDetail(invoice_id) {
    let request = { entity_unique_id: this.entity_unique_id, subscription_invoice_unique_id: invoice_id }
    const response: WebApiResponse = await this._subscriptionservice.GetSubscriptionInvoiceDetail(request);
    if (response.code == 1) {
      let data: any = response.data;
      this.customer_info = data.customer_info;
      this.entity_info = data.entity_info;
      this.sub_invoice = data.subscriptionInvoice;
      this.sub_invoice_details = data.invoice_details;
      this.name = this.customer_info.name;
      this.shipping_address1 = this.customer_info.shipping_address1;
      this.shipping_address2 = this.customer_info.shipping_address2;
      this.shipping_city = this.customer_info.shipping_city;
      this.shipping_state = this.customer_info.shipping_state;
      this.shipping_country = this.customer_info.shipping_country;
      this.shipping_zipcode = this.customer_info.shipping_zipcode;
      this.customer_unique_id = this.customer_info.customer_unique_id;
      this.displaycustid = this.customer_unique_id.substring(0, 7);
      this.company_name = this.entity_info.entity_name;
      this.company_address1 = this.entity_info.entity_address1;
      this.company_address2 = this.entity_info.entity_address2;
      this.company_country = this.entity_info.entity_country_name;
      this.company_state = this.entity_info.entity_state_name;
      this.company_city = this.entity_info.entity_city;
      this.company_zipcode = this.entity_info.entity_zipcode;
      if (this.entity_info.entity_logo != undefined && this.entity_info.entity_logo != '') {
        this.company_logo = this.entity_info.entity_logo;
      }
      else {
        this.company_logo = 'assets/images/logo1.png';
      }
      this.sub_invoice = data.subscriptionInvoice;
      this.sub_invoice_details = data.invoice_details;
      this.subscription_invoice_id = this.sub_invoice.subscription_invoice_id;
      this.invoice_date = moment(this.sub_invoice.invoice_date).format('MM/DD/YYYY');
      this.paid_status = this.sub_invoice.paid_status;
      for (let i of this.sub_invoice_details) {

        if (i.item_type == 4 || i.item_type == 10) {
          this.discount_amount += i.total_amount;
        }
        else {
          this.total_amount += i.total_amount;
        }
        i.item_price = i.item_price.toFixed(2);
        i.total_amount = i.total_amount.toFixed(2);

      }
      this.total_after_discount = this.total_amount - (this.discount_amount + this.sub_invoice.credit_amount);
      this.total_amount = this.total_amount.toFixed(2);
      this.total_after_discount = this.total_after_discount.toFixed(2);
      this.discount_amount = this.discount_amount.toFixed(2);
      this.credit_amount = this.sub_invoice.credit_amount.toFixed(2);
    }

  }

  async GetCustomerAccountInfo() {
    let request = { company_unique_id: this.entity_unique_id, customer_unique_id: this.customer_unique_id }
    const response: WebApiResponse = await this._customerService.GetAccountDetails(request);
    if (response.code == 1) {
      this.card_list = response.data;
    }
  }

  async Save() {
    if (this.cardForm.invalid) {
      return;
    }
    this.cardForm.get('company_unique_id').setValue(sessionStorage.getItem('company_unique_id'));
    this.cardForm.get('account_type').setValue(this.CardType);
    this.cardForm.get('account_first_four').setValue(this.cardForm.get('account_number').value.substring(0, 5));
    this.cardForm.get('account_last_four').setValue(this.cardForm.get('account_number').value.substring(this.cardForm.get('account_number').value.length - 4));
    this.cardForm.get('account_expiry_date')?.setValue(this.cardForm.get('expMonth')?.value + this.cardForm.get('expYear')?.value);
    let n = document.getElementById('onepayResultToken') as HTMLInputElement;
    n.value = '';
    let result = CardTokenize("cardNumber", "expMonth", "expYear", "securityCode", "cardError", "onepayResultToken");
    if (result) {
      let res = (document.getElementById('onepayResultToken') as HTMLInputElement).value;
      let data = JSON.parse(res);
      VieworderinvoiceComponent.GetInstance.cardForm.get('account_number')?.setValue('');
      if (data.result_code == 1) {
        VieworderinvoiceComponent.GetInstance.cardForm.get('account_token')?.setValue(data.token);
        const respons: WebApiResponse = await VieworderinvoiceComponent.GetInstance._accountservice.ManageAccount(VieworderinvoiceComponent.GetInstance.cardForm.value);
        if (respons.code == 1) {
          this.cardForm.reset();
          this.ngOnInit();
          this.CardType = '';
        }
        else {
          alert(data.message);
        }
      }
    }
  }

  async ShowAddCard() {
    this.IsAddCard = true;
    await this.fetchgatewayconfiguration();
    await this.LoadPaymentTokenScript();
  }

  async fetchgatewayconfiguration() {
    let request = { entity_id: 0 };
    let response = await this._accountservice.FetchGatewayConfiguration(request);
    if (response.code == 1) {
      this.GatewaySettings = response.data;
    }
  }


  async LoadPaymentTokenScript() {

    let checkoutScript = this._renderer2.createElement('script');
    checkoutScript.type = "text/javascript";
    checkoutScript.src = this.GatewaySettings.gateway_jsurl;
    checkoutScript.setAttribute("data-paymenttokenkey", this.GatewaySettings.gateway_jskey);
    this._renderer2.appendChild(this._document.body, checkoutScript);

  }

  async onclickpayinvoice() {
    let request = {
      entity_unique_id: this.entity_unique_id,
      customer_unique_id: this.customer_unique_id,
      order_invoice_unique_id: this.invoice_id,
      order_state: 2
    };
    const response: WebApiResponse = await this._subscriptionservice.ManageOrderState(request);
    if (response.code == 1) {
      alert(response.message);
    }
    else {
      alert(response.message);
    }
  }


  onKeyCardNumber(event) {
    this.CardType = '';
    this.hideCardError = true;
    var number = this.cardForm.get('account_number').value;

    var re = new RegExp("^4");
    var revisa = new RegExp("^4");
    if (number.match(re) != null) {
      var number = this.cardForm.get('account_number').value;
      if (number.length <= 8) {
        if (number.match(re) != null) {
          this.image = "assets/images/visa.svg";
          this.CardType = 'VI';
          return;
        }
      }
      if (number.length >= 16) {
        if (this.luhnCheck(number)) {
          this.image = "assets/images/visa.svg";
          this.CardType = 'VI';
          return;
        }
        else {
          if (this.CardType == '') {
            this.hideCardError = false;
            return;
          }
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    if (number.length <= 8) {
      if (/^(5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12})$/.test(number)) {
        this.image = "assets/images/mastercard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^(5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12})$/.test(number) && number.length >= 16) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/mastercard.svg";
        this.CardType = 'MC';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    // AMEX
    re = new RegExp("^3[47]");
    if (number.length <= 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/American.svg";
        this.CardType = 'AX';
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 15) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/American.svg";
        this.CardType = 'AX';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.length == 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/Discover.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 16) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/Discover.svg";
        this.CardType = 'DC';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          this.image = "";
          return;
        }
      }
    }

    // Diners
    var number = this.cardForm.get('account_number').value;
    re = new RegExp("^36");
    if (number.length <= 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/diners-clubcard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 14) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        this.CardType = 'DS';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.length <= 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/diners-clubcard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 14) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        this.CardType = 'DS';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.length == 6) {
      if (number.match(re) != null) {
        this.image = "assets/images/Jcbcard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 15) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/Jcbcard.svg";
        this.CardType = 'JC';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }
    if (number.length >= 16) {
      if (this.CardType == '') {
        this.hideCardError = false;
        return;
      }
    }
    if (number.length == 0) {
      this.image = "";
      this.CardType = '';
    }
  }
  luhnCheck(number): boolean {
    let odd = true;
    let i = 0;
    let sum = 0;
    let digit: number;

    if (!number) {
      return false;
    }
    const digits = number.split('').reverse();
    const length = digits.length;
    for (i; i < length; i++) {
      digit = parseInt(digits[i], 10);
      if (odd = !odd) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  }


}
