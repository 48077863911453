import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../shared/AppConfig';
import { WebApiResponse } from '../model/ApiResponse';
@Injectable()
export class LoginService {
  protected _appset = AppConfig.settings;
  constructor(private _httpClient: HttpClient) { }

  async CheckUserEmail(data: any) {
    const url = this._appset.APIUrl + "VerifyCustomerEmail";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }
  async VerifyPasscode(data: any) {
    const url = this._appset.APIUrl + "VerifyPasscode";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }

  async VerifyOrderLink(data: any) {
    const url = this._appset.APIUrl + "VerifyOrderLink";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }

  async ResendOtp(data: any) {
    const url = this._appset.APIUrl + "ResendOtp";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }
  
}
