
<div class="h-100 d-flex flex-column justify-content-center" *ngIf="hideLogin">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto my-10">
    <form class="form w-100" id="kt_sign_in_form" [formGroup]="loginForm" novalidate="novalidate">
      <div class="text-center mb-10">
        <h2 class="text-dark mb-5 fw-bold fs-4">Sign In to User Portal</h2>
      </div>
      <div [ngClass]="AlertType == 'danger' ? 'alert text-danger' : 'text-success'" class="mb-4" role="alert" *ngIf="IsAlert">
        {{AlertMessage}}
      </div>
      <div *ngIf="IsShowEmail">
        <div class="fv-row mb-10">
          <label class="form-label">Email</label>
          <input class="form-control form-control-lg" type="text" name="email" formControlName="email" placeholder="Enter your email" autocomplete="off" (keydown)="onKeydownMain($event)" />

        </div>
        <div class="text-center">
          <button (click)="CheckUserEmail()" type="button" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 1mb-5">
            <span class="indicator-label">Continue</span>
          </button>
        </div>
      </div>
      <div *ngIf="IsEmailVerified">
        <div class="fv-row mb-10">
          <label class="form-label 1fs-6 1fw-bolder 1text-dark">{{textValue}}</label>
          <input class="form-control form-control-lg" type="password" name="passcode" formControlName="passcode" placeholder="Enter {{textValue}}" autocomplete="off" (keydown)="onKeyPasscode($event)" />
          <div id='timerData' class="kt-font-warning messagealerts" *ngIf="timer">Code expires in {{countdown}} seconds.</div>
        </div>
        
        <a (click)="ResendOtp()" *ngIf="ResendOTP">Resend Code</a>
        <div class="text-center">
          <button type="button" (click)="Verifypasscode()" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5">
            <span class="indicator-label">Continue</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="alert text-danger" role="alert" *ngIf="LoginError">
  {{loginErrorMessage}}
</div>
