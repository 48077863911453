<header class="header">
  <div class="container-fluid d-flex justify-content-between align-items-center" *ngIf="!isedit">
    <div class="fs-5 fw-bold cursor-pointer" (click)="Redirectback()">
      <i class="fas fa-chevron-left me-2"></i>Payment Method
    </div>
    <div class="cursor-pointer">
      <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
    </div>
  </div>
  <div class="container-fluid d-flex justify-content-between align-items-center" *ngIf="isedit">
    <div class="fs-5 fw-bold cursor-pointer" (click)="Redirectbackcard()">
      <i class="fas fa-chevron-left me-2"></i>Edit Card
    </div>
    <div class="cursor-pointer">
      <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
    </div>
  </div>
</header>
<div class="container-xxl px-lg-5 pt-20">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="section">
    <div class="row mt-3">
      <div class="col-md-8 d-flex mb-3">
        <div class="row flex-grow-1">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel 1mb-6 1mb-xl-9 flex-grow-1">
              <div class="card-body p-6">
                <div class="row d-flex justify-content-center" *ngIf="IsAddCard">
                  <div class="col-md-8 justify-content-center">
                    <form [formGroup]="cardForm" novalidate="novalidate" id="kt_sign_in_form" action="#">
                      <input id="onepayResultToken" type="hidden" />
                      <div class="text-danger" id="cardError" name="cardError"></div>
                      
                        <div class="">
                          <div class="row">
                            <div class="col-md-12">
                              <label for="addcardnumber">Card Number</label>
                              <div class="position-relative">
                                <input type="text" formControlName="account_number" class="form-control" id="cardNumber" name="cardNumber" placeholder="Card Number" maxlength="16" (keyup)="onKeyCardNumber($event)">
                                <div class="position-absolute top-right-7px">
                                  <img *ngIf="CardType != ''" [src]="image" style="width: 40px;" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-6">
                              <label class="required form-label">Expiry Month</label>
                              <select class="form-control" id="expMonth" formControlName="expMonth">
                                <option value="">Month *</option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
        
        
                            </div>
                            <div class="col-md-6">
                              <label class="required form-label">Expiry Year</label>
                              <select class="form-control" id="expYear" formControlName="expYear">
                                <option value="">Year *</option>
                                <option value="22">2022</option>
                                <option value="23">2023</option>
                                <option value="24">2024</option>
                                <option value="25">2025</option>
                                <option value="26">2026</option>
                              </select>
        
                            </div>
                          </div>
                          <div class="row mb-3">
                            <div class="col-md-6">
                              <label for="addcardcvv">CVV</label>
                              <div class="position-relative">
                                <input type="text" formControlName="account_cvv" maxlength="4" class="form-control" id="securityCode" name="securityCode" placeholder="CVV">
                                <span class="position-absolute top-right-10px cursor-pointer" popover="I will hide on blur" triggers="mouseenter:mouseleave">
                                  <i class="fa fa-info-circle text-muted"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-between mb-3">
                            <div class="col-md-6">
                              <label class="me-2">
                                <input type="checkbox" class="option-input checkbox" formControlName="is_default" />
                                Save card as Default
                              </label>
                            </div>
                            <div class="col-md-6 d-flex justify-content-end">
                              <button [disabled]="cardForm.invalid || CardType == ''" (click)="Save()" class="btn btn-sm btn-primary">Add</button>
                            </div>
                          </div>
                        </div>
                    </form>
                  </div>
                </div>
                <div class="row mb-3" *ngIf="card_list.length > 0 && Showcards">
                  <div class="col-md-12">
                    <div class="shadow-panel mt-3 p-2 px-75rem py-0">
                      <div class="row p-2 border-bottom cursor-pointer row-hover" *ngFor="let card of card_list" (click)="editcard(card)">
                        <div class="col-9 d-flex justify-content-start align-items-center">
                          <div class="me-2">
                            <span *ngIf="card.account_type == ''"><i class="fas fa-credit-card fs-5 text-muted me-2"></i></span>
                            <img *ngIf="card.account_type == 'VI'" src="assets/images/visa.svg" style="width: 40px;" />
                            <img *ngIf="card.account_type == 'MC'" src="assets/images/mastercard.svg" style="width: 40px;" />
                            <img *ngIf="card.account_type == 'AX'" src="assets/images/American.svg" style="width: 40px;" />
                            <img *ngIf="card.account_type == 'DC'" src="assets/images/Discover.svg" style="width: 21px;" />
                          </div>
                          <div class="fw-bold">XXXX-XXXX-XXXX-{{card.account_last_four}}</div>
                        </div>
                        <div class="col-3 d-flex justify-content-end align-items-center">
                          <div *ngIf="card.is_default">
                            <button class="btn btn-sm btn-danger">Primary</button>
                          </div>
                          <div class="cursor-pointer">
                            <i class="fas fa-angle-right fs-6 ms-3"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="AddCardBtn">
                  <div class="col-12 d-flex justify-content-center">
                    <button class="btn btn-sm btn-primary" (click)="ShowAddCard()">Add New</button>
                  </div>
                </div>
                <div class="row mt-3" *ngIf="isedit">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-12 d-flex flex-column align-items-center mb-3">
                        <div class="mb-3">
                          <span *ngIf="card_type == ''"><i class="fas fa-credit-card fs-5 text-muted me-2"></i></span>
                          <img *ngIf="card_type == 'VI'" src="assets/images/visa.svg" style="width: 50px;" />
                          <img *ngIf="card_type == 'MC'" src="assets/images/mastercard.svg" style="width: 50px;" />
                          <img *ngIf="card_type == 'AX'" src="assets/images/American.svg" style="width: 50px;" />
                          <img *ngIf="card_type == 'DC'" src="assets/images/Discover.svg" style="width: 50px;" />
                        </div>
                        <div class="fs-5">XXXX-XXXX-XXXX-{{last_four}}</div>
                      </div>
                      <div class="col-12 d-flex justify-content-start">
                        <div class="fs-6 fw-bold">Set this payment method as</div>
                      </div>
                    </div>
                    <div class="shadow-panel mt-3 p-2 px-75rem py-0">
                      <div class="row p-2 border-bottom">
                        <div class="col-12 d-flex justify-content-start align-items-start">
                          <div class="me-3">
                            <input type="radio" class="option-input radio" name="example" [checked]="is_default_card" (change)="changecarddefault(true)" />
                          </div>
                          <div>
                            <div class="fs-6 fw-bold">Primary</div>
                            <div class="fs-7 text-muted">This will be charged for all your automatic payments.</div>
                          </div>
                        </div>
                      </div>
                      <div class="row p-2 border-bottom">
                        <div class="col-12 d-flex justify-content-start align-items-start">
                          <div class="me-3">
                            <input type="radio" class="option-input radio" name="example" [checked]="!is_default_card" (change)="changecarddefault(false)" />
                          </div>
                          <div>
                            <div class="fs-6 fw-bold">Backup</div>
                            <div class="fs-7 text-muted">This will be the backup when your primary payment method fails.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex flex-column">
        <div class="row flex-grow-1 mb-3">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Quick Links</div>
              </div>
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Payment Method</a>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Payment History</a>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Update billing details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
