import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CardMonth'
})
export class CardMonth implements PipeTransform {
  transform(value: string) {
    value = value.substring(0, 2);
    if (value == '01') {
      return 'Jan';
    }
   else  if (value == '02') {
      return 'Feb';
    }
    else if (value == '03') {
      return 'Mar';
    }
    else if (value == '04') {
      return 'Apr';
    }
    else if (value == '05') {
      return 'May';
    }
    else if (value == '06') {
      return 'Jun';
    }
    else if (value == '07') {
      return 'Jul';
    }
    else if (value == '08') {
      return 'Aug';
    }
    else if (value == '09') {
      return 'Sep';
    }
    else if (value == '10') {
      return 'Oct';
    }
    else if (value == '11') {
      return 'Nov';
    }
    else if (value == '12') {
      return 'Dec';
    }

    return 'Month Not Found';
  }
}
