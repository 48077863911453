import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebApiResponse } from '../model/ApiResponse';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  subscriptionlist: any = [];
  SingleSubscriptionList: any = [];
  MultipleSubscriptionList: any = [];
  InsideMutipleList: any;
  constructor(private _subscriptionservice: SubscriptionService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    document.documentElement.style.setProperty("--theme-color", '#009ef7');
    this.GetSubscriptionList();
  }


  async GetSubscriptionList() {
    this.spinner.show();
    let request = { "email": sessionStorage.getItem('user_email'), "entity_id": sessionStorage.getItem('entity_id') };
    let response: WebApiResponse = await this._subscriptionservice.GetSubscriptionList(request);
    if (response.code == 1) {
      this.subscriptionlist = response.data;

      sessionStorage.setItem('CompanyTheme', this.subscriptionlist[0].company_theme);
      let themecolor = window.sessionStorage.getItem("CompanyTheme");
      if (themecolor == null) {
        themecolor = '#009ef7'
      }
      document.documentElement.style.setProperty("--theme-color", themecolor);
      let themecolorrgb = this.hexToRGBA(themecolor, 25);
      document.documentElement.style.setProperty("--theme-color-rgb", themecolorrgb);

      let group = this.subscriptionlist.reduce((r, a) => {
        r[a.company_unique_id] = [...r[a.company_unique_id] || [], a];
        return r;
      }, {});

      var SubcriptionArray: any = Object.values(group);
      for (var i of SubcriptionArray) {
        if (i.length > 1) {
          this.MultipleSubscriptionList.push(i[0]);
        }
        else if (i.length == 1) {
          for (var k of i) {
            this.SingleSubscriptionList.push(k);
          }
        }
      }
      if (this.SingleSubscriptionList.length > 0) {
        for (var i of this.SingleSubscriptionList) {
          i.total_subscription_price = i.total_subscription_price.toFixed(2);
        }
      }
      if (this.SingleSubscriptionList.length == 1 && this.MultipleSubscriptionList.length == 0) {

        sessionStorage.setItem('company_unique_id', this.SingleSubscriptionList[0].company_unique_id);
        this.router.navigateByUrl('/viewsubscription/' + this.SingleSubscriptionList[0].company_unique_id + '/' + this.SingleSubscriptionList[0].subscription_unique_id + '/' + this.SingleSubscriptionList[0].customer_unique_id);
      }
    }
    this.spinner.hide();
  }

  GetMultipleInside(entity_id) {
    this.InsideMutipleList = this.subscriptionlist.filter(x => x.entity_id == entity_id);
    for (var i of this.InsideMutipleList) {
      i.total_subscription_price = i.total_subscription_price.toFixed(2);
    }
  }


  viewsubscription(sub) {
    sessionStorage.setItem('company_unique_id', sub.company_unique_id);
    this.router.navigateByUrl('/viewsubscription/' + sub.company_unique_id + '/' + sub.subscription_unique_id + '/' + sub.customer_unique_id);
  }

  hexToRGBA(hex, opacity) {
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).concat(opacity + '%').join(',') + ')';
  }

}
