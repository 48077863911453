import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebApiResponse } from '../model/ApiResponse';
import { AppConfig } from '../shared/AppConfig';

@Injectable()
export class CustomerService {

  protected _appset = AppConfig.settings;
  constructor(private _httpClient: HttpClient) { }

  async GetAccountDetails(data: any) {
    const url = this._appset.APIUrl + "FetchAccountDetails";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      var res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
  async GetCouponByPromoCode(data: any) {
    const url = this._appset.APIUrl + "GetCouponByPromoCode";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      var res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
  async GetCouponByPromoCodeCheckout(data: any,apikey:any) {
    const url = this._appset.APIUrl + "GetCouponByPromoCodeCheckout";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('ApiKey', apikey)
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      var res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

}
