import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebApiResponse } from '../model/ApiResponse';
import { CustomerService } from '../services/customer.service';
import { Location } from '@angular/common';
import { SubscriptionService } from '../services/subscription.service';
import { SettingRequest } from '../model/subscription';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-viewsubscription',
  templateUrl: './viewsubscription.component.html',
  styleUrls: ['./viewsubscription.component.css']
})
export class ViewsubscriptionComponent implements OnInit {
  subscription_unique_id: string = "";
  subscription_name: string = "";
  is_active: number = 1;
  customer_name: string = "";
  customer_email: string = "";
  plan_name: string = "";
  plan_quantity: any;
  plan_amount: any;
  plan_currency: any;
  IsTieredPlan: boolean = false;
  pricelist: any;
  pricing_structure_name: string = "";
  plan_unique_id: any;
  pricing_structure: number = 0;
  IsMeteredPlan: boolean = false;
  SubscriptionStartDate: any;
  NextBillingDate: any;
  addonlist: any = [];
  chargelist: any = [];
  couponlist: any = [];
  TotalAmounts: number = 0;
  TotalUnits: number = 0;
  customer_unique_id: string;
  plan_price_breakdown: any = [];
  frequency_name: any;
  exist_subscription_price: number = 0;
  next_subscription_price: any;
  customer_default_card: any = [];
  expiry_date: any;
  account_last_four: any;
  account_type: any;
  subscription_status: any;
  total_discount_price: any = 0;
  IsPauseConfirmation: boolean = false;
  IsCancelConfirmation: boolean = false;
  customerportalsettings: any;
  allowchangesubscription: boolean = true;
  allowpausesubscription: boolean = true;
  allowreactivatesubscription: boolean = true;
  allowcancelsubscription: boolean = true;
  toastButtons: IToastButton[] = [
    {
      id: "1",
      title: "view jobs 1"
    },
    {
      id: "2",
      title: "view jobs 2"
    }
  ];
    company_unique_id: any;

  constructor(private _subscriptionservice: SubscriptionService, private activatedroute: ActivatedRoute, private router: Router,
    private spinner: NgxSpinnerService, private _customerService: CustomerService, private _location: Location, private toastr: ToastrService) {
    this.subscription_unique_id = this.activatedroute.snapshot.params["SubscriptionUniqueId"];
    this.customer_unique_id = this.activatedroute.snapshot.params["CustomerUniqueId"];
    this.company_unique_id = this.activatedroute.snapshot.params["EntityId"];
  }

  ngOnInit() {

    this.getSettings();
    this.ApplyCustomerPortalSettings();
    this.GetSubscriptionDetails();
    this.GetCustomerAccountInfo();

  }

  async getSettings() {
    this.spinner.show();
    var req = new SettingRequest();
    req.setting_type = 2;
    req.company_unique_id = this.company_unique_id;
    const response: WebApiResponse = await this._subscriptionservice.GetSettings(req);
    if (response.code == 1) {
      const resp: any = response.data;
      sessionStorage.setItem("SettingJson", resp.setting_json);
    }
    this.spinner.hide();
  }
  async GetSubscriptionDetails() {
    this.spinner.show();
    var request = { "subscription_unique_id": this.subscription_unique_id, "company_unique_id": this.company_unique_id }
    const response: WebApiResponse = await this._subscriptionservice.GetSubscriptionList(request);
    if (response.code == 1) {
      var data: any = response.data;
      sessionStorage.setItem("Subscription_" + data[0].subscription_unique_id, JSON.stringify(data[0]));
      this.subscription_name = data[0].subscription_name;
      this.subscription_unique_id = data[0].subscription_unique_id;
      this.customer_name = data[0].customer_name;
      this.customer_email = data[0].customer_email;
      this.is_active = data[0].is_active;
      this.plan_name = data[0].plan_name;
      this.plan_quantity = data[0].subscription_plan_quantity;
      this.plan_amount = data[0].subscription_plan_price;
      this.plan_currency = data[0].subscription_currency;
      this.frequency_name = data[0].subscription_frequency_name;
      this.exist_subscription_price = this.exist_subscription_price + this.plan_amount;
      this.plan_unique_id = data[0].plan_unique_id;
      this.subscription_status = data[0].subscription_status;
      if (data[0].subscription_plan_price_breakdown_response != "") {
        this.plan_price_breakdown = JSON.parse(data[0].subscription_plan_price_breakdown_response);
      }
      this.pricing_structure = data[0].subscription_plan_price_structure;
      this.IsMeteredPlan = data[0].is_metered_plan;
      this.addonlist = data[0].addon_list;
      this.chargelist = data[0].charge_list;
      this.couponlist = data[0].coupon_list;
      this.SubscriptionStartDate = moment(data[0].subscription_startdate).format("MM/DD/YYYY");
      this.NextBillingDate = moment(data[0].subscription_nextbilldate).format("MM/DD/YYYY");
      if (data[0].subscription_plan_price_structure != 1 && data[0].subscription_plan_price_structure != 2) {
        this.IsTieredPlan = true;
      }
      else {
        this.IsTieredPlan = false;
      }
      if (this.pricing_structure == 3) {
        this.pricing_structure_name = "Tiered";
      }
      if (this.pricing_structure == 5) {
        this.pricing_structure_name = "Volume";
      }
      if (this.pricing_structure == 4) {
        this.pricing_structure_name = "StairStep";
      }
      if (data[0].addon_list.length > 0) {
        for (var i of data[0].addon_list) {
          this.exist_subscription_price = this.exist_subscription_price + i.addon_price;
        }
      }
      if (data[0].charge_list.length > 0) {
        for (var j of data[0].charge_list) {
          this.exist_subscription_price = this.exist_subscription_price + j.charge_price;
        }
      }
      for (var l of data[0].addon_list) {
        if (l.addon_price_breakdown_response != "") {
          l.addon_price_breakdown = JSON.parse(l.addon_price_breakdown_response);
        }
      }
      for (var m of data[0].charge_list) {
        if (m.charge_price_breakdown_response != "") {
          m.addon_price_breakdown = JSON.parse(m.charge_price_breakdown_response);
        }
      }

      this.next_subscription_price = this.exist_subscription_price;
      for (var d of this.couponlist) {
        if (d.discount_type == 1) {
          this.total_discount_price = this.total_discount_price + d.discount_unit;
        }
        else {
          var discountamount = (d.discount_unit / 100) * this.next_subscription_price;
          if (discountamount <= 0) {
            discountamount = Math.abs(discountamount);
          }
          this.total_discount_price = this.total_discount_price + discountamount;
        }
      }
      this.next_subscription_price -= this.total_discount_price;
      this.next_subscription_price = this.next_subscription_price.toFixed(2);
      this.spinner.hide();
    }
    else {
      this.spinner.hide();
    }


  }
  async GetPricingModel(ItemId: string, ItemType: number, Quantity: any) {
    var request = { "subscription_unique_id": this.subscription_unique_id, "item_unique_id": ItemId, "item_type": ItemType, "company_unique_id": this.company_unique_id }
    const response: WebApiResponse = await this._subscriptionservice.GetSubscriptionItemPricing(request);
    if (response.code == 1) {
      var data: any = response.data;
      this.pricelist = [];
      this.pricelist = data;
      this.pricing_structure = this.pricelist[0].pricing_structure;
      for (var i of this.pricelist) {
        if (i.unit_to == 0) {
          i.unit_to = "& above";
        }
      }
    }
    this.ShowPricingBreakdown(Quantity);
  }

  ShowPricingBreakdown(Quantity: number) {
    this.TotalAmounts = 0;
    this.TotalUnits = Quantity;
    if (this.pricelist[0].pricing_structure == 3) {
      var remainingquantity = 0;
      for (var i = 0; i < this.pricelist.length; i++) {
        if (i == 0) {
          if (Quantity > this.pricelist[i].unit_to) {
            var newquantity = 0;
            remainingquantity = Quantity - this.pricelist[i].unit_to;
            newquantity = Quantity - remainingquantity;
            this.pricelist[i].unit = newquantity;
            this.pricelist[i].amount = this.pricelist[i].price * newquantity;
          }
          else {
            this.pricelist[i].unit = Quantity;
            this.pricelist[i].amount = this.pricelist[i].price * Quantity;
            remainingquantity = 0;
          }
        }
        else {
          if (remainingquantity > 0) {
            var newquantity = 0;
            var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
            if (remainingquantity > count) {
              remainingquantity = remainingquantity - count;

              this.pricelist[i].unit = count;
              this.pricelist[i].amount = this.pricelist[i].price * count;
            }
            else {
              this.pricelist[i].unit = remainingquantity;
              this.pricelist[i].amount = this.pricelist[i].price * remainingquantity;
              remainingquantity = 0;
            }
          }
          else {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;
          }
        }

      }

    }
    if (this.pricelist[0].pricing_structure == 4) {
      for (var i = 0; i < this.pricelist.length; i++) {
        var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (isNaN(count)) {
          var j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = Quantity;
            this.pricelist[i].amount = this.pricelist[i].price;
          }
        }
        else if (Quantity > count) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else {
          this.pricelist[i].unit = Quantity;
          this.pricelist[i].amount = this.pricelist[i].price;
        }
      }
    }
    if (this.pricelist[0].pricing_structure == 5) {
      for (var i = 0; i < this.pricelist.length; i++) {
        var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (Quantity == 0) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else if (isNaN(count)) {
          var j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = Quantity;
            this.pricelist[i].amount = this.pricelist[i].price * Quantity;
          }
        }
        else if (Quantity >= this.pricelist[i].unit_from && Quantity <= this.pricelist[i].unit_to) {
          this.pricelist[i].unit = Quantity;
          this.pricelist[i].amount = this.pricelist[i].price * Quantity;
          Quantity = 0;

        }
        else {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
      }
    }
    for (var i = 0; i < this.pricelist.length; i++) {
      this.TotalAmounts += this.pricelist[i].amount;
    }
  }
  Redirectback() {
    this._location.back();
  }
  editsubscription() {
    this.router.navigateByUrl('/editsubscription/' + this.company_unique_id + '/' + this.subscription_unique_id + '/' + this.customer_unique_id);
  }

  showPaymentHistory() {
    this.router.navigateByUrl('/paymenthistory/' + this.company_unique_id + '/' + this.customer_unique_id + '/' + this.subscription_unique_id);
  }
  showPaymentMethod() {
    this.router.navigateByUrl('/paymentmethod/' + this.company_unique_id + '/' + this.customer_unique_id);
  }

  showShippingDetails() {
    this.router.navigateByUrl('/shippingdetails/' + this.company_unique_id + '/' + this.customer_unique_id);
  }


  async GetCustomerAccountInfo() {
    this.spinner.show();
    var request = { company_unique_id: this.company_unique_id, customer_unique_id: this.customer_unique_id }
    const response: WebApiResponse = await this._customerService.GetAccountDetails(request);
    if (response.code == 1) {
      var data: any = response.data;
      this.customer_default_card = data.filter(x => x.is_default);
      if (this.customer_default_card.length > 0) {
        this.account_last_four = this.customer_default_card[0].account_last_four;
        this.account_type = this.customer_default_card[0].account_type;
        this.expiry_date = this.customer_default_card[0].account_expiry_date;
      }
    }
    this.spinner.hide();
  }

  async pausesubscriptionconfirm() {
    this.IsCancelConfirmation = false;
    this.IsPauseConfirmation = true;
  }
  async cancelsubscriptionconfirm() {
    this.IsPauseConfirmation = false;
    this.IsCancelConfirmation = true;
  }

  async pausecancel() {
    this.IsPauseConfirmation = false;
    this.IsCancelConfirmation = false;
  }
  async changesubscriptionstatus(status) {
    var request = { "subscription_unique_id": this.subscription_unique_id, "company_unique_id": this.company_unique_id, "subscription_status": status, "customer_unique_id": this.customer_unique_id, "subscription_name": this.subscription_name }
    const response: WebApiResponse = await this._subscriptionservice.UpdateSubscriptionStatus(request);
    if (response.code == 1) {
      this.Redirectback();
    }
  }
  ApplyCustomerPortalSettings() {
    if (sessionStorage.getItem('SettingJson')) {
      var settings = JSON.parse(sessionStorage.getItem('SettingJson'));
      this.customerportalsettings = settings.CustomerPortalSettings;
      console.log(this.customerportalsettings);
      if (this.customerportalsettings.allowCustomerToChangeSubscription == '0') {
        this.allowchangesubscription = false;
      }
      if (this.customerportalsettings.allowCustomerToPauseSubscription == '0') {
        this.allowpausesubscription = false;
      }
      if (this.customerportalsettings.allowCustomerToCancelSubscription == '0') {
        this.allowcancelsubscription = false;
      }
      if (this.customerportalsettings.allowCustomerToReactivateCancelSubscription == '0') {
        this.allowreactivatesubscription = false;
      }
    }

  }

}

export interface IToastButton {
  id: string;
  title: string;
};
