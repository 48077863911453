<div class="container-xxl mx-0 px-lg-5 pt-20">
  <div class="section">
    <div class="1row 1justify-content-center">
      <div class="1col-md-8">
        <div class="1row">
          <div class="1col-md-12">
            <div class="shadow-panel p-4">
              <div class="d-flex justify-content-end mb-3">
                <button class="btn btn-sm btn-primary me-2" (click)="ShowAddCard()" *ngIf="card_list.length == 0">Add Card</button>
                <button class="btn 1btn-sm btn-primary btn-print me-2" *ngIf="card_list.length > 0" (click)="onclickpayinvoice()">Pay Now</button>
                <div (click)="pdf.saveAs('SubscriptionInvoice.pdf')" class="btn 1btn-sm btn-primary btn-print">Download</div>
              </div>
              <kendo-pdf-export #pdf paperSize="A4" [scale]="0.7" margin="5mm">
                <div class="card border-0 mb-5 mb-xl-8">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="row mb-3">
                        <div class="col-md-12">
                          <img alt="Logo" [src]="company_logo" class="h-50px logo" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7">
                          <div>
                            <div>{{company_name}}</div>
                            <div>{{company_address1}} {{company_address2}}</div>
                            <div>{{company_city}}, {{company_state}} {{company_zipcode}}</div>
                            <div>{{company_country}}</div>
                            <div class="w-100px"><hr /></div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div>
                            <h2>Invoice</h2>
                          </div>
                          <div>SOI-<span class="fw-bold">{{subscription_invoice_id}}</span></div>
                          <div>Date <span class="fw-bold">{{invoice_date}}</span></div>
                          <div>Amount <span class="fw-bold">$ {{total_after_discount}} (USD)</span></div>
                          <div>Customer ID <span class="fw-bold">{{displaycustid}}</span></div>
                          <div><span class="fw-bold text-success" *ngIf="paid_status == 1">PAID</span></div>
                          <div><span class="fw-bold text-info" *ngIf="paid_status != 1">Pending</span></div>
                          <div class="w-100px"><hr /></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7">
                          <div>
                            <div>BILLED TO</div>
                            <div>{{name}}</div>
                            <div>{{shipping_address1}} {{shipping_address2}}</div>
                            <div>{{shipping_city}} {{shipping_state}} {{shipping_zipcode}}</div>
                            <div>{{shipping_country}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-10">
                        <div class="col-md-12">
                          <div class="table-responsive mb-9">
                            <table class="table mb-3 border-bottom border-secondary" aria-describedby="Invoice Details">
                              <thead>
                                <tr class="border-bottom border-top border-secondary fs-6 fw-bold">
                                  <th class="min-w-175px pb-2">Description</th>
                                  <th class="min-w-70px text-end pb-2">Unit</th>
                                  <th class="min-w-80px text-end pb-2">Item Price</th>
                                  <th class="min-w-100px text-end pb-2">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="fw-bold fs-6 text-end" *ngFor="let item of sub_invoice_details">
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <i class="{{item.item_icon}} me-2"></i>
                                      {{item.item_description}}
                                    </div>
                                  </td>
                                  <td class="pt-6" *ngIf="item.item_unit > 0">{{item.item_unit}}</td>
                                  <td class="pt-6" *ngIf="item.item_unit == 0 || item.item_unit == null">-</td>
                                  <td class="pt-6" *ngIf="item.response_price_breakdown.length == 0">$ {{item.item_price}}</td>
                                  <td class="pt-6" *ngIf="item.response_price_breakdown.length > 0">-</td>
                                  <td class="pt-6 text-dark fw-bold"><label *ngIf="item.item_type == 4">- </label>$ {{item.total_amount}}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table mb-3" aria-describedby="Invoice Total">
                              <tbody>
                                <tr class="fw-bold fs-6 text-end">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2">Amount</td>
                                  <td class="min-w-100px text-end pb-2">$ {{total_amount}}</td>
                                </tr>
                                <tr class="fw-bold fs-6 text-end" *ngIf="discount_amount !='0.00'">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2">Discount</td>
                                  <td class="min-w-100px text-end pb-2">$ {{discount_amount}}</td>
                                </tr>
                                <tr class="fw-bold fs-6 text-end py-3">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2 border-top border-bottom border-secondary">Total</td>
                                  <td class="min-w-100px text-end pb-2 border-top border-bottom border-secondary">$ {{total_after_discount}}</td>
                                </tr>
                                <tr></tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </kendo-pdf-export>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center" *ngIf="IsAddCard">
      <div class="col-md-8 justify-content-center">
        <form [formGroup]="cardForm" novalidate="novalidate" id="kt_sign_in_form" action="#">
          <input id="onepayResultToken" type="hidden" />
          <div class="text-danger" id="cardError" name="cardError"></div>

          <div class="">
            <div class="row">
              <div class="col-md-12">
                <label for="addcardnumber">Card Number</label>
                <div class="position-relative">
                  <input type="text" formControlName="account_number" class="form-control" id="cardNumber" name="cardNumber" placeholder="Card Number" maxlength="16" (keyup)="onKeyCardNumber($event)">
                  <div class="position-absolute top-right-7px">
                    <img *ngIf="CardType != ''" [src]="image" style="width: 40px;" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <label class="required form-label">Expiry Month</label>
                <select class="form-control" id="expMonth" formControlName="expMonth">
                  <option value="">Month *</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>


              </div>
              <div class="col-md-6">
                <label class="required form-label">Expiry Year</label>
                <select class="form-control" id="expYear" formControlName="expYear">
                  <option value="">Year *</option>
                  <option value="22">2022</option>
                  <option value="23">2023</option>
                  <option value="24">2024</option>
                  <option value="25">2025</option>
                  <option value="26">2026</option>
                </select>

              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <label for="addcardcvv">CVV</label>
                <div class="position-relative">
                  <input type="text" formControlName="account_cvv" maxlength="4" class="form-control" id="securityCode" name="securityCode" placeholder="CVV">
                </div>
              </div>
            </div>
            <div class="row justify-content-between mb-3">
              <div class="col-md-6">
                <label class="me-2">
                  <input type="checkbox" class="option-input checkbox" formControlName="is_default" />
                  Save card as Default
                </label>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <button [disabled]="cardForm.invalid || CardType == ''" (click)="Save()" class="btn btn-sm btn-primary">Add</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
