import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavTopComponent } from './nav-top/nav-top.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './services/login.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig } from './shared/AppConfig';
import { SubscriptionService } from './services/subscription.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditsubscriptionComponent } from './editsubscription/editsubscription.component';
import { CustomerService } from './services/customer.service';
import { ViewsubscriptionComponent } from './viewsubscription/viewsubscription.component';
import AuthInterceptor from './authinterceptor/authinterceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { PaymentHistoryComponent } from './paymenthistory/paymenthistory.component';
import { ShippingdetailsComponent } from './shippingdetails/shippingdetails.component';
import { PaymentmethodComponent } from './paymentmethod/paymentmethod.component';
import { AccountService } from './services/account.service';
import { ViewinvoiceComponent } from './viewinvoice/viewinvoice.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ToastrModule } from 'ngx-toastr';
import { CardMonth } from './shared/CardMonth';
import { VieworderComponent } from './vieworder/vieworder.component';
import { VieworderinvoiceComponent } from './vieworderinvoice/vieworderinvoice.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    NavTopComponent,
    SubscriptionComponent,
    LoginComponent,
    EditsubscriptionComponent,
    ViewsubscriptionComponent,
    PaymentHistoryComponent,
    ShippingdetailsComponent,
    PaymentmethodComponent,
    ViewinvoiceComponent,
    CheckoutComponent,
    CardMonth,
    VieworderComponent,
    VieworderinvoiceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    PDFExportModule,
    NgxIntlTelInputModule,
    ToastrModule.forRoot()
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, LoginService, SubscriptionService, CustomerService, AppConfig, AccountService,{
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [AppConfig], multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
