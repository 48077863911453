<header class="header">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <div class="fs-5 fw-bold cursor-pointer" (click)="Redirectback()">
      <i class="fas fa-chevron-left me-2"></i>Edit Subscription 
    </div>
    <div class="cursor-pointer">
      <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
    </div>
  </div>
</header>
<div class="container-xxl px-lg-5 pt-20">
  <div class="section">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
    <div class="row mt-3">
      <div class="col-md-8 d-flex mb-3">
        <div class="row flex-grow-1">
          <div class="col-md-12 d-flex" *ngIf="ShowItemDetail">
            <div class="card shadow-panel 1mb-6 1mb-xl-9 flex-grow-1">
              <div class="card-body p-0">
                <form [formGroup]="subscriptionForm" novalidate="novalidate" id="kt_sign_in_form" action="#">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row justify-content-between bg-primary bg-opacity-25 p-4 fw-bold fs-5 gx-5 border-top-left-radius-sm border-top-right-radius-sm mx-0">
                        <div class="col">Items</div>
                        <div class="col-3 col-md-2 text-end">Quantity</div>
                        <div class="col-3 col-md-2 text-end">Amount</div>
                      </div>
                      <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0">
                        <div class="col">
                          {{plan_name}}
                          <div *ngFor="let i of  plan_price_breakdown">
                            <div class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                          </div>
                        </div>
                        <div class="col-3 col-md-2 text-end">
                          <span *ngIf="IsMeteredPlan">
                            <span class="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                              </svg>
                              Metered<br />
                              <a style="color:deepskyblue;" (click)="RedirectPlanUsage(plan_unique_id)"> Usage details</a>
                            </span>
                          </span>
                          <div class="col-auto" *ngIf="IsPlanQuantity && !IsMeteredPlan">
                            <input class="form-control text-end" type="text" formControlName="subscription_plan_quantity" [readonly]="!allowchangequantity" (focusout)="viewplanbreakdown()" placeholder="Enter quantity" name="name" /><span *ngIf="plan_free_quantity > 0">Free Quantity: {{plan_free_quantity}}</span>
                          </div>
                          <label *ngIf="!IsPlanQuantity || IsMeteredPlan" class=" fs-5 fw-bold me-2">-</label>
                        </div>
                        <div class="col-3 col-md-2 text-end">
                          <span *ngIf="!IsMeteredPlan">
                            $ {{subscription_plan_price}}
                          </span>
                          <span *ngIf="IsMeteredPlan">
                            <strong>-</strong>
                          </span>
                          <span class="ms-2 invisible">
                            <i class="far fa-trash-alt"></i>
                          </span>
                        </div>
                      </div>
                      <ng-container formArrayName="addon_list">
                        <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let addon of addons().controls; let i=index" [formGroupName]="i">
                          <div class="col">
                            {{ addon.value.addon_name }}
                              <div id="addonbreakdown{{i}}">
                                <!--<a class="cursor-pointer" (click)="viewaddonbreakdown(i)" data-bs-toggle="modal" data-bs-target="#view-tiered-breakdown">View Tiered Breakdown</a>-->
                                <div *ngFor="let pb of addon.value.addon_price_breakdown">
                                  <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                                </div>
                              </div>
                          </div>
                          <div class="col-3 col-md-2 text-end">
                            <div class="col-auto" id="addonquantity{{i}}">
                              <input type="text" formControlName="addon_quantity" (focusout)="viewaddonbreakdown(i)" [readonly]="!allowchangequantity" placeholder="& Above" class="form-control text-end">
                            </div>
                            <span *ngIf="addon.is_metered_addon">
                              <span class="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                  <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                  <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                </svg>
                                Metered<br />
                                <a style="color:deepskyblue;" (click)="RedirectAddonUsage(addon.addon_unique_id)"> Usage details</a>
                              </span>
                            </span>
                            <label *ngIf="addon.value.addon_quantity == 0" class=" fs-5 fw-bold me-2">-</label>
                          </div>
                          <div class="col-3 col-md-2 text-end">
                            <span *ngIf="!addon.is_metered_addon">
                              $ {{addon.value.addon_price}}
                            </span>
                            <span *ngIf="addon.is_metered_addon">
                              <strong>-</strong>
                            </span>
                            <span *ngIf="!addon.value.is_plan_attachment" class="ms-2 cursor-pointer" (click)="removeaddon(i)">
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container formArrayName="charge_list">
                        <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let charge of charges().controls; let i=index" [formGroupName]="i">
                          <div class="col">
                            {{ charge.value.charge_name }}
                            <div id="chargebreakdown{{i}}">
                              <div *ngFor="let pb of charge.value.charge_price_breakdown">
                                <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                              </div>
                              <!--<a class="cursor-pointer" (click)="viewchargebreakdown(i)" data-bs-toggle="modal" data-bs-target="#kt_modal_tiered">View Tiered Breakdown</a>-->
                            </div>
                          </div>
                          <div class="col-3 col-md-2 text-end">
                            <div class="col-auto" id="chargequantity{{i}}">
                              <input type="text" formControlName="charge_quantity" (focusout)="viewchargebreakdown(i)" [readonly]="!allowchangequantity"  placeholder="& Above" class="form-control text-end">
                            </div>
                            <label *ngIf="charge.value.charge_quantity == 0" class=" fs-5 fw-bold me-2">-</label>
                          </div>
                          <div class="col-3 col-md-2 text-end">
                            <span>$ {{ charge.value.charge_price }}</span>
                            <span *ngIf="!charge.value.is_plan_attachment" class="ms-2 cursor-pointer" (click)="removecharge(i)">
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container formArrayName="coupon_list">
                        <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let coupon of coupons().controls; let i=index" [formGroupName]="i">
                          <div class="col">
                            {{ coupon.value.coupon_name }}
                          </div>
                          <div class="col-3 col-md-2 text-end"></div>
                          <div class="col-3 col-md-2 text-end">
                            <span>($ {{ coupon.value.coupon_discount }})</span>
                            <span [class]="!AllowAddDeletecoupons ? 'invisible' : ''" class="ms-2 cursor-pointer" (click)="removecoupon(i)">
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-md-12 my-3">
                      <div class="row justify-content-end justify-content-md-between px-6">
                        <div class="col-auto">
                          <div class="accordion 1accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="flush-headingOne">
                                <button class="1subscription-charges accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" (click)="ShowBillingBreakdown()">
                                  <span class="me-3">
                                    You will be charged $ {{immediate_charges}} for prorated charges via <img src="assets/images/visa.svg" style="width: 30px;" /> ending {{account_last_four}}
                                  </span>
                                </button>
                              </h2>
                              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body px-2">
                                  <div class="row mb-2">
                                    <div class="col-md-12 fs-6 fw-bold">
                                      Chargeable now
                                    </div>
                                  </div>
                                  <div class="row mb-2" *ngFor="let item of invoiceitems">
                                    <div class="col-md-6">
                                      {{item.item_description}} X {{item.item_unit}}
                                      <div *ngFor="let i of item.price_breakdown">
                                        <div class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                                      </div>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-end">
                                      $ {{item.total_amount}}
                                    </div>
                                  </div>
                                  <div class="row mb-2" *ngIf="total_credit_charges !='0.00'">
                                    <div class="col-md-8 d-flex justify-content-end text-muted">
                                      Credits applied
                                    </div>
                                    <div class="col-md-4 d-flex justify-content-end border-bottom">
                                      - $ {{total_credit_charges}}
                                    </div>
                                  </div>
                                  <div class="row mb-2">
                                    <div class="col-md-12 d-flex justify-content-end fs-6 fw-bold">
                                      $ {{invoice_total}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="row mt-3">
                            <div class="col-md-12 col-sm-12 mb-2 d-md-flex justify-content-end align-items-center">
                              <div class="fs-6 1mb-2 py-3" *ngIf="toggleshowCouponLabel" (click)="showCoupon();">
                                <i class="fa fa-tag 1border 1border-primary rounded 1p-2 me-2 1text-primary"></i>
                                <a class="1text-dark first after">Apply Coupon</a>
                              </div>
                              <div class="d-flex align-items-center ps-md-4" *ngIf="toggleshowCoupon">
                                <div class="me-2">
                                  <input class="form-control" type="text" #couponcode id="promocode" placeholder="Enter coupon promocode" />
                                  <div class="text-danger">{{iscouponerror}}</div>
                                </div>
                                <div class="col-auto">
                                  <button class="btn btn-sm btn-secondary me-2" (click)="cancelcoupon()">
                                    Cancel
                                  </button>
                                  <button class="btn btn-sm bg-light-primary" (click)="validatecoupon(couponcode.value)">
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                    <div class="col-md-12 my-3">
                      <div class="px-6 d-flex justify-content-end">
                        <button class="btn btn-sm btn-secondary me-2" (click)="Redirectback()">Cancel</button>
                        <button class="btn btn-sm btn-primary" (click)="save()">Update</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            
          </div>
          <div class="col-md-12 d-flex" *ngIf="toggleChangePlan">
            <div class="card shadow-panel 1mb-6 1mb-xl-9 flex-grow-1">
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-md-3">
                    <div class="border border-2 rounded-3 p-6">
                      <div class="text-center">
                        <h4>Starter</h4>
                        <label class="text-muted">Plan details about starter</label>
                      </div>
                      <div class="bg-light-primary rounded-3 text-center p-4 my-4 w-100">
                        <label class="fs-6 text-dark">Price</label>
                        <div class="fs-4 text-dark fw-bold">Starts at $20</div>
                      </div>
                      <div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="border border-2 rounded-3 p-6">
                      <div class="text-center">
                        <h4>Pro</h4>
                        <label class="text-muted">Plan details about starter</label>
                      </div>
                      <div class="bg-light-primary rounded-3 text-center p-4 my-4 w-100">
                        <label class="fs-6 text-dark">Price</label>
                        <div class="fs-4 text-dark fw-bold">Starts at $40</div>
                      </div>
                      <div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                      </div>
                    </div>                   
                  </div>
                  <div class="col-md-3">
                    <div class="border border-2 rounded-3 p-6">
                      <div class="text-center">
                        <h4>Enterprise</h4>
                        <label class="text-muted">Plan details about starter</label>
                      </div>
                      <div class="bg-light-primary rounded-3 text-center p-4 my-4 w-100">
                        <label class="fs-6 text-dark">Price</label>
                        <div class="fs-4 text-dark fw-bold">Starts at $60</div>
                      </div>
                      <div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                      </div>
                    </div>                    
                  </div>
                  <div class="col-md-3">
                    <div class="border border-2 rounded-3 p-6">
                      <div class="text-center">
                        <h4>Custom</h4>
                        <label class="text-muted">Plan details about starter</label>
                      </div>
                      <div class="bg-light-primary rounded-3 text-center p-4 my-4 w-100">
                        <label class="fs-6 text-dark">Special Price</label>
                        <div class="fs-4 text-dark fw-bold">Contact Sales</div>
                      </div>
                      <div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                        <div><i class="far fa-check-circle me-3 text-primary"></i>Plan details json text 1</div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="card-body p-6">
                <h5 class="card-title">Change Plan</h5>
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label>Plan Name</label>
                    <div>
                      <select class="form-select" aria-label="Select Plan" (change)="onplanselect($event.target.value)">
                        <option selected>Select Plan</option>
                        <option *ngFor="let plan of planlist" value="{{plan.id}}">{{plan.plan_name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3" *ngIf="BillingFrequency.length >0">
                    <div><label>Plan Frequency</label></div>
                    <div class="my-2" *ngFor="let billfreq of BillingFrequency">
                      <label class="me-2">
                        <input type="radio" class="option-input radio" name="example" value="{{billfreq | json}}" (change)="onselectfreuency($event)" />
                        {{billfreq.frequency_name}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 d-flex justify-content-end align-items-center">
                    <button class="btn btn-sm btn-secondary me-2" id="cancel" (click)="cancelChangePlan();">Cancel</button>
                    <button class="btn btn-sm btn-primary" (click)="AssignPlan()">Update Plan</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex" *ngIf="toggleChangeAddon">
            <div class="card shadow-panel 1mb-6 1mb-xl-9 flex-grow-1">
              <div class="card-body p-6" *ngIf="addonlist.length > 0">
                <h5 class="card-title">Add Addon </h5>
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="my-2" *ngFor="let item of addonlist">
                      <label class="me-2">
                        <input type="checkbox" class="option-input checkbox" (change)="onaddonchoose(item,$event.target.checked)" />
                        {{item.addon_name}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 d-flex justify-content-end align-items-center">
                    <button class="btn btn-sm btn-secondary me-2" id="cancel" (click)="cancelchangeaddon();">Cancel</button>
                    <button class="btn btn-sm btn-primary" (click)="Assigneaddon()">Add</button>
                  </div>
                </div>
              </div>
              <div class="card-body d-flex justify-content-center align-items-center" *ngIf="addonlist.length == 0">
                <label class="fs-6 fw-bold">No Addon available for the selected plan.</label>
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex" *ngIf="toggleChangeCharge">
            <div class="card shadow-panel 1mb-6 1mb-xl-9 flex-grow-1">
              <div class="card-body p-6" *ngIf="chargelist.length > 0">
                <h5 class="card-title">Add charge</h5>
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="my-2" *ngFor="let item of chargelist">
                      <label class="me-2">
                        <input type="checkbox" class="option-input checkbox" (change)="onchargechoose(item,$event.target.checked)" />
                        {{item.charge_name}}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 d-flex justify-content-end align-items-center">
                    <button class="btn btn-sm btn-secondary me-2" id="cancel" (click)="cancelchangecharge();">Cancel</button>
                    <button class="btn btn-sm btn-primary" (click)="Assignecharge()">Add</button>
                  </div>
                </div>
              </div>
              <div class="card-body d-flex justify-content-center align-items-center" *ngIf="chargelist.length == 0">
                <label class="fs-6 fw-bold">No Charge available for the selected plan.</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex flex-column">
        <div class="row flex-grow-1 mb-3">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Next Invoice</div>
              </div>
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-6">
                    <div class="fw-bold">{{Subscription_billing_date}}</div>
                    <div>({{frequency_name}})</div>
                  </div>
                  <div class="col-6 d-flex justify-content-end text-center">
                    <div class="">
                      <div class="badge bg-primary p-2 text-dark bg-opacity-25 fs-3">$ {{next_subscription_price}}  *</div>
                      <div class="text-muted" style="font-size: 12px;">* Excluding Tax</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex-grow-1 mb-3">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Quick Links</div>
              </div>
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3" (click)="showChangePlan();">
                      <a class="text-dark first after">Change Plan</a>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3" (click)="GetAddon();">
                      <a class="text-dark first after">Add Add-Ons</a>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3" (click)="GetCharges();">
                      <a class="text-dark first after">Add Charges</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="view-tiered-breakdown" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-650px">
    <div class="modal-content">
      <div class="modal-header">
        <div class="fs-5 fw-bold">{{pricing_structure_name}} Pricing Breakdown</div>
        <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
          <i class="fas fa-times fa-fw"></i>
        </div>
      </div>
      <div class="modal-body py-10">
        <table class="table table-rounded table-row-bordered border gy-4 gs-7">
          <thead>
            <tr class="fw-bolder fs-6 text-gray-800">
              <th colspan="1">Units: From - To</th>
              <th colspan="1">Unit</th>
              <th colspan="1" class="text-center">Price Per Unit</th>
              <th colspan="1" class="text-end">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let price of pricelist; let i=index">
              <td>
                <label>{{price.unit_from}} - {{price.unit_to}}</label>
              </td>
              <td>
                <label>{{price.unit}}</label>
              </td>
              <td class="text-center">
                <label>{{price.price}}</label>
              </td>
              <td class="text-end">
                <label>${{price.amount}}</label>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <strong>Total</strong>
              </td>
              <td class="text-center">
                {{TotalUnits}}
              </td>
              <td class="text-end">
                $ {{TotalAmounts}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

