<app-nav-top></app-nav-top>
<ngx-spinner bdColor="rgba(51,51,51,0.8)"
             size="medium"
             color="#009ef7"
             type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
<div class="container-xxl px-lg-5 pt-20">
  <div class="row" *ngIf="SingleSubscriptionList.length > 0">
    <div class="col-md-12" *ngFor="let sub of SingleSubscriptionList" (click)="viewsubscription(sub)">
      <div class="card shadow-panel card-embosed border-0 mb-3">
        <div class="card-body p-2">
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-12 d-flex justify-content-start align-items-center px-3 py-2 bg-primary bg-opacity-25">
              <div class="me-4">
                <img src="assets/images/logo-2.svg" class="img-fluid" alt="logo" style="width: 50px;" />
              </div>
              <div class="">
                <h5 class="text-primary fw-bold">{{sub.entity_name}}</h5>
                <h6 class="card-title fw-bold mb-0">{{sub.subscription_name}}</h6>
                <small class="text-dark">Subscription ID: {{sub.subscription_unique_id}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="MultipleSubscriptionList.length > 0">
    <div class="col-md-12">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="card shadow-panel cursor-pointer border-0 mb-3" *ngFor="let sub of MultipleSubscriptionList; let i = index" [attr.data-index]="i">
          <div class="card-body p-2">
            <div class="accordion-item">
              <div class="accordion-header" [id]="'flush-headingOne'+i">
                <div class="1accordion-button">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="col-12 d-flex justify-content-start align-items-center px-3 py-2 bg-primary bg-opacity-25 collapsed" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapseOne'+i" (click)="GetMultipleInside(sub.entity_id)" aria-expanded="false" [attr.aria-controls]="'flush-collapseOne'+i">
                      <div class="me-4" *ngIf="sub.company_logo !=''">
                        <img src="{{sub.company_logo}}" class="img-fluid" alt="logo" style="width: 50px;" />
                      </div>
                      <div class="me-4" *ngIf="sub.company_logo ==''">
                        <img src="assets/images/Infify-logo.png"class="img-fluid" alt="logo" style="width: 50px;" />
                      </div>
                      <div class="">
                        <h5 class="text-primary fw-bold">{{sub.entity_name}}</h5>
                        <h6 class="card-title fw-bold">We found multiple subscrptions, click to view</h6>
                      </div>
                    </div>
                    <!--<div class="col-6 d-flex justify-content-end align-items-center align-self-stretch bg-light fs-3 p-3 fw-bold">
                    </div>-->
                  </div>
                </div>
              </div>
              <div [id]="'flush-collapseOne'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'flush-headingOne'+i" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body p-0">
                  <div class="d-flex justify-content-between align-items-center border-bottom p-3" *ngFor="let sub of InsideMutipleList" (click)="viewsubscription(sub)">
                    <div class="">
                      <h6 class="card-title fw-bold mb-0">{{sub.subscription_name}}</h6>
                      <small class="text-muted">Subscription ID: {{sub.subscription_unique_id}}</small>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="row" *ngIf="subscriptionlist.length > 0">
    <div class="col-md-12">
      <div class="card card-embosed border-0 my-2" (click)="showsubscriptionDetails();">
        <div class="card-body" *ngFor="let sub of subscriptionlist">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h5 class="card-title">{{sub.subscription_name}}</h5>
              <small class="text-muted">Subscription ID: {{sub.subscription_unique_id}}</small>
            </div>
            <div class="fs-3 fw-bold">
              $469.20
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</div>
