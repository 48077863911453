import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WebApiResponse } from '../model/ApiResponse';
import { AccountService } from '../services/account.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-shippingdetails',
  templateUrl: './shippingdetails.component.html',
  styleUrls: ['./shippingdetails.component.css']
})
export class ShippingdetailsComponent implements OnInit {
  customerForm: FormGroup;
  customer_unique_id: string;
  entity_id: any;
  StateList: any;
  CountryList: any;
  CountryStateList: any;
  constructor(private fb: FormBuilder, private _accountservice: AccountService,
    private activatedroute: ActivatedRoute, private _location: Location) {
    this.customer_unique_id = this.activatedroute.snapshot.params["CustomerUniqueId"];
    this.entity_id = this.activatedroute.snapshot.params["EntityId"];
    this.customerForm = this.fb.group({
      "customer_unique_id": [''],
      "company_unique_id": [''],
      "shipping_first_name": [''],
      "shipping_last_name": [''],
      "shipping_city": [''],
      "shipping_state": [''],
      "shipping_country": [''],
      "shipping_zipcode": [''],
      "shipping_address1": [''],
      "shipping_address2": [''],
      "customer_language": ['']
    });
  }

  ngOnInit() {
    this.customerForm.get('customer_unique_id').setValue(this.customer_unique_id);
    this.customerForm.get('company_unique_id').setValue(this.entity_id);
    this.GetCountryList();
    this.GetStateList();
    this.GetCustomerDetails();
  }
  async GetCustomerDetails() {
    var request = { customer_unique_id: this.customerForm.get('customer_unique_id').value, company_unique_id: this.entity_id }
    const response: WebApiResponse = await this._accountservice.GetCustomers(request);
    if (response.code == 1) {
      const resp: any = response.data;
      this.customerForm.patchValue(resp[0]);
      this.customerForm.get('shipping_state').setValue(resp[0].shipping_state_id);
      this.customerForm.get('shipping_country').setValue(resp[0].shipping_country_id);
    }

  }
  CountryChange(value: any) {
    this.CountryStateList = this.StateList.filter((f: { country_id: number; }) => f.country_id == Number(value.target.value));
  }

  async save() {
    const response: WebApiResponse = await this._accountservice.UpdateShippingDetails(this.customerForm.value);
    if (response.code == 1) {
      this.Redirectback();
    }
  }

  async GetCountryList() {
    const response: WebApiResponse = await this._accountservice.GetCountryList()
    if (response.code == 1) {
      this.CountryList = response.data;
    }
  }
  async GetStateList() {
    const response: WebApiResponse = await this._accountservice.GetStateList()
    if (response.code == 1) {
      this.StateList = response.data;
      this.CountryStateList = this.StateList;
    }
  }

  Redirectback() {
    this._location.back();
  }
}
