<header class="header">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <div class="fs-5 fw-bold cursor-pointer"  (click)="Redirectback()">
      <i class="fas fa-chevron-left me-2"></i>Shipping Details
    </div>
    <div class="cursor-pointer">
      <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
    </div>
  </div>
</header>
<div class="container-xxl px-lg-5 pt-20">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="section">
    <div class="row mt-3">
      <div class="col-md-8 d-flex mb-3">
        <div class="row flex-grow-1">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel 1mb-6 1mb-xl-9 flex-grow-1">
              <div class="card-body p-6">
                <form [formGroup]="customerForm" novalidate="novalidate" id="kt_sign_in_form">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="shippingfirstname">First Name</label>
                        <input type="text" class="form-control" id="shippingfirstname" placeholder="First Name" formControlName="shipping_first_name">
                    </div>
                    <div class="col-md-6">
                      <label for="shippinglastname">Last Name</label>
                      <input type="text" class="form-control" id="shippinglastname" placeholder="Last Name" formControlName="shipping_last_name">
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <label for="shippingaddress1">Address Line 1</label>
                      <input type="text" class="form-control" id="shippingaddress1" placeholder="Address Line 1" formControlName="shipping_address1">
                    </div>
                    <div class="col-md-6">
                      <label for="shippingaddress2">Address Line 2</label>
                      <input type="text" class="form-control" id="shippingaddress2" placeholder="Address Line 2" formControlName="shipping_address2">
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <label for="billingcountry">Country</label>
                      <select name="country" id="billingstate" aria-label="Select a Country" data-control="select2" formControlName="shipping_country" data-placeholder="Select a Country..." data-dropdown-parent="#kt_modal_add_customer" class="form-select" (change)="CountryChange($event)">
                        <option [value]=0>Select a Country...</option>
                        <option *ngFor="let country of CountryList" [value]="country.country_id">{{country.country_name}}</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label for="billingstate">State</label>
                      <select name="country" id="billingstate" aria-label="Select a State" data-control="select2" formControlName="shipping_state" data-placeholder="Select a State..." data-dropdown-parent="#kt_modal_add_customer" class="form-select">
                        <option [value]=0>Select a State...</option>
                        <option *ngFor="let state of CountryStateList" [value]="state.state_id">{{state.state_name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <label for="shippingcity">City</label>
                      <input type="text" class="form-control" id="shippingcity" placeholder="City" formControlName="shipping_city">
                    </div>
                    <div class="col-md-6">
                      <label for="shippingzip">Zip</label>
                      <input type="text" class="form-control" id="shippingzip" placeholder="Zip" formControlName="shipping_zipcode">
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12 d-flex justify-content-end">
                      <button class="btn btn-sm btn-primary" (click)="save()">Update</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div class="col-md-4 d-flex flex-column">
        <div class="row flex-grow-1 mb-3">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Quick Links</div>
              </div>
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Edit Subscription</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Pause Subscription</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Cancel Subscription</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Payment Method</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Payment History</a>
                    </div>
                    <div class="fs-6 mb-3">
                      <a class="text-dark first after">Update billing details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</div>
