import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export default class AuthInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient, private _router: Router) { }

  intercept(req: any, next: HttpHandler): any {

    if (req.headers.get('No-Auth') == "True" || req.url.includes('.json')) {
      return next.handle(req.clone());
    }

    if (sessionStorage.getItem('access_token') != null) {
      const headers = req.headers
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${sessionStorage.getItem('access_token')}`)
        .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        .set('Pragma', 'no-cache')
        .set('Expires', '0');
      const clonereq = req.clone({ headers });

      return next.handle(clonereq).pipe(catchError(errs => {
        if (errs instanceof HttpErrorResponse) {
          if (errs.status === 401) {
            sessionStorage.removeItem('access_token');
            this._router.navigate(['/login']);
          }
        }
        return throwError(errs);
      }));

    }
    else {
      sessionStorage.removeItem('access_token');
      this._router.navigate(['/login']);
    }
  }
}
