<header class="header">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <div class="fs-5 fw-bold"  *ngIf="IsPlanAdd || isemail">
      Order Detail
    </div>
    <div class="fs-5 fw-bold cursor-pointer" *ngIf="chargebreakdown" (click)="showsubitem()">
      <i class="fas fa-chevron-left me-2"></i>Order Summary
    </div>
    <div class="fs-5 fw-bold" *ngIf="IsCustomerDetails">
      Account Details
    </div>
    <div class="fs-5 fw-bold" *ngIf="IsShippingDetails">
      Address Details
    </div>
    <div class="fs-5 fw-bold" *ngIf="IsCardDetails">
      Payment Details
    </div>
    <div class="fs-5 fw-bold" *ngIf="IsSubscribePay">
      Complete Your Order
    </div>
  </div>
</header>
<div class="container-xxl px-lg-5 pt-20">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="section">
    <div class="row mb-3" *ngIf="IsClose">
      <div class="col-md-12">
        <div class="shadow-panel mt-3 p-4 text-center">
          <h4 class="">Close</h4>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="InValidDetails">
      <div class="col-md-12">
        <div>Invalid details passed</div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="IsPlanAdd">
      <div class="col-md-12">
        <div class="card shadow-panel">
          <div class="card-body p-0">
            <form [formGroup]="subscriptionForm" novalidate="novalidate" id="kt_sign_in_form" action="#">
              <div class="row">
                <div class="col-md-12">
                  <div class="row justify-content-between bg-primary bg-opacity-25 p-4 fw-bold fs-5 gx-5 border-top-left-radius-sm border-top-right-radius-sm mx-0">
                    <div class="col">Items</div>
                    <div class="col-3 col-md-2 text-end">Quantity</div>
                    <div class="col-3 col-md-2 text-end">Amount</div>
                  </div>
                  <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0">
                    <div class="col">
                      {{plan_name}}
                      <div *ngFor="let i of  plan_price_breakdown">
                        <div class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                      </div>
                    </div>
                    <div class="col-3 col-md-2 text-end">
                      <span *ngIf="IsMeteredPlan">
                        <span class="svg-icon svg-icon-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                          </svg>
                          Metered<br />
                          <a style="color:deepskyblue;" (click)="RedirectPlanUsage(plan_unique_id)"> Usage details</a>
                        </span>
                      </span>
                      <div class="col-auto" *ngIf="IsPlanQuantity && !IsMeteredPlan">
                        <input class="form-control text-end" type="text" formControlName="subscription_plan_quantity" [readonly]="QuantityRead" (focusout)="viewplanbreakdown()" placeholder="Enter quantity" name="name" /><span *ngIf="plan_free_quantity > 0">Free Quantity: {{plan_free_quantity}}</span>
                      </div>
                      <label *ngIf="!IsPlanQuantity || IsMeteredPlan" class=" fs-5 fw-bold me-2">-</label>
                    </div>
                    <div class="col-3 col-md-2 text-end">
                      <span *ngIf="!IsMeteredPlan">
                        $ {{subscription_plan_price}}
                      </span>
                      <span *ngIf="IsMeteredPlan">
                        <strong>-</strong>
                      </span>
                      <span class="ms-2 invisible">
                        <i class="far fa-trash-alt"></i>
                      </span>
                    </div>
                  </div>
                  <ng-container formArrayName="addon_list">
                    <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let addon of addons().controls; let i=index" [formGroupName]="i">
                      <div class="col">
                        {{ addon.value.addon_name }}
                          <div id="addonbreakdown{{i}}">
                            <div *ngFor="let pb of addon.value.addon_price_breakdown">
                              <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                            </div>
                          </div>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <div class="col-auto" id="addonquantity{{i}}">
                          <input type="text" formControlName="addon_quantity" (focusout)="viewaddonbreakdown(i)" [readonly]="QuantityRead" placeholder="& Above" class="form-control text-end">
                        </div>
                        <span *ngIf="addon.is_metered_addon">
                          <span class="svg-icon svg-icon-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                              <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                              <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                            </svg>
                            Metered<br />
                            <a style="color:deepskyblue;" (click)="RedirectAddonUsage(addon.addon_unique_id)"> Usage details</a>
                          </span>
                        </span>
                        <label *ngIf="addon.value.addon_quantity == 0" class=" fs-5 fw-bold me-2">-</label>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span *ngIf="!addon.is_metered_addon">
                          $ {{addon.value.addon_price}}
                        </span>
                        <span *ngIf="addon.is_metered_addon">
                          <strong>-</strong>
                        </span>
                        <span [class]="addon.value.is_plan_attachment  ? 'invisible' : ''" class="ms-2 cursor-pointer" (click)="removeaddon(i)">
                          <i class="far fa-trash-alt"></i>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container formArrayName="charge_list">
                    <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let charge of charges().controls; let i=index" [formGroupName]="i">
                      <div class="col">
                        {{ charge.value.charge_name }}
                        <div id="chargebreakdown{{i}}">
                          <div *ngFor="let pb of charge.value.charge_price_breakdown">
                            <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <div class="col-auto" id="chargequantity{{i}}">
                          <input type="text" formControlName="charge_quantity" (focusout)="viewchargebreakdown(i)" [readonly]="QuantityRead"  placeholder="& Above" class="form-control text-end">
                        </div>
                        <label *ngIf="charge.value.charge_quantity == 0" class=" fs-5 fw-bold me-2">-</label>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span>$ {{ charge.value.charge_price }}</span>
                        <span [class]="charge.value.is_plan_attachment ? 'invisible' : ''" class="ms-2 cursor-pointer" (click)="removecharge(i)">
                          <i class="far fa-trash-alt"></i>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container formArrayName="coupon_list">
                    <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let coupon of coupons().controls; let i=index" [formGroupName]="i">
                      <div class="col">
                        {{ coupon.value.coupon_name }}
                      </div>
                      <div class="col-3 col-md-2 text-end"></div>
                      <div class="col-3 col-md-2 text-end">
                        <span>($ {{ coupon.value.coupon_discount }})</span>
                        <span [class]="!AllowAddDeletecoupons ? 'invisible' : ''" class="ms-2 cursor-pointer" (click)="removecoupon(i)">
                          <i class="far fa-trash-alt"></i>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="recommendattachmentlist.length > 0 && ShowRecommended">
                    <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom">
                      <div class="col">
                        <div class="fw-bold fs-6">Recommended items</div>
                      </div>
                    </div>
                    <div class="row justify-content-between align-items-center p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let i of recommendattachmentlist">
                      <div class="col">
                        {{i.item_name}}
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        $ {{i.item_price}}
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <div class="col-md-12 d-flex justify-content-end">
                          <button class="btn btn-sm btn-primary" (click)="onRecomendselect(i)">Add</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- <table class="table table-responsive table-bordered table-hover mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col">No. of Units</th>
                        <th scope="col" class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="align-middle">
                          {{plan_name}}
                          <div *ngFor="let i of  plan_price_breakdown">
                            <div class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span *ngIf="IsMeteredPlan">
                            <span class="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                              </svg>
                              Metered<br />
                              <a style="color:deepskyblue;" (click)="RedirectPlanUsage(plan_unique_id)"> Usage details</a>
                            </span>
                          </span>
                          <div class="col-auto" *ngIf="IsPlanQuantity && !IsMeteredPlan">
                            <input class="form-control" type="text" formControlName="subscription_plan_quantity" [readonly]="QuantityRead" (focusout)="viewplanbreakdown()" placeholder="Enter quantity" name="name" /><span *ngIf="plan_free_quantity > 0">Free Quantity: {{plan_free_quantity}}</span>
                          </div>
                          <label *ngIf="!IsPlanQuantity || IsMeteredPlan" class=" fs-5 fw-bold me-2">-</label>
                        </td>
                        <td class="align-middle text-end">
                          <span *ngIf="!IsMeteredPlan">
                            $ {{subscription_plan_price}}
                          </span>
                          <span *ngIf="IsMeteredPlan">
                            <strong>-</strong>
                          </span>
                          <span class="ms-2 invisible">
                            <i class="far fa-trash-alt"></i>
                          </span>
                        </td>
                      </tr>
                      <ng-container formArrayName="addon_list">
                        <tr *ngFor="let addon of addons().controls; let i=index" [formGroupName]="i">
                          <td class="align-middle">
                            {{ addon.value.addon_name }}
                            <div id="addonbreakdown{{i}}">
                              <div *ngFor="let pb of addon.value.addon_price_breakdown">
                                <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                              </div>
                            </div>
                          </td>
                          <td class="align-middle">
                            <div class="col-md-6" id="addonquantity{{i}}">
                              <input type="text" formControlName="addon_quantity" [readonly]="QuantityRead" (focusout)="viewaddonbreakdown(i)" placeholder="& Above" class="form-control">
                            </div>
                            <span *ngIf="addon.is_metered_addon">
                              <span class="svg-icon svg-icon-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                  <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                  <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                </svg>
                                Metered<br />
                                <a style="color:deepskyblue;" (click)="RedirectAddonUsage(addon.addon_unique_id)"> Usage details</a>
                              </span>
                            </span>
                            <label *ngIf="addon.value.addon_quantity == 0" class=" fs-5 fw-bold me-2">-</label>
                          </td>
                          <td class="align-middle text-end">
                            <span *ngIf="!addon.is_metered_addon">
                              $ {{addon.value.addon_price}}
                            </span>
                            <span *ngIf="addon.is_metered_addon">
                              <strong>-</strong>
                            </span>
                            <span [class]="addon.value.is_plan_attachment  ? 'invisible' : ''" class="ms-2 cursor-pointer" (click)="removeaddon(i)">
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container formArrayName="charge_list">
                        <tr *ngFor="let charge of charges().controls; let i=index" [formGroupName]="i">
                          <td class="align-middle">
                            {{ charge.value.charge_name }}
                            <div id="chargebreakdown{{i}}">
                              <div *ngFor="let pb of charge.value.charge_price_breakdown">
                                <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                              </div>
                            </div>
                          </td>
                          <td class="align-middle">
                            <div class="col-sm-2" id="chargequantity{{i}}">
                              <input type="text" formControlName="charge_quantity" [readonly]="QuantityRead" (focusout)="viewchargebreakdown(i)" placeholder="& Above" class="form-control">
                            </div>
                            <label *ngIf="charge.value.charge_quantity == 0" class=" fs-5 fw-bold me-2">-</label>
                          </td>
                          <td class="align-middle text-end">
                            <span>$ {{ charge.value.charge_price }}</span>
                            <span  [class]="charge.value.is_plan_attachment ? 'invisible' : ''" class="ms-2 cursor-pointer " (click)="removecharge(i)">
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container formArrayName="coupon_list">
                        <tr *ngFor="let coupon of coupons().controls; let i=index" [formGroupName]="i">
                          <td class="align-middle">
                            {{ coupon.value.coupon_name }}
                          </td>
                          <td class="align-middle">
                          </td>
                          <td class="align-middle text-end">
                            <span>($ {{ coupon.value.coupon_discount }})</span>
                            <span [class]="!AllowAddDeletecoupons ? 'invisible' : ''" class="ms-2 cursor-pointer" (click)="removecoupon(i)">
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="recommendattachmentlist.length > 0 && ShowRecommended" >
                        <tr>
                          <td colspan="3" class="align-middle">
                            <div class="fw-bold fs-6">Recommended items</div>
                          </td>
                        </tr>
                        <tr *ngFor="let i of  recommendattachmentlist">
                          <td class="align-middle">
                            {{i.item_name}}
                          </td>
                          <td class="align-middle">
                            <span>
                             $ {{i.item_price}}
                            </span>
                          </td>
                          <td class="align-middle text-end">
                            <div class="col-md-12 d-flex justify-content-end">
                              <button class="btn btn-primary" (click)="onRecomendselect(i)">Add</button>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table> -->
                  <div class="row mt-3 p-4" *ngIf="ShowAddCoupon">

                    <div class="col-md-12 col-sm-12 mb-2 d-md-flex justify-content-end align-items-center">
                      <div class="fs-6 1mb-2 py-3" *ngIf="toggleshowCouponLabel" (click)="showCoupon();">
                        <i class="fa fa-tag 1border 1border-primary rounded 1p-2 me-2 1text-primary"></i>
                        <a class="1text-dark first after">Apply Coupon</a>
                      </div>
                      <div class="d-flex align-items-center ps-md-4" *ngIf="toggleshowCoupon">
                        <div class="me-2">
                          <input class="form-control" type="text" #couponcode id="promocode" placeholder="Enter coupon promocode" />
                          <div class="text-danger">{{iscouponerror}}</div>
                        </div>
                        <div class="col-auto">
                          <button class="btn btn-sm btn-secondary me-2" (click)="cancelcoupon()">
                            Cancel
                          </button>
                          <button class="btn btn-sm bg-light-primary" (click)="validatecoupon(couponcode.value)">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-2 col-sm-12 mb-2" *ngIf="ShowAddCoupon">
                      <div class="fs-6 1mt-2 1me-2" (click)="showCoupon();">
                        <i class="fa fa-tag 1border 1border-primary rounded 1p-2 me-2 1text-primary"></i>
                        <a class="text-dark first after">Apply Coupon</a>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12" *ngIf="ShowAddCoupon">
                      <div class="d-flex ps-md-4" *ngIf="toggleshowCoupon">
                        <div class="col-md-6 me-2">
                          <input class="form-control" type="text" #couponcode id="promocode" placeholder="Enter coupon promocode" />
                          <div class="text-danger">{{iscouponerror}}</div>
                        </div>
                        <div class="col-auto">
                          <button class="btn btn-outline-primary 1bg-light 1text-dark cursor-pointer" (click)="validatecoupon(couponcode.value)">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="row my-3 p-4 align-items-center">
                    <div class="col-md-12 d-flex justify-content-end">
                      <button class="btn btn-sm btn-primary mb-1" (click)="showemail()">Proceed to Checkout</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3 pb-4" *ngIf="chargenow && new_total_subscription_price !='0.00'" (click)="viewinvoicebreakdown()">
      <div class="col-md-12">
        <div class="card shadow-panel">
          <div class="card-body p-4">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center px-2">
                  <div class="d-flex flex-column flex-grow-1 me-4">
                    <div class="d-flex justify-content-sm-between align-items-center">
                      <div class="">Immediate Charges</div>
                      <div class="flex-grow-1 px-4"><hr /></div>
                      <div class="text-primary">$ {{new_total_subscription_price}}</div>
                    </div>
                    <div class="d-flex justify-content-sm-between align-items-center">
                      <div class="">Next charge on {{next_billing_date}}</div>
                      <div class="flex-grow-1 px-4"><hr /></div>
                      <div class="text-primary">$ {{new_total_subscription_price}}</div>
                    </div>
                  </div>
                  <div>
                    <i class="fas fa-angle-right fs-4 cursor-pointer"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3 pb-4" *ngIf="chargebreakdown">
      <div class="col-md-12">
        <div class="card shadow-panel mt-0 min-h-md-325px min-h-sm-475px">
          <div class="card-body p-4">
            <div class="px-2">
              <div class="row mb-2">
                <div class="col-md-12 fs-6 fw-bold">
                  Chargeable now
                </div>
              </div>
              <div class="row align-items-end mb-2" *ngFor="let item of invoiceitems">
                <div class="col-6 col-sm-6">
                  {{item.item_description}} X {{item.item_unit}}
                  <div *ngFor="let i of item.price_breakdown" class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                </div>
                <div class="col-6 col-sm-6 d-flex justify-content-end">
                  <span *ngIf="item.item_type==4">$ ({{item.total_amount}}) </span>
                  <span *ngIf="item.item_type!=4">$ {{item.total_amount}} </span>
                </div>
              </div>
              <div class="row 1align-items-end mb-2" *ngIf="total_credit_charges !='0.00'">
                <div class="col-sm-8 d-flex justify-content-end text-muted">
                  Credits applied
                </div>
                <div class="col-sm-4 d-flex justify-content-end border-bottom">
                  - $ {{total_credit_charges}}
                </div>
              </div>
              <div class="row 1align-items-end mb-2">
                <div class="col-md-12 d-flex justify-content-end fs-6 fw-bold">
                  $ {{invoice_total}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-12 fs-6 fw-bold">
                  Charges on {{next_billing_date}}
                </div>
              </div>
              <div class="row align-items-end mb-2" *ngFor="let item of invoiceitems">
                <div class="col-6 col-sm-6">
                  {{item.item_description}} X {{item.item_unit}}
                  <div *ngFor="let i of item.price_breakdown" class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                </div>
                <div class="col-6 col-sm-6 d-flex justify-content-end">
                  $ {{item.total_amount}}
                </div>
              </div>
              <div class="row mb-2" *ngIf="total_credit_charges !='0.00'">
                <div class="col-sm-8 d-flex justify-content-end text-muted">
                  Credits applied
                </div>
                <div class="col-sm-4 d-flex justify-content-end border-bottom">
                  - $ {{total_credit_charges}}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-12 d-flex justify-content-end fs-6 fw-bold">
                  $ {{invoice_total}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3 pb-4" *ngIf="isemail">
      <div class="col-md-12">
        <form [formGroup]="loginForm" novalidate="novalidate">
          <div class="card shadow-panel mt-0 min-h-md-325px min-h-sm-475px">
            <div class="card-body p-4">
              <div class="row mt-3 justify-content-center">
                <div class="col-md-6 text-center">
                  <div class="mb-3">
                    <label for="email" class="form-label fw-bold fs-5 text-primary">Enter email to proceed</label>
                  </div>
                  <div class="mb-3">
                    <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email address" />
                  </div>
                  <div>
                    <button class="btn btn-sm btn-primary w-50" [disabled]="loginForm.invalid" (click)="showcustomerdetail()">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-3 pb-4" *ngIf="Ispassword">
      <div class="col-md-12">
        <div class="card shadow-panel mt-0 min-h-md-325px min-h-sm-475px">
          <div class="card-body p-4">
            <div class="row mt-3 justify-content-center">
              <div class="col-md-6 text-center">
                <div class="mb-3">
                  <label for="password" class="form-label fw-bold fs-5 text-primary">Enter your password</label>
                </div>
                <div class="input-group mb-3">
                  <input type="password" class="form-control" id="password" placeholder="" />
                  <span class="input-group-text">
                    <i class="fa fa-eye-slash"></i>
                  </span>
                </div>
                <div class="mb-3">
                  <button class="btn btn-sm btn-primary w-50">Next</button>
                </div>
                <div class="mb-3">
                  <a class="text-primary opacity-75">Login via one time password <i class="fa fa-arrow-right ms-2"></i></a>
                </div>
                <div>
                  <a class="text-primary opacity-75">Proceed as guest <i class="fa fa-arrow-right ms-2"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="customerForm" novalidate="novalidate">
      <div class="row mb-3 pb-4" *ngIf="IsCustomerDetails">
        <div class="col-md-12">
          <div class="card shadow-panel mt-0 p-0 min-h-md-325px min-h-sm-475px">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="accountfirstname">First Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="first_name" id="accountfirstname" placeholder="First Name">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="accountlastname">Last Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="last_name" id="accountlastname" placeholder="Last Name">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="accountemail">Email address  <span class="text-danger">*</span></label>
                    <input type="email" class="form-control" formControlName="customer_email" id="accountemail" placeholder="Email">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="billingphone">Phone <span class="text-danger">*</span></label>
                    <ngx-intl-tel-input class="form-control phonenumberfield"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="false"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.UnitedStates"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        name="mobile"
                                        formControlName="phone_number"
                                        [inputId]="'phone_number'"
                                        (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

                    </ngx-intl-tel-input>
                </div>
              </div>
              <div class="row py-3">
                <div class="col-md-12 d-flex justify-content-end">
                  <button class="btn btn-sm btn-primary" [disabled]="customerForm.invalid" (click)="showshippingdetails()">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 pb-4"  *ngIf="IsShippingDetails">
        <div class="col-md-12">
          <div class="card shadow-panel mt-0 p-0 min-h-md-325px min-h-sm-475px">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="billingfirstname">First Name</label>
                    <input type="text" class="form-control" formControlName="shipping_first_name" id="billingfirstname" placeholder="First Name">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="billinglastname">Last Name</label>
                    <input type="text" class="form-control" formControlName="shipping_last_name" id="billinglastname" placeholder="Last Name">
                </div>
                <div class="col-md-12 mb-3">
                    <label for="billingaddress1">Address Line 1</label>
                    <input type="text" class="form-control" id="billingaddress1" formControlName="shipping_address1" placeholder="Address Line 1">
                </div>
                <div class="col-md-12 mb-3">
                    <label for="billingaddress2">Address Line 2</label>
                    <input type="text" class="form-control" id="billingaddress2" formControlName="shipping_address2" placeholder="Address Line 2">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="billingcountry">Country</label>
                    <select class="form-select" formControlName="shipping_country" id="billingcountry" aria-label="Select Country">
                      <option [value]=0>Select a Country...</option>
                      <option *ngFor="let country of CountryList" [value]="country.country_id">{{country.country_name}}</option>
                    </select>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="billingstate">State</label>
                    <select class="form-select" formControlName="shipping_state" id="billingstate" placeholder="State">
                      <option [value]=0>Select a State...</option>
                      <option *ngFor="let state of CountryStateList" [value]="state.state_id">{{state.state_name}}</option>
                    </select>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="billingcity">City</label>
                    <input type="text" class="form-control" formControlName="shipping_city" id="billingcity" placeholder="City">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="billingzip">Zip</label>
                    <input type="text" class="form-control" formControlName="shipping_zipcode" id="billingzip" placeholder="Zip">
                </div>
              </div>
              <div class="row py-3">
                <div class="col-md-12 d-flex justify-content-end">
                  <button class="btn btn-sm btn-primary" (click)="showcarddetails()">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row mb-3 pb-4" *ngIf="IsCardDetails">
      <div class="col-md-12">
        <form [formGroup]="cardForm" novalidate="novalidate">
          <div class="card shadow-panel mt-0 min-h-md-325px min-h-sm-475px">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="paymentcardnumber">Card Number  <span class="text-danger">*</span></label>
                  <div class="position-relative">
                    <input type="text" class="form-control" formControlName="account_number" id="paymentcardnumber" placeholder="Card NUmber" maxlength="16" (keyup)="onKeyCardNumber($event)">
                    <img *ngIf="CardType != ''" [src]="image" style="width: 40px; position: absolute; right: 10px; top: 8px; " />
                  </div>
                  <label *ngIf="!hideCardError" class="px-3 text-danger">Invalid card number</label>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="paymentcardexpiry">Expiry Month  <span class="text-danger">*</span></label>
                  <select class="form-control" id="expMonth" formControlName="expMonth">
                      <option value="">Month *</option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="paymentcardexpiry">Expiry Year  <span class="text-danger">*</span></label>
                  <select class="form-control"  id="expYear" formControlName="expYear">
                      <option value="">Year *</option>
                      <option value="22">2022</option>
                      <option value="23">2023</option>
                      <option value="24">2024</option>
                      <option value="25">2025</option>
                      <option value="26">2026</option>
                    </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="paymentcardcvv">CVV</label>
                  <input type="text" class="form-control" formControlName="cvv" maxlength="4" id="paymentcardcvv" placeholder="CVV">
                </div>
                <div class="col-md-12 mb-3">
                  <label for="paymentaddress1">Address Line 1</label>
                  <input type="text" class="form-control" formControlName="billing_address1" id="paymentaddress1" placeholder="Address Line 1">
                </div>
                <div class="col-md-12 mb-3">
                  <label for="paymentaddress2">Address Line 2</label>
                  <input type="text" class="form-control" formControlName="billing_address1" id="paymentaddress2" placeholder="Address Line 2">
                </div>
                <div class="col-md-6 mb-3">
                  <label for="billingcountry">Country</label>
                  <select class="form-select" formControlName="billing_country_id" id="billingcountry" aria-label="Select Country">
                      <option [value]=0>Select a Country...</option>
                      <option *ngFor="let country of CountryList" [value]="country.country_id">{{country.country_name}}</option>
                    </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="billingstate">State</label>
                  <select class="form-select" formControlName="billing_state_id" id="billingstate" placeholder="State">
                      <option [value]=0>Select a State...</option>
                      <option *ngFor="let state of CountryStateList" [value]="state.state_id">{{state.state_name}}</option>
                    </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="billingcity">City</label>
                  <input type="text" class="form-control" formControlName="billing_city" id="billingcity" placeholder="City">
                </div>
                <div class="col-md-6 mb-3">
                  <label for="billingzip">Zip</label>
                  <input type="text" class="form-control" formControlName="billing_postal" id="billingzip" placeholder="Zip">
                </div>
              </div>
              <div class="row py-3">
                <div class="col-md-12 d-flex justify-content-end">
                  <button class="btn btn-sm btn-primary" [disabled]="(cardForm.invalid || CardType == '') && !RestrictAddCard" (click)="showpaysubscribe()">Next</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-3 pb-4" *ngIf="IsSubscribePay">
      <div class="col-md-12">
        <div class="card shadow-panel mt-0 min-h-md-325px min-h-sm-475px">
          <div class="card-body p-4">
            <div class="row justify-content-center align-items-center p-4">
              <div class="col-6 col-md-6 border-start border-2 border-primary">
                <div class="">
                  <div class="fw-bold fs-6">
                    Account
                  </div>
                  <div>{{customerForm.controls['first_name'].value}} {{customerForm.controls['last_name'].value}}</div>
                  <div>{{customerForm.controls['customer_email'].value}}</div>
                </div>
              </div>
              <div class="col-6 col-md-6 d-flex justify-content-end">
                <div class="">
                  <a class="text-primary opacity-75" (click)="showcustomerdetail()">Edit <i class="fa fa-arrow-right ms-2"></i></a>
                </div>
              </div>
            </div>
            <div class="row justify-content-center align-items-center p-4">
              <div class="col-6 col-md-6 border-start border-2 border-primary">
                <div class="">
                  <div class="fw-bold fs-6">
                    Billing
                  </div>
                  <div>{{customerForm.controls['shipping_first_name'].value}} {{customerForm.controls['shipping_last_name'].value}}</div>
                  <div>{{customerForm.controls['shipping_address1'].value}}</div>
                  <div>{{customerForm.controls['shipping_address2'].value}}</div>
                </div>
              </div>
              <div class="col-6 col-md-6 d-flex justify-content-end">
                <div class="">
                  <a class="text-primary opacity-75" (click)="showshippingdetails()">Edit <i class="fa fa-arrow-right ms-2"></i></a>
                </div>
              </div>
            </div>
            <div class="row justify-content-center align-items-center p-4">
              <div class="col-6 col-md-6 border-start border-2 border-primary">
                <div class="fw-bold fs-6">
                  Payment
                </div>
                <div class="d-flex justify-content-start align-items-center">
                  <div class="fw-bold me-2">ending {{cardForm.controls['account_last_four'].value}}</div>
                  <div class="">
                    <img [src]="image" style="width: 40px;" />
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6 d-flex justify-content-end">
                <div class="">
                  <a class="text-primary opacity-75" (click)="showcarddetails()">Edit <i class="fa fa-arrow-right ms-2"></i></a>
                </div>
              </div>
            </div>
            <div class="row justify-content-center align-items-center p-4">
              <div class="col-md-12 mb-6 px-0">
                <div class="my-1">
                  <label class="me-2 fw-bold">
                    <input type="checkbox" class="option-input checkbox" [(ngModel)]="IsTerms" />
                    I agree to <a class="text-primary">Terms of Service</a> and <a class="text-primary">Privacy Policy</a><span class="fs-5 ms-2 text-danger">*</span>
                  </label>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <div class="mb-3">
                  <button class="btn btn-sm btn-primary w-50" [disabled]="!IsTerms" (click)="CreateCheckoutSubscription()">Pay  {{new_total_subscription_price}} &#38; subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
