import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CustomerPortal';
  ngOnInit() {
    var themecolor = window.sessionStorage.getItem("CompanyTheme");
    if (themecolor == null) {
      themecolor = '#009ef7'
    }
    document.documentElement.style.setProperty("--theme-color", themecolor);
    var themecolorrgb = this.hexToRGBA(themecolor, 25);
    document.documentElement.style.setProperty("--theme-color-rgb", themecolorrgb);
    this.changeTheme('');
  }

  hexToRGBA(hex, opacity) {
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).concat(opacity+'%').join(',') + ')';
  }

  changeTheme(themeName) {
    var elem = document.getElementById('theme');
    elem.setAttribute('href', './style.css');
    elem.setAttribute('rel', 'stylesheet');
    elem.setAttribute('type', 'text/css');
  }

}
//isFinite(opacity) ? opacity : 1
