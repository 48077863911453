import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebApiResponse } from '../model/ApiResponse';
import { SubscriptionService } from '../services/subscription.service';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-paymenthistory',
  templateUrl: './paymenthistory.component.html'
})
export class PaymentHistoryComponent implements OnInit {

 company_unique_id: any;
  customer_unique_id: any;
  subscription_unique_id: any;
  InvoiceList: any;
  total_after_discount: any;

  constructor(private _subscriptionservice: SubscriptionService,
    private activatedroute: ActivatedRoute, private _location: Location, private spinner: NgxSpinnerService, private router: Router) {
    this.subscription_unique_id = this.activatedroute.snapshot.params["SubscriptionUniqueId"];
    this.company_unique_id = this.activatedroute.snapshot.params["EntityId"];
    this.customer_unique_id = this.activatedroute.snapshot.params["CustomerUniqueId"];
  }

  ngOnInit() {
    this.GetPaymentHistory();
  }

  Redirectback() {
    this._location.back();
  }

  async GetPaymentHistory() {
    this.spinner.show();
    let request = { "company_unique_id": this.company_unique_id, "customer_unique_id": this.customer_unique_id, "subscription_unique_id": this.subscription_unique_id }
    let data: WebApiResponse = await this._subscriptionservice.GetPaymentHistory(request);
    if (data.code == 1) {
      this.InvoiceList = data.data;
      for (let j of this.InvoiceList) {
        j.created_date = moment(j.created_date).format("MM/DD/YYYY hh:MM A");
        j.invoice_amount = j.invoice_amount.toFixed(2);
        if (j.paid_status == 1) {
          j.paid_status = "Paid";
        }
        else {
          j.paid_status = "Pending";
        }
      }
    }
    this.spinner.hide();
  }

  RedirectViewInvoice(InvoiceId) {
    this.router.navigateByUrl('/viewinvoice/' + this.company_unique_id + '/' + InvoiceId);
  }

}
