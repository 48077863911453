import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebApiResponse } from '../model/ApiResponse';
import { InvoicePriceResponse, ItemType, PriceBreakdown, subscriptionaddon, subscriptioncharge, subscriptioncoupon, SubscriptionInvoiceDetailsModel, SubscriptionInvoiceModel } from '../model/subscription';
import { SubscriptionService } from '../services/subscription.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { AccountService } from '../services/account.service';
import { CustomerService } from '../services/customer.service';
import { DOCUMENT } from '@angular/common';
export declare var CardTokenize: Function;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  subscriptionForm: FormGroup;
  customerForm: FormGroup;
  loginForm: FormGroup;
  cardForm: FormGroup;
  subscription_unique_id: string = "";
  subscription_name: string = "";
  is_active: number = 1;
  customer_name: string = "";
  customer_email: string = "";
  plan_name: string = "";
  plan_quantity: any;
  plan_amount: any;
  plan_currency: any;
  IsTieredPlan: boolean = false;
  pricelist: any;
  pricing_structure_name: string = "";
  plan_unique_id: any;
  pricing_structure: number = 0;
  IsMeteredPlan: boolean = false;
  addonlist: any = [];
  chargelist: any = [];
  couponlist: any = [];
  TotalUnits: number = 0;
  TotalAmounts: number = 0;
  SubscriptionStartDate: any;
  entity_id: any;
  customer_unique_id: any;
  customer_Accounts: any = [];
  frequency_name: any;
  product_unique_id: any;
  subscription_plan_price: any;
  next_subscription_price: any;
  Subscription_billing_date: string;
  plan_subscription_frequency: any;
  exist_subscription_price: number = 0;
  exist_subscription_plan_price: any;
  exist_addon: any;
  exist_charge: any;
  exist_coupon: any;
  new_total_subscription_price: any;
  total_sub_total: any; plan_pricing_structure: number;
  plan_free_quantity: number = 0;
  WhenCharge: string;
  ExistSubObj: any;
  invoice_total: any;
  invoiceitems: any = [];
  total_discount_price: any;
  total_credit_charges: any;
  IsChargeImmediately: boolean;
  immediate_charges: any;
  toggleChangePlan: any;
  ChargebleItems: any = [];
  IsPlanQuantity: boolean = false;
  IsTiered: boolean = false;
  ShowItemDetail: boolean = true;
  planlist: any = [];
  selectplancurrency: boolean = false;
  BillingFrequency: any = [];
  BillingCurrency: any = [];
  scheduleruniqueId: any;
  IsPlanAdd: boolean = false;
  chargenow: boolean = false;
  chargebreakdown: boolean = false;
  isemail: boolean = false;
  Ispassword: boolean = false;
  IsCustomerDetails: boolean = false;
  IsShippingDetails: boolean = false;
  IsCardDetails: boolean = false;
  IsSubscribePay: boolean = false;
  isedit: boolean = false;
  is_trial_enable: boolean;
  plan_price_breakdown: PriceBreakdown[];
  apikey: any;
  planid: any;
  planpriceid: any;
  plandetails: any;
  date = new Date();
  IsPlanFreeQuantity: boolean;
  recommendattachmentlist: any = [];
  next_billing_date: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = false;
  image: string;
  CardType: string;
  hideCardError: boolean = true;
  StateList: any;
  CountryList: any;
  CountryStateList: any;
  IsTerms: boolean = false;
  InValidDetails: boolean = false;
  iscouponerror: string = '';
  toggleshowCoupon: boolean = false;
  toggleshowCouponLabel: boolean = true;
  IsClose: boolean = false;
  checkoutsettings: any;
  QuantityRead: boolean = false;
  ShowRecommended: boolean = true;
  AllowAddDeletecoupons: boolean = true;
  ShowAddCoupon: boolean = true;
  RestrictAddCard: boolean = true;
  GatewaySettings: any;
  static GetInstance: any;
  addons(): FormArray {
    return this.subscriptionForm.get("addon_list") as FormArray
  }
  charges(): FormArray {
    return this.subscriptionForm.get("charge_list") as FormArray
  }
  coupons(): FormArray {
    return this.subscriptionForm.get("coupon_list") as FormArray
  }

  getControlLabel(type: string) {
    return this.subscriptionForm.controls[type].value;
  }

  Addaddon(addon: any): FormGroup {
    return this.fb.group({
      subscription_addon_unique_id: addon.subscription_addon_unique_id,
      addon_schedule_unique_id: addon.addon_schedule_unique_id,
      addon_name: addon.addon_name,
      addon_unique_id: addon.addon_unique_id,
      subscription_unique_id: addon.subscription_unique_id,
      addon_frequency: addon.addon_frequency,
      addon_price: addon.addon_price,
      addon_price_breakdown: addon.addon_price_breakdown,
      addon_price_structure: addon.addon_price_structure,
      addon_quantity: addon.addon_quantity,
      is_plan_attachment: addon.is_plan_attachment,
      is_active: false
    })
  }
  AddCharge(charge: any): FormGroup {
    return this.fb.group({
      subscription_charge_unique_id: charge.subscription_charge_unique_id,
      charge_schedule_unique_id: charge.charge_schedule_unique_id,
      charge_name: charge.charge_name,
      charge_unique_id: charge.charge_unique_id,
      subscription_unique_id: charge.subscription_unique_id,
      charge_price: charge.charge_price,
      charge_quantity: charge.charge_quantity,
      is_plan_attachment: charge.is_plan_attachment,
      charge_price_breakdown: charge.charge_price_breakdown,
      charge_price_structure: charge.charge_price_structure,
      is_active: false
    })
  }
  AddCoupon(coupon: any): FormGroup {
    return this.fb.group({
      subscription_coupon_unique_id: coupon.subscription_coupon_unique_id,
      coupon_name: coupon.coupon_name,
      discount_type: coupon.discount_type,
      discount_unit: coupon.discount_unit,
      coupon_unique_id: coupon.coupon_unique_id,
      subscription_unique_id: coupon.subscription_unique_id,
      coupon_discount: coupon.coupon_discount,
      is_active: coupon.is_active
    })
  }
  constructor(private fb: FormBuilder, private _subscriptionservice: SubscriptionService,
    private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService,
    private _accountservice: AccountService, private _customerService: CustomerService, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
    CheckoutComponent.GetInstance = this;
    this.subscriptionForm = this.fb.group({
      "entity_id": [0],
      "subscription_unique_id": [''],
      "customer_unique_id": [''],
      "product_unique_id": [''],
      "plan_unique_id": [''],
      "subscription_name": ['', Validators.required],
      "subscription_frequency": [1],
      "subscription_currency": [''],
      "subscription_startdate": [],
      "subscription_nextbilldate": [],
      "subscription_expirydate": [],
      "subscription_plan_price": [0],
      "subscription_plan_price_breakdown": [],
      "subscription_plan_price_structure": [1],
      "subscription_plan_quantity": [1],
      "plan_name": [''],
      "billing_cycle": [1],
      "billed_untill": [],
      "is_auto_collect": ['1'],
      "is_active": true,
      "is_trial_enabled": [false],
      "trial_frequency": [1],
      "trial_frequency_number": [0],
      "addon_list": this.fb.array([]),
      "charge_list": this.fb.array([]),
      "coupon_list": this.fb.array([])
    });
    this.loginForm = this.fb.group({
      "email": ['', [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)]],
      "password": ['']
    });
    this.customerForm = this.fb.group({
      "customer_unique_id": [''],
      "first_name": ['', [Validators.required]],
      "last_name": ['', [Validators.required]],
      "phone_number": ['', [Validators.required, Validators.minLength(10)]],
      "country_code": [''],
      "customer_email": ['', [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)]],
      "is_active": 1,
      "is_auto_collect": ['1'],
      "customer_timezone_id": 1,
      "customer_company": [''],
      "shipping_first_name": [''],
      "shipping_last_name": [''],
      "shipping_city": [''],
      "shipping_state": [''],
      "shipping_country": [''],
      "shipping_zipcode": [''],
      "shipping_address1": [''],
      "shipping_address2": [''],
      "customer_language": ['']
    });

    this.cardForm = this.fb.group({
      "account_first_four": [''],
      "account_last_four": [''],
      "account_number": ['', Validators.required],
      "account_type": [''],
      "account_expiry_date": [''],
      "cvv": [''],
      "expMonth": ['', [Validators.required]],
      "expYear": ['', [Validators.required]],
      "account_token": [''],
      "entity_id": [0],
      "customer_unique_id": [''],
      "billing_address1": [''],
      "billing_address2": [''],
      "billing_country_id": [0],
      "billing_state_id": [0],
      "billing_city": [''],
      "billing_postal": [''],
      "is_default": true
    });

  }

  ngOnInit() {
    document.documentElement.style.setProperty("--theme-color", '#009ef7');
    this.GetCountryList();
    this.GetStateList();
    this.activatedRoute.queryParams.subscribe(async params => {
      this.apikey = params['apikey'];
      this.planid = params['planuniqueid'];
      this.planpriceid = params['planScheduleUniqueId'];

    })
    this.subscriptionForm.get('subscription_startdate')?.setValue(this.date);
    if (this.apikey != '' && this.planid != '') {
      this.getplandetails();
      this.ApplyCheckOutSettings();
    }

  }

  async LoadPaymentTokenScript() {

    let checkoutScript = this._renderer2.createElement('script');
    checkoutScript.type = "text/javascript";
    checkoutScript.src = this.GatewaySettings.gateway_jsurl;
    checkoutScript.setAttribute("data-paymenttokenkey", this.GatewaySettings.gateway_jskey);
    this._renderer2.appendChild(this._document.body, checkoutScript);

  }

  async fetchgatewayconfiguration() {
    let request = { entity_id: 0 };
    let response = await this._accountservice.FetchGatewayConfiguration(request);
    if (response.code == 1) {
      this.GatewaySettings = response.data;
    }
  }
  async GetCountryList() {
    const response: WebApiResponse = await this._accountservice.GetCountryList()
    if (response.code == 1) {
      this.CountryList = response.data;
    }
  }
  async GetStateList() {
    const response: WebApiResponse = await this._accountservice.GetStateList()
    if (response.code == 1) {
      this.StateList = response.data;
      this.CountryStateList = this.StateList;
    }
  }
  onCountryChangeNew(e: any) {
    this.customerForm.get('phone_number')?.setValue("");
  }

  async viewinvoicebreakdown() {
    this.chargenow = false;
    this.IsPlanAdd = false;
    this.isemail = false;
    this.chargebreakdown = true;
  }
  async showsubitem() {
    await this.HideFlatFeeAddons();
    await this.HideFlatFeeCharges();
    this.isemail = false;
    this.chargebreakdown = false;
    this.chargenow = true;
    this.IsPlanAdd = true;

  }
  async showemail() {
    this.subscriptionForm.get('subscription_frequency')?.setValue(this.plan_subscription_frequency);
    this.subscriptionForm.get('subscription_plan_price')?.setValue(this.subscription_plan_price);
    this.IsPlanAdd = false;
    this.chargebreakdown = false;
    this.isemail = true;
    this.chargenow = true;
  }

  async showcustomerdetail() {
    this.IsSubscribePay = false;
    this.chargenow = false;
    this.IsPlanAdd = false;
    this.chargebreakdown = false;
    this.isemail = false;
    this.IsCustomerDetails = true;
    this.customerForm.get('customer_email').setValue(this.loginForm.get('email').value);
  }
  async showshippingdetails() {
    if (this.customerForm.get('phone_number')?.value != null) {
      let phone = this.customerForm.get('phone_number')?.value;
      this.customerForm.get('country_code')?.setValue(phone.dialCode);
      this.customerForm.get('phone_number')?.setValue(phone.nationalNumber);
    }
    console.log(this.customerForm.get('phone_number')?.value);
    this.IsSubscribePay = false;
    this.chargenow = false;
    this.IsPlanAdd = false;
    this.chargebreakdown = false;
    this.isemail = false;
    this.IsCustomerDetails = false;
    this.IsShippingDetails = true;
  }
  async showcarddetails() {
    this.IsSubscribePay = false;
    this.chargenow = false;
    this.IsPlanAdd = false;
    this.chargebreakdown = false;
    this.isemail = false;
    this.IsCustomerDetails = false;
    this.IsShippingDetails = false;
    this.IsCardDetails = true;
    await this.fetchgatewayconfiguration();
    await this.LoadPaymentTokenScript();
  }
  async showpaysubscribe() {
    this.cardForm.get('account_type').setValue(this.CardType);
    this.cardForm.get('account_first_four').setValue(this.cardForm.get('account_number').value.substring(0, 5));
    this.cardForm.get('account_last_four').setValue(this.cardForm.get('account_number').value.substring(this.cardForm.get('account_number').value.length - 4));
    this.chargenow = false;
    this.IsPlanAdd = false;
    this.chargebreakdown = false;
    this.isemail = false;
    this.IsCustomerDetails = false;
    this.IsShippingDetails = false;
    this.IsCardDetails = false;
    this.IsSubscribePay = true;
  }

  async GetPaymentToken() {
    this.cardForm.get('account_expiry_date')?.setValue(this.cardForm.get('expMonth')?.value + this.cardForm.get('expYear')?.value);
    let n = document.getElementById('onepayResultToken') as HTMLInputElement;
    n.value = '';
    let result = CardTokenize("cardNumber", "expMonth", "expYear", "securityCode", "cardError", "onepayResultToken");
    if (result) {
      let res = (document.getElementById('onepayResultToken') as HTMLInputElement).value;
      let data = JSON.parse(res);
      CheckoutComponent.GetInstance.cardForm.get('account_number')?.setValue('');
      if (data.result_code == 1) {
        CheckoutComponent.GetInstance.cardForm.get('account_token')?.setValue(data.token);
        const respons: WebApiResponse = await CheckoutComponent.GetInstance._accountservice.ManageAccount(CheckoutComponent.GetInstance.cardForm.value);
        if (respons.code == 1) {
          this.cardForm.reset();
          this.ngOnInit();
          this.CardType = '';
        }
        else {
          alert(data.message);
        }
      }
    }
  }

  showCoupon() {
    this.toggleshowCoupon = true;
    this.toggleshowCouponLabel = false;
  }

  cancelcoupon() {
    this.toggleshowCouponLabel = true;
    this.toggleshowCoupon = false;
  }

  async getplandetails() {
    this.spinner.show();
    let request = { apikey: this.apikey, plan_unique_id: this.planid }
    const response: WebApiResponse = await this._subscriptionservice.getplandetails(request);
    if (response.code == 1) {
      this.plandetails = response.data;
      this.subscriptionForm.get('product_unique_id').setValue(this.plandetails.product_unique_id);
      this.plan_unique_id = this.plandetails.plan_unique_id;
      this.plan_name = this.plandetails.plan_name;
      this.subscriptionForm.get('plan_unique_id')?.setValue(this.plan_unique_id);
      this.subscriptionForm.get('plan_name')?.setValue(this.plan_name);
      this.subscriptionForm.get('subscription_name')?.setValue(this.plan_name);
      this.IsMeteredPlan = this.plandetails.is_metered_plan;
      sessionStorage.setItem("SettingJson", this.plandetails.settings);
      sessionStorage.setItem('CompanyTheme', this.plandetails.company_theme);

      let themecolor = window.sessionStorage.getItem("CompanyTheme");
      if (themecolor == null || themecolor == 'null') {
        themecolor = '#009ef7'
      }
      document.documentElement.style.setProperty("--theme-color", themecolor);
      let themecolorrgb = this.hexToRGBA(themecolor, 25);
      document.documentElement.style.setProperty("--theme-color-rgb", themecolorrgb);

      let schedular = this.plandetails.plan_schedular.filter(x => x.plan_schedule_unique_id == this.planpriceid);
      if (schedular.length > 0) {
        this.subscriptionForm.get('billing_cycle')?.setValue(schedular[0].billing_cycle);
        this.subscriptionForm.get('billed_untill')?.setValue(schedular[0].billed_untill);
        await this.onselectfreuency(schedular[0]);
        await this.GetPlanAttachment();
        await this.ShowBillingBreakdown();
        await this.viewplanbreakdown();
        await this.HideFlatFeeAddons();
        await this.HideFlatFeeCharges();
      }
      this.IsPlanAdd = true;
    }
    else {
      this.InValidDetails = true;
    }
    this.spinner.hide();
  }


  hexToRGBA(hex, opacity) {
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).concat(opacity + '%').join(',') + ')';
  }

  async GetPlanAttachment() {

    this.recommendattachmentlist = this.plandetails.plan_attachment.filter(x => !x.is_required);
    for (let k of this.recommendattachmentlist) {
      if (k.item_type == 2) {
        let addon = this.plandetails.sub_addon.filter(x => x.id == k.item_id);
        if (addon.length == 1) {
          let sdular = addon[0].addon_schedular_list.filter(x => x.billing_frequency == this.subscriptionForm.get('subscription_frequency').value);
          if (sdular.length == 0) {
            k.item_price = sdular[0].tiers[0].price;
          }
        }
      }
      if (k.item_type == 3) {
        let charge = this.plandetails.sub_charge.filter(x => x.id == k.item_id);
        if (charge.length == 1) {
          k.item_price = charge[0].sub_charge_schedular.tiers[0].price;
        }
      }
    }
    if (this.plandetails.plan_attachment.length > 0) {
      let attachaddonlist = this.plandetails.plan_attachment.filter((x: { item_type: number; is_required: boolean }) => x.item_type == 2 && x.is_required);
      if (attachaddonlist.length > 0) {
        let result = this.addonlist.filter(function (o1: any) {
          return attachaddonlist.some(function (o2: any) {
            return o1.addon_unique_id === o2.item_id;
          });
        })
        if (result.length == 0) {
          for (let i of attachaddonlist) {
            let addon = this.plandetails.sub_addon.filter(x => x.id == i.item_id);
            if (addon.length > 0) {
              let resul = addon[0].addon_schedular_list.filter(x => x.billing_frequency === this.plan_subscription_frequency)
              resul[0].is_plan_attachment = true;
              resul[0].addon_name = addon[0].addon_name;
              resul[0].addon_unique_id = addon[0].id;
              await this.onaddonselect(resul[0]);
            }
          }
        }
      }
      let attachchargelist = this.plandetails.plan_attachment.filter((x: { item_type: number; is_required: boolean }) => x.item_type == 3 && x.is_required);
      if (attachchargelist.length > 0) {
        let resultcharge = this.chargelist.filter(function (o1: any) {
          return attachchargelist.some(function (o2: any) {
            return o1.charge_unique_id === o2.item_id;
          });
        })
        if (resultcharge.length == 0) {
          for (let j of attachchargelist) {
            let charge = this.plandetails.sub_charge.filter(x => x.id == j.item_id);
            if (charge.length > 0) {
              charge[0].is_plan_attachment = true;
              await this.onchargeselect(charge[0]);
            }
          }
        }
      }
    }
  }
  async onchargeselect(event: any) {
    let obj = new subscriptioncharge();
    let data: any = event;
    if (data != null) {
      let pricebreakdown = Array<PriceBreakdown>();
      let presponse: any = event.sub_charge_schedular.tiers;
      if (presponse[0].pricing_structure != 1) {
        obj.charge_price = presponse[0].price;
        obj.charge_quantity = 1;
        let pricebdown = new PriceBreakdown();
        pricebdown.unit = 1;
        pricebdown.price = presponse[0].price;
        pricebreakdown.push(pricebdown);
        obj.charge_price_breakdown = new Array<PriceBreakdown>();
        obj.charge_price_breakdown = pricebreakdown;
      }
      else {
        obj.charge_quantity = 0;
        obj.charge_price = presponse[0].price;
      }
      obj.charge_price_structure = presponse[0].pricing_structure;
      obj.charge_schedule_unique_id = data.sub_charge_schedular.charge_schedule_unique_id;
      obj.charge_name = event.charge_name;
      obj.charge_unique_id = event.id;
      obj.is_plan_attachment = event.is_plan_attachment;
      obj.subscription_unique_id = this.subscriptionForm.get('subscription_unique_id')?.value;
      this.charges().push(this.AddCharge(obj));
      for (let i = 0; i < this.charges().length; i++) {
        let control = <FormArray>this.subscriptionForm.controls.charge_list;
        let fg: any = control.get([i]);
        if (obj.charge_unique_id == fg.get('charge_unique_id').value) {
          fg.get('charge_price_breakdown').setValue(pricebreakdown);
        }

      }
    }

    await this.ShowBillingBreakdown();
    await this.HideFlatFeeCharges();
  }

  async onaddonselect(event: any) {
    let obj = new subscriptionaddon();
    if (event != null) {
      let pricebreakdown = Array<PriceBreakdown>();
      let presponse: any = event.tiers;
      if (presponse[0].pricing_structure != 1) {
        obj.addon_price = presponse[0].price;
        obj.addon_quantity = 1;
        let pricebdown = new PriceBreakdown();
        pricebdown.price = presponse[0].price;
        pricebdown.unit = obj.addon_quantity;
        pricebreakdown.push(pricebdown);
        obj.addon_price_breakdown = new Array<PriceBreakdown>();
        obj.addon_price_breakdown.push(pricebdown);
      }
      else {
        obj.addon_quantity = 0;
        obj.addon_price = presponse[0].price;
      }
      obj.addon_price_structure = presponse[0].pricing_structure;
      obj.addon_schedule_unique_id = event.addon_schedule_unique_id;
      obj.addon_frequency = event.billing_frequency;
      obj.addon_name = event.addon_name;
      obj.addon_unique_id = event.addon_unique_id;
      obj.is_plan_attachment = event.is_plan_attachment;
      obj.subscription_unique_id = this.subscriptionForm.get('subscription_unique_id')?.value;
      this.addons().push(this.Addaddon(obj));
      for (let i = 0; i < this.addons().length; i++) {
        let control = <FormArray>this.subscriptionForm.controls.addon_list;
        let fg: any = control.get([i]);
        if (obj.addon_unique_id == fg.get('addon_unique_id').value) {
          fg.get('addon_price_breakdown').setValue(pricebreakdown);
        }
      }
    }

    await this.ShowBillingBreakdown();
    await this.HideFlatFeeAddons();

  }

  async oncouponselect(event: any) {
    let obj = new subscriptioncoupon();
    obj.subscription_unique_id = this.subscriptionForm.get('subscription_unique_id')?.value;
    obj.coupon_unique_id = event.coupon_unique_id;
    obj.coupon_name = event.coupon_name;
    obj.is_active = event.is_active;
    obj.discount_type = event.discount_type;
    if (obj.discount_type == 1) {
      obj.coupon_discount = event.discount_unit;
    }
    obj.discount_unit = event.discount_unit;
    this.coupons().push(this.AddCoupon(obj));
    if (this.subscriptionForm.get('coupon_list').value.length > 0 && this.checkoutsettings.allowCustomerToAddMultipleCoupons == '0') {
      this.ShowAddCoupon = false;
    }
    await this.ShowBillingBreakdown();
  }

  async validatecoupon(couponcode) {
    this.spinner.show();
    let request = { coupon_promo_code: couponcode };
    let response: any = await this._customerService.GetCouponByPromoCodeCheckout(request, this.apikey);
    if (response.code == 1) {
      let couponuniqueid = response.data.coupon_unique_id;
      if (this.coupons().length == 0) {
        this.oncouponselect(response.data);
      } else {
        for (let i = 0; i < this.coupons().length; i++) {
          let control = <FormArray>this.subscriptionForm.controls.coupon_list;
          let fg: any = control.get([i]);
          if (couponuniqueid != fg.get('coupon_unique_id').value) {
            this.oncouponselect(response.data);
          }
          else {
            this.iscouponerror = 'Coupon Already Added';
          }
        }
      }
      const ele = document.getElementById('promocode');
      (<HTMLInputElement>document.getElementById("promocode")).value = "";
    }
    else {
      this.iscouponerror = 'Invalid Coupon';
      setTimeout(() => this.iscouponerror = '', 3000);
    }
    this.spinner.hide();
  }

  async ShowBillingBreakdown() {
    this.new_total_subscription_price = 0;
    this.total_sub_total = 0;
    this.total_discount_price = 0;
    this.total_credit_charges = 0;
    this.invoice_total = 0;
    if (!this.isedit) {
      this.new_total_subscription_price = this.new_total_subscription_price + this.subscription_plan_price;
      this.invoiceitems = [];
      if (this.addons().length > 0) {
        for (let i = 0; i < this.addons().length; i++) {
          this.new_total_subscription_price += this.addons().value[i].addon_price;
        }
      }
      if (this.charges().length > 0) {
        for (let i = 0; i < this.charges().length; i++) {
          this.new_total_subscription_price += this.charges().value[i].charge_price;
        }
      }
      this.total_sub_total = parseInt(this.new_total_subscription_price);
      this.total_sub_total = (this.total_sub_total).toFixed(2);

      if (this.coupons().length > 0) {
        if (this.coupons().length == 2) {
          let newcouponlist = [];
          let fixamountcoupon = this.coupons().value.filter((x: { discount_type: any; }) => x.discount_type == 1);
          if (fixamountcoupon != null) {
            newcouponlist.push(fixamountcoupon);
          }
          let percentagecoupon = this.coupons().value.filter((x: { discount_type: any; }) => x.discount_type == 2);
          if (percentagecoupon != null) {
            newcouponlist.push(percentagecoupon);
          }
          this.coupons().clear();
          this.coupons().push(this.AddCoupon(fixamountcoupon[0]));
          this.coupons().push(this.AddCoupon(percentagecoupon[0]));
        }
        for (let i = 0; i < this.coupons().length; i++) {
          let InvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
          if (this.coupons().value[i].discount_type == 1) {
            this.total_discount_price = this.total_discount_price + this.coupons().value[i].discount_unit;
            InvoiceDetailRequest.total_amount = this.coupons().value[i].discount_unit;
            InvoiceDetailRequest.item_description = this.coupons().value[i].coupon_name;
            InvoiceDetailRequest.item_type = ItemType.Coupon;
            this.invoiceitems.push(InvoiceDetailRequest);
            this.new_total_subscription_price = this.new_total_subscription_price - this.coupons().value[i].discount_unit;
            let control = <FormArray>this.subscriptionForm.controls.coupon_list;
            let fg: any = control.get([i]);
            fg.get('coupon_discount').setValue(this.coupons().value[i].discount_unit);

          }
          else {

            let discountamount = (this.coupons().value[i].discount_unit / 100) * this.new_total_subscription_price;
            if (discountamount <= 0) {
              discountamount = Math.abs(discountamount);
            }
            this.total_discount_price = this.total_discount_price + discountamount;
            InvoiceDetailRequest.item_description = this.coupons().value[i].coupon_name;
            InvoiceDetailRequest.total_amount = this.new_total_subscription_price - discountamount;
            InvoiceDetailRequest.item_type = ItemType.Coupon;
            this.invoiceitems.push(InvoiceDetailRequest);
            this.new_total_subscription_price = this.new_total_subscription_price - discountamount;
            let control = <FormArray>this.subscriptionForm.controls.coupon_list;
            let fg: any = control.get([i]);
            fg.get('coupon_discount').setValue(discountamount);

          }

        }
      }
      let InvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
      let pricebreakdown = new Array<PriceBreakdown>();
      if (this.subscriptionForm.get('subscription_plan_price_structure')?.value > 2) {
        this.pricelist = this.plandetails.plan_schedular.filter(x => x.plan_schedule_unique_id == this.planpriceid)[0].plan_pricing_list;
        await this.AddSubscriptionPrice(this.subscriptionForm.get('subscription_plan_quantity')?.value, ItemType.Plan, 0);
        for (let m of this.pricelist) {
          let pricebdown = new PriceBreakdown();
          if (m.unit > 0) {
            pricebdown.price = m.price;
            pricebdown.unit = m.unit;
            pricebreakdown.push(pricebdown);
          }
        }
      }
      InvoiceDetailRequest.price_breakdown = pricebreakdown;
      InvoiceDetailRequest.item_description = this.subscriptionForm.get('plan_name')?.value;
      InvoiceDetailRequest.item_unit = this.subscriptionForm.get('subscription_plan_quantity')?.value;
      InvoiceDetailRequest.total_amount = this.subscription_plan_price;
      InvoiceDetailRequest.item_type = ItemType.Plan;
      this.invoiceitems.push(InvoiceDetailRequest);

      if (this.addons().length > 0) {
        for (let i = 0; i < this.addons().length; i++) {
          let AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
          let pricebreakdown = new Array<PriceBreakdown>();
          if (this.addons().value[i].addon_price_structure > 2) {
            let addonschedular = this.plandetails.sub_addon.filter(x => x.id == this.addons().value[i].addon_unique_id);
            if (addonschedular.length > 0) {
              this.pricelist = addonschedular[0].addon_schedular_list.filter(x => x.addon_schedule_unique_id == this.addons().value[i].addon_schedule_unique_id)[0].tiers;
              await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
              for (let m of this.pricelist) {
                let pricebdown = new PriceBreakdown();
                if (m.unit > 0) {
                  pricebdown.price = m.price;
                  pricebdown.unit = m.unit;
                  pricebreakdown.push(pricebdown);
                }
              }
            }
          }
          AddonInvoiceDetailRequest.price_breakdown = pricebreakdown;
          AddonInvoiceDetailRequest.item_description = this.addons().value[i].addon_name;
          AddonInvoiceDetailRequest.item_unit = this.addons().value[i].addon_quantity;
          AddonInvoiceDetailRequest.total_amount = this.addons().value[i].addon_price;
          AddonInvoiceDetailRequest.item_type = ItemType.Addon;
          this.invoiceitems.push(AddonInvoiceDetailRequest);
        }
      }
      if (this.charges().length > 0) {
        for (let i = 0; i < this.charges().length; i++) {
          let ChargeInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
          let pricebreakdown = new Array<PriceBreakdown>();
          if (this.charges().value[i].charge_price_structure > 2) {
            let chargeschedular = this.plandetails.sub_charge.filter(x => x.id == this.charges().value[i].charge_unique_id);
            if (chargeschedular.length > 0) {
              this.pricelist = chargeschedular[0].sub_charge_schedular.tiers;
              await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, i);
              for (let m of this.pricelist) {
                let pricebdown = new PriceBreakdown();
                if (m.unit > 0) {
                  pricebdown.price = m.price;
                  pricebdown.unit = m.unit;
                  pricebreakdown.push(pricebdown);
                }
              }
            }
          }
          ChargeInvoiceDetailRequest.price_breakdown = pricebreakdown;
          ChargeInvoiceDetailRequest.item_unique_id = this.charges().value[i].charge_unique_id;
          ChargeInvoiceDetailRequest.item_description = this.charges().value[i].charge_name;
          ChargeInvoiceDetailRequest.item_unit = this.charges().value[i].charge_quantity;
          ChargeInvoiceDetailRequest.total_amount = this.charges().value[i].charge_price;
          ChargeInvoiceDetailRequest.item_type = ItemType.Charge;
          this.invoiceitems.push(ChargeInvoiceDetailRequest);
        }
      }
      this.invoiceitems = this.invoiceitems.sort((a, b) => (a.item_type > b.item_type) ? 1 : -1);
      this.invoice_total = (this.new_total_subscription_price).toFixed(2);
      if (this.invoice_total < 0) {
        this.invoice_total = (0).toFixed(2);
      }
      this.total_discount_price = (this.total_discount_price).toFixed(2);
      if (this.new_total_subscription_price < 0) {
        this.new_total_subscription_price = (0).toFixed(2);
      }
      else {
        this.new_total_subscription_price = (this.new_total_subscription_price).toFixed(2);
      }
      for (var k of this.invoiceitems) {
        if (k.item_unit == 0) {
          k.item_unit = "-";
        }
        k.total_amount = k.total_amount.toFixed(2);
      }

    }
  }
  async viewplanbreakdown() {
    this.pricelist = this.plandetails.plan_schedular.filter(x => x.plan_schedule_unique_id == this.planpriceid)[0].plan_pricing_list;
    let pricebreakdown = new Array<PriceBreakdown>();
    await this.AddSubscriptionPrice(this.subscriptionForm.get('subscription_plan_quantity')?.value, 1, 0);
    for (let m of this.pricelist) {
      let pricebdown = new PriceBreakdown();
      if (m.unit > 0) {
        pricebdown.price = m.price;
        pricebdown.unit = m.unit;
        pricebreakdown.push(pricebdown);
      }
    }
    this.plan_price_breakdown = pricebreakdown;
    this.subscriptionForm.get('subscription_plan_price_breakdown')?.setValue(pricebreakdown);
    await this.ShowSubscriptionPriceChange();
  }

  async viewaddonbreakdown(i: number) {

    let addonschedular = this.plandetails.sub_addon.filter(x => x.id == this.addons().value[i].addon_unique_id);
    if (addonschedular.length > 0) {
      this.pricelist = addonschedular[0].addon_schedular_list.filter(x => x.addon_schedule_unique_id == this.addons().value[i].addon_schedule_unique_id)[0].tiers;
      await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, 2, i);
      let pricebreakdown = new Array<PriceBreakdown>();
      for (let m of this.pricelist) {
        let pricebdown = new PriceBreakdown();
        if (m.unit > 0) {
          pricebdown.price = m.price;
          pricebdown.unit = m.unit;
          pricebreakdown.push(pricebdown);
        }
      }
      let control = <FormArray>this.subscriptionForm.controls.addon_list;
      let fg: any = control.get([i]);
      fg.get('addon_price_breakdown').setValue(pricebreakdown);
      await this.ShowSubscriptionPriceChange();
    }
  }
  async viewchargebreakdown(i: number) {

    let chargeschedular = this.plandetails.sub_charge.filter(x => x.id == this.charges().value[i].charge_unique_id);
    if (chargeschedular.length > 0) {
      this.pricelist = chargeschedular[0].sub_charge_schedular.tiers;
      await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, 3, i);
      let pricebreakdown = new Array<PriceBreakdown>();
      for (let m of this.pricelist) {
        let pricebdown = new PriceBreakdown();
        if (m.unit > 0) {
          pricebdown.price = m.price;
          pricebdown.unit = m.unit;
          pricebreakdown.push(pricebdown);
        }
      }
      let control = <FormArray>this.subscriptionForm.controls.charge_list;
      let fg: any = control.get([i]);
      fg.get('charge_price_breakdown').setValue(pricebreakdown);
      await this.ShowSubscriptionPriceChange();
    }
  }


  async onRecomendselect(item: any) {
    if (item.item_type == '2') {
      let addon = this.plandetails.sub_addon.filter(x => x.id == item.item_id);
      if (addon.length > 0) {
        let resul = addon[0].addon_schedular_list.filter(x => x.billing_frequency === this.plan_subscription_frequency)
        resul[0].is_plan_attachment = false;
        resul[0].addon_name = addon[0].addon_name;
        resul[0].addon_unique_id = addon[0].id;
        await this.onaddonselect(resul[0]);
      }
    }
    else if (item.item_type == '3') {
      let charge = this.plandetails.sub_charge.filter(x => x.id == item.item_id);
      if (charge.length > 0) {
        charge[0].is_plan_attachment = false;
        await this.onchargeselect(charge[0]);
      }
      this.recommendattachmentlist = this.recommendattachmentlist.filter(x => x.item_id != item.item_id);
    }

  }
  async removeaddon(i: number) {
    this.addons().removeAt(i);
    this.recommendattachmentlist = this.plandetails.plan_attachment.filter(x => !x.is_required);
    await this.ShowBillingBreakdown();
  }
  async removecharge(i: number) {
    this.charges().removeAt(i);
    this.recommendattachmentlist = this.plandetails.plan_attachment.filter(x => !x.is_required);
    await this.ShowBillingBreakdown();
  }
  async removecoupon(i: number) {
    this.coupons().removeAt(i);
    if (this.coupons.length == 0 && this.checkoutsettings.allowCustomerToAddRemoveCoupons == '1') {
      this.ShowAddCoupon = true;
    }
    await this.ShowBillingBreakdown();
  }


  async onselectfreuency(schedular: any) {
    this.scheduleruniqueId = schedular.plan_schedule_unique_id
    this.plan_subscription_frequency = schedular.billing_frequency;
    this.frequency_name = schedular.frequency_name;
    this.plan_free_quantity = schedular.free_quantity;
    this.subscriptionForm.get('is_trial_enabled')?.setValue(schedular.is_trial_enabled);
    this.subscriptionForm.get('trial_frequency')?.setValue(schedular.trial_frequency);
    this.subscriptionForm.get('trial_frequency_number')?.setValue(schedular.trial_frequency_number);
    await this.GetPlanpricingbyschedule(schedular);
    if (this.subscriptionForm.get('is_trial_enabled')?.value) {
      this.is_trial_enable = true;
      this.subscriptionForm.get('subscription_nextbilldate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(this.subscriptionForm.get('trial_frequency')?.value * this.subscriptionForm.get('trial_frequency_number')?.value, 'days'));
      this.subscriptionForm.get('subscription_expirydate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(parseInt(this.subscriptionForm.get('trial_frequency')?.value) * parseInt(this.subscriptionForm.get('trial_frequency_number')?.value), 'days'));
      this.next_billing_date = moment(this.subscriptionForm.get('subscription_nextbilldate')?.value).format("MM/DD/YYYY hh:MM A");
    }
    else {
      this.is_trial_enable = false;
      this.subscriptionForm.get('subscription_nextbilldate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(this.plan_subscription_frequency, 'days'));
      this.subscriptionForm.get('subscription_expirydate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(this.plan_subscription_frequency, 'days'));
      this.next_billing_date = moment(this.subscriptionForm.get('subscription_nextbilldate')?.value).format("MM/DD/YYYY hh:MM A");
    }
    if (!this.subscriptionForm.get('is_trial_enabled')?.value && moment(new Date()).format("MM/DD/YYYY") == moment(this.subscriptionForm.get('subscription_startdate')?.value).format("MM/DD/YYYY")) {
      this.WhenCharge = "Immediately";
      this.IsChargeImmediately = true;
      this.chargenow = true;
    }
    else if (this.subscriptionForm.get('is_trial_enabled')?.value) {
      this.WhenCharge = "on " + moment(this.subscriptionForm.get('subscription_nextbilldate')?.value).format("MM/DD/YYYY hh:MM A");
      this.IsChargeImmediately = false;
    }
    else {
      this.WhenCharge = "on " + moment(this.subscriptionForm.get('subscription_startdate')?.value).format("MM/DD/YYYY hh:MM A");
    }
  }
  async GetPlanpricingbyschedule(schedular) {
    let pricelist = schedular.plan_pricing_list;
    if (pricelist.length > 0) {
      if (this.addons().length > 0) {
        let rawvalue = this.subscriptionForm.getRawValue();
        let addonlist = rawvalue.addon_list;
        let control = <FormArray>this.subscriptionForm.controls.addon_list;
        for (let i = 0; i < addonlist.length; i++) {
          let fg: any = control.get([i]);
          if (fg.get('addon_frequency')?.value != this.plan_subscription_frequency) {
            this.addons().removeAt(i);
          }
        }
      }
      this.pricelist = [];
      this.pricelist = pricelist;
      this.subscriptionForm.get('subscription_plan_price_structure')?.setValue(this.pricelist[0].pricing_structure);
      for (let i of this.pricelist) {
        if (i.unit_to == 0) {
          i.unit_to = "& above";
        }
      }
      if (this.pricelist[0].pricing_structure == 3) {
        this.pricing_structure_name = "Tiered";
      }
      if (this.pricelist[0].pricing_structure == 5) {
        this.pricing_structure_name = "Volume";
      }
      if (this.pricelist[0].pricing_structure == 4) {
        this.pricing_structure_name = "StairStep";
      }
      if (this.pricelist[0].pricing_structure > 2) {
        this.IsPlanQuantity = true;
        this.IsTiered = true;
        this.subscription_plan_price = this.pricelist[0].price;
        for (let j of this.pricelist) {
          if (j == 0) {
            j.unit = 1;
            j.amount = j.price * 1;
          }
          else {
            j.unit = '-';
            j.amount = 0;
          }
        }
      }
      else {
        if (this.pricelist[0].pricing_structure == 1) {
          this.IsPlanQuantity = false;
        }
        else {
          this.IsPlanFreeQuantity = true;
          this.IsPlanQuantity = true;
        }
        if (this.plan_free_quantity > 1) {
          this.subscription_plan_price = 0;
        }
        else {
          this.subscription_plan_price = this.pricelist[0].price;
        }

      }
    }
  }


  async ShowSubscriptionPriceChange() {
    this.new_total_subscription_price = 0;
    this.total_sub_total = 0;
    this.total_discount_price = 0;
    this.invoice_total = 0;
    if (!this.isedit) {
      this.new_total_subscription_price = this.new_total_subscription_price + this.subscription_plan_price;
      if (this.addons().length > 0) {
        for (let i = 0; i < this.addons().length; i++) {
          this.new_total_subscription_price += this.addons().value[i].addon_price;
        }
      }
      if (this.charges().length > 0) {
        for (let i = 0; i < this.charges().length; i++) {
          this.new_total_subscription_price += this.charges().value[i].charge_price;
        }
      }
      this.total_sub_total = parseInt(this.new_total_subscription_price);
      this.total_sub_total = (this.total_sub_total).toFixed(2);
      if (this.coupons().length > 0) {
        if (this.coupons().length == 2) {
          let newcouponlist = [];
          let fixamountcoupon = this.coupons().value.filter((x: { discount_type: any; }) => x.discount_type == 1);
          if (fixamountcoupon != null) {
            newcouponlist.push(fixamountcoupon);
          }
          let percentagecoupon = this.coupons().value.filter((x: { discount_type: any; }) => x.discount_type == 2);
          if (percentagecoupon != null) {
            newcouponlist.push(percentagecoupon);
          }
          this.coupons().clear();
          this.coupons().push(this.AddCoupon(fixamountcoupon[0]));
          this.coupons().push(this.AddCoupon(percentagecoupon[0]));
        }
        for (let i = 0; i < this.coupons().length; i++) {
          if (this.coupons().value[i].discount_type == 1) {
            this.total_discount_price = this.total_discount_price + this.coupons().value[i].discount_unit;
            this.new_total_subscription_price = this.new_total_subscription_price - this.coupons().value[i].discount_unit;
            let control = <FormArray>this.subscriptionForm.controls.coupon_list;
            let fg: any = control.get([i]);
            fg.get('coupon_discount').setValue(this.coupons().value[i].discount_unit.toFixed(2));

          }
          else {
            let discountamount = (this.coupons().value[i].discount_unit / 100) * this.new_total_subscription_price;
            this.total_discount_price = this.total_discount_price + discountamount;
            this.new_total_subscription_price = this.new_total_subscription_price - discountamount;
            let control = <FormArray>this.subscriptionForm.controls.coupon_list;
            let fg: any = control.get([i]);
            fg.get('coupon_discount').setValue(discountamount.toFixed(2));

          }

        }
      }
      this.invoice_total = (this.new_total_subscription_price).toFixed(2);
      if (this.invoice_total < 0) {
        this.invoice_total = (0).toFixed(2);
      }
      this.total_discount_price = (this.total_discount_price).toFixed(2);
      if (this.new_total_subscription_price < 0) {
        this.new_total_subscription_price = (0).toFixed(2);
      }
      else {
        this.new_total_subscription_price = (this.new_total_subscription_price).toFixed(2);
      }
      for (let k of this.invoiceitems) {
        if (k.item_unit == 0) {
          k.item_unit = "-";
        }
      }

    }

  }


  async HideFlatFeeAddons() {
    setTimeout(() => {
      let rawvalue = this.subscriptionForm.getRawValue();
      let addonlist = rawvalue.addon_list;
      let control = <FormArray>this.subscriptionForm.controls.addon_list;
      for (let i = 0; i < addonlist.length; i++) {
        let fg: any = control.get([i]);
        if (fg.get('addon_price_structure')?.value == 1) {
          const output = document.getElementById('addonquantity' + i);
          const breakdown = document.getElementById('addonbreakdown' + i);
          if (breakdown) {
            breakdown.style.display = 'none';
          }
          if (output) {
            output.style.display = 'none';
          }
        }
        else {
          const output = document.getElementById('addonquantity' + i);
          const breakdown = document.getElementById('addonbreakdown' + i);
          if (breakdown) {
            if (fg.get('addon_price_structure')?.value == 2) {
              breakdown.style.display = 'none';
            }
            else {
              breakdown.style.display = 'block';
            }
          }

          if (output) {
            output.style.display = 'block';
          }
        }
      }
    }, 10);
  }

  async HideFlatFeeCharges() {
    setTimeout(() => {
      let rawvalue = this.subscriptionForm.getRawValue();
      let chargelist = rawvalue.charge_list;
      let control = <FormArray>this.subscriptionForm.controls.charge_list;
      for (let i = 0; i < chargelist.length; i++) {
        let fg: any = control.get([i]);
        if (fg.get('charge_price_structure')?.value == 1) {
          const output = document.getElementById('chargequantity' + i);
          if (output) {
            output.style.display = 'none';
          }
          const breakdown = document.getElementById('chargebreakdown' + i);
          if (breakdown) {
            breakdown.style.display = 'none';
          }
        }
        else {
          const output = document.getElementById('chargequantity' + i);
          if (output) {
            output.style.display = 'block';
          }

          const breakdown = document.getElementById('chargebreakdown' + i);
          if (breakdown) {
            if (fg.get('charge_price_structure')?.value == 2) {
              breakdown.style.display = 'none';

            }
            else {
              breakdown.style.display = 'block';
            }
          }
        }
      }
    }, 10);
  }



  async AddSubscriptionPrice(quantityParam: any, Type: number, index: number) {
    let quantity = quantityParam;
    this.TotalUnits = quantity;
    this.TotalAmounts = 0;
    if (Type == 1) {
      if (this.plan_free_quantity >= quantity) {
        quantity = 0;
      }
      else {
        quantity = quantity - this.plan_free_quantity;
      }
    }
    if (this.pricelist[0].pricing_structure == 3) {
      let remainingquantity = 0;
      for (let i = 0; i < this.pricelist.length; i++) {
        if (i == 0) {
          if (quantity > this.pricelist[i].unit_to) {
            let newquantity = 0;
            remainingquantity = quantity - this.pricelist[i].unit_to;
            newquantity = quantity - remainingquantity;
            this.pricelist[i].unit = newquantity;
            this.pricelist[i].amount = this.pricelist[i].price * newquantity;
          }
          else {
            this.pricelist[i].unit = quantity;
            this.pricelist[i].amount = this.pricelist[i].price * quantity;
            remainingquantity = 0;
          }
        }
        else {
          if (remainingquantity > 0) {
            let newquantity = 0;
            if (this.pricelist[i].unit_to > 0) {
              let count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
              if (remainingquantity > count) {
                remainingquantity = remainingquantity - count;
                this.pricelist[i].unit = count;
                this.pricelist[i].amount = this.pricelist[i].price * count;
              }
            }
            else {
              this.pricelist[i].unit = remainingquantity;
              this.pricelist[i].amount = this.pricelist[i].price * remainingquantity;
              remainingquantity = 0;
            }
          }
          else {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;
          }
        }

      }

    }
    if (this.pricelist[0].pricing_structure == 4) {
      for (let i = 0; i < this.pricelist.length; i++) {
        let count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (quantity == 0) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else if (isNaN(count)) {
          let j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = quantity;
            this.pricelist[i].amount = this.pricelist[i].price;
          }
        }
        else if (quantity >= this.pricelist[i].unit_from && quantity <= this.pricelist[i].unit_to) {
          this.pricelist[i].unit = quantity;
          this.pricelist[i].amount = this.pricelist[i].price;
          quantity = 0;

        }
        else {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
      }
    }
    if (this.pricelist[0].pricing_structure == 5) {
      for (var i = 0; i < this.pricelist.length; i++) {
        var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (quantity == 0) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else if (isNaN(count)) {
          var j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = quantity;
            this.pricelist[i].amount = this.pricelist[i].price * quantity;
          }
        }
        else if (quantity >= this.pricelist[i].unit_from && quantity <= this.pricelist[i].unit_to) {
          this.pricelist[i].unit = quantity;
          this.pricelist[i].amount = this.pricelist[i].price * quantity;
          quantity = 0;

        }
        else {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
      }
    }
    if (this.pricelist[0].pricing_structure > 2) {
      for (var i = 0; i < this.pricelist.length; i++) {
        this.TotalAmounts += this.pricelist[i].amount;
      }
    }
    else if (this.pricelist[0].pricing_structure == 2) {
      this.TotalAmounts = this.pricelist[0].price * quantity;
    }
    else if (this.pricelist[0].pricing_structure == 1) {
      this.TotalAmounts = this.pricelist[0].price;
    }

    if (Type == 1) {
      this.subscription_plan_price = this.TotalAmounts;
    }

    if (Type == 2) {
      let control = <FormArray>this.subscriptionForm.controls.addon_list;
      let fg: any = control.get([index]);
      fg.get('addon_price').setValue(this.TotalAmounts);
    }

    else if (Type == 3) {
      let control = <FormArray>this.subscriptionForm.controls.charge_list;
      let fg: any = control.get([index]);
      fg.get('charge_price').setValue(this.TotalAmounts);
    }
  }

  async GetInvoicePriceBreakdown(NewQuantity: any, OldQuantity: any, PricingStructure: any, PriceList: any, ExistPrice: any) {
    let response = new InvoicePriceResponse();
    let price = 0;
    let credit = 0;
    let listpricebreakdown = Array<PriceBreakdown>();
    if (PricingStructure == '3') {
      let totalunit = 0;
      for (let i of PriceList) {
        if (i.unit_to > 0) {
          totalunit += i.unit;
          if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to) && (NewQuantity >= i.unit_from && NewQuantity <= i.unit_to)) {
            let unit = NewQuantity - OldQuantity;
            OldQuantity = 0;
            if (unit > 0) {
              let pricepertier = i.price * unit;
              price += pricepertier;
              let pricebdown = new PriceBreakdown();
              pricebdown.price = i.price;
              pricebdown.unit = unit;
              listpricebreakdown.push(pricebdown);
            }
          }
          else if ((NewQuantity >= i.unit_from && NewQuantity <= i.unit_to) || ((OldQuantity != i.unit_to) && (i.unit_to > totalunit))) {
            if (i.unit > 0) {
              let unitone = i.unit;
              let pricepertierone = i.price * unitone;
              price += pricepertierone;
              let pricebdown = new PriceBreakdown();
              pricebdown.price = i.price;
              pricebdown.unit = unitone;
              listpricebreakdown.push(pricebdown);
              NewQuantity = 0;
            }
          }
          else if (((OldQuantity != i.unit_to) && (i.unit_to == totalunit && i.unit_to > OldQuantity))) {
            if (i.unit > 0) {
              let unitonet = 0;
              if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to)) {
                unitonet = i.unit_to - OldQuantity;
              }
              else {
                unitonet = i.unit;
              }

              let pricepertierone = i.price * unitonet;
              price += pricepertierone;
              let pricebdown = new PriceBreakdown();
              pricebdown.price = i.price;
              pricebdown.unit = unitonet;
              listpricebreakdown.push(pricebdown);
              NewQuantity = 0;
            }
          }
        }
        else if (i.unit > 0) {
          let unitonerr = 0;
          if (NewQuantity == 0) {
            unitonerr = i.unit;
          }
          else {
            unitonerr = NewQuantity - OldQuantity;
          }
          let pricepertierone = i.price * unitonerr;
          price += pricepertierone;
          let pricebdown = new PriceBreakdown();
          pricebdown.price = i.price;
          pricebdown.unit = unitonerr;
          listpricebreakdown.push(pricebdown);
          OldQuantity = 0;
        }

      }
    }
    else if (PricingStructure == '4') {
      for (let i of PriceList) {
        if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to) && (NewQuantity >= i.unit_from && NewQuantity <= i.unit_to)) {
          let unit = i.unit - OldQuantity;
          price += i.price;
        }
        else {
          if (i.unit > 0) {
            let unitoner = NewQuantity;
            price += i.price;
            credit = credit + ExistPrice;
          }
        }
      }
    }
    else if (PricingStructure == '5') {
      for (let i of PriceList) {
        if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to) && (NewQuantity >= i.unit_from && NewQuantity <= i.unit_to)) {
          let unit = i.unit - OldQuantity;
          price += unit * i.price;
        }
        else {
          if (i.unit > 0) {
            let unitonere = NewQuantity;
            price += unitonere * i.price;
            credit = credit + ExistPrice;
          }
        }
      }
    }
    response.price_breakdown = listpricebreakdown;
    response.price = price;
    response.credit = credit;
    return response;
  }

  onKeyCardNumber(event) {
    this.CardType = '';
    this.hideCardError = true;
    var number = this.cardForm.get('account_number').value;

    var re = new RegExp("^4");
    var revisa = new RegExp("^4");
    if (/^4/.test(number)) {
      var number = this.cardForm.get('account_number').value;
      if (number.length <= 8) {
        if (/^4/.test(number)) {
          this.image = "assets/images/visa.svg";
          this.CardType = 'VI';
          return;
        }
      }
      if (number.length >= 16) {
        if (this.luhnCheck(number)) {
          this.image = "assets/images/visa.svg";
          this.CardType = 'VI';
          //return "Visa";
          return;
        }
        else {
          if (this.CardType == '') {
            this.hideCardError = false;
            return;
          }
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    if (number.length <= 8) {
      if (/^(5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12})$/.test(number)) {
        this.image = "assets/images/mastercard.svg";
        //return "AMEX";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^(5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12})$/.test(number) && number.length >= 16) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/mastercard.svg";
        this.CardType = 'MC';
        //return "Mastercard";
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    // AMEX
    re = new RegExp("^3[47]");
    if (number.length <= 8) {
      if (/^3[47]/.test(number)) {
        this.image = "assets/images/American.svg";
        this.CardType = 'AX';
        //return "AMEX";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^3[47]/.test(number) != null && number.length >= 15) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/American.svg";
        this.CardType = 'AX';
        //return "Mastercard";
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.length == 8) {
      if (/^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/.test(number)) {
        this.image = "assets/images/Discover.svg";
        //return "AMEX";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/.test(number) && number.length >= 16) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/Discover.svg";
        this.CardType = 'DC';
        //return "Mastercard";
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          this.image = "";
          return;
        }
      }
    }

    // Diners
    var number = this.cardForm.get('account_number').value;
    re = new RegExp("^36");
    if (number.length <= 8) {
      if (/^36/.test(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        //return "AMEX";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^36/.test(number) && number.length >= 14) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        this.CardType = 'DS';
        //return "Mastercard";
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.length <= 8) {
      if (/^30[0-5]/.test(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        //return "AMEX";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^30[0-5]/.test(number) && number.length >= 14) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        this.CardType = 'DS';
        //return "Mastercard";
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.length == 6) {
      if (/^35(2[89]|[3-8][0-9])/.test(number)) {
        this.image = "assets/images/Jcbcard.svg";
        //return "AMEX";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^35(2[89]|[3-8][0-9])/.test(number) && number.length >= 15) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/Jcbcard.svg";
        this.CardType = 'JC';
        //return "Mastercard";
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }
    if (number.length >= 16) {
      if (this.CardType == '') {
        this.hideCardError = false;
        return;
      }
    }
    if (number.length == 0) {
      this.image = "";
      this.CardType = '';
    }
  }

  luhnCheck(number): boolean {
    let odd = true;
    let i = 0;
    let sum = 0;
    let digit: number;

    if (!number) {
      return false;
    }
    const digits = number.split('').reverse();
    const length = digits.length;
    for (i; i < length; i++) {
      digit = parseInt(digits[i], 10);
      if (odd = !odd) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  }

  async CreateCheckoutSubscription() {
    this.spinner.show();
    let request = new SubscriptionInvoiceModel();
    request.customer_unique_id = this.subscriptionForm.get('customer_unique_id')?.value;
    request.invoice_from = this.subscriptionForm.get('subscription_startdate')?.value;
    request.invoice_to = this.subscriptionForm.get('subscription_expirydate')?.value;
    request.billing_cycle = this.subscriptionForm.get('billing_cycle')?.value;
    request.invoice_amount = this.invoice_total;
    request.discount_amount = this.total_discount_price;
    request.credit_amount = this.total_credit_charges;
    for (let i of this.invoiceitems) {
      if (i.item_unit == '-') {
        i.item_unit = 0;
      }
    }
    request.invoice_details = this.invoiceitems;
    if (this.customerForm.get('phone_number')?.value != null) {
      let phone = this.customerForm.get('phone_number')?.value;
      this.customerForm.get('country_code')?.setValue(phone.dialCode);
      this.customerForm.get('phone_number')?.setValue(phone.nationalNumber);
    }
    await this.GetPaymentToken();
    let data = { subscription: this.subscriptionForm.value, customer: this.customerForm.value, card: this.cardForm.value, subscription_invoice: request };
    let response = await this._subscriptionservice.CreateCheckoutSubscription(data, this.apikey);
    if (response.code == 1) {
      this.IsSubscribePay = false;
      this.IsClose = true;
      this.SendResponseToParent(this.loginForm.get('email').value)
    }
    this.spinner.hide();
  }
  SendResponseToParent(response) {
    window.parent.postMessage(response, '*');
  }

  ApplyCheckOutSettings() {
    if (sessionStorage.getItem('SettingJson')) {
      let settings = JSON.parse(sessionStorage.getItem('SettingJson'));
      this.checkoutsettings = settings.CheckoutSettings;
      console.log(this.checkoutsettings);
      if (this.checkoutsettings.allowCustomerToChangePlanAddonChargequantity == '0') {
        this.QuantityRead = true;
      }
      if (this.checkoutsettings.showRecommendedaddon == '0') {
        this.ShowRecommended = false;
      }
      if (this.checkoutsettings.allowCustomerToAddRemoveCoupons == '0') {
        this.AllowAddDeletecoupons = false;
        this.ShowAddCoupon = false;
      }
      if (this.checkoutsettings.allowCustomerToCheckoutWithoutPaymentMethod == '0') {
        this.RestrictAddCard = false;
      }
    }

  }

}
