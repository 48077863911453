import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebApiResponse } from '../model/ApiResponse';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  IsEmailVerified: boolean = false;
  IsShowEmail: boolean = true;
  IsAlert: boolean = false;
  AlertMessage: string = "";
  email: string = "";
  apiKey: string = "";
  loginErrorMessage: string = "";
  hideLogin: boolean = false;
  LoginError: boolean = false;
  IsPasscode: number = 0;
  textValue: string = 'Password';
  AlertType: string = 'danger';
  timer: boolean = false;
  countdown: string;
  ResendOTP: boolean = false;

  constructor(private fb: FormBuilder, private loginservice: LoginService,
    private activatedroute: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) {

    let query_string = this.activatedroute.snapshot.queryParams;
    this.email = query_string["email"];
    this.apiKey = query_string["apikey"];

    this.loginForm = this.fb.group({
      "email": ['', [Validators.required]],
      "passcode": [''],
    });

  }

  ngOnInit() {
    sessionStorage.clear();
    document.documentElement.style.setProperty("--theme-color", '#009ef7');
    if (this.apiKey != undefined && this.apiKey != null && this.apiKey != '') {
      this.loginForm.get('email').setValue(this.email);
      this.IsEmailVerified = false;
      this.IsShowEmail = false;
      this.CheckUserEmail();
    }
    else {
      this.hideLogin = true;
    }
  }
  onKeydownMain(event) {
    if (event.keyCode == 13) {
      this.CheckUserEmail();
    }
  }
  onKeyPasscode(event) {
    if (event.keyCode == 13) {
      this.Verifypasscode();
    }
  }

  async CheckUserEmail() {
    this.spinner.show();
    let request = { "email": this.loginForm.get('email').value, "api_key": this.apiKey }
    sessionStorage.setItem('customer_email', this.email);
    let data: WebApiResponse = await this.loginservice.CheckUserEmail(request);
    if (data.code == 1) {
      const response: any = data.data;
      sessionStorage.setItem('access_token', response.token);
      sessionStorage.setItem('user_email', response.user_email);
      sessionStorage.setItem('entity_id', response.entity_id);
      sessionStorage.setItem('company_unique_id', response.company_unique_id);
      sessionStorage.setItem("SettingJson", response.settings);
      this.router.navigateByUrl('subscription');
    }
    else if (data.subcode == 106) {
      const response: any = data.data;
      this.IsPasscode = response;
      if (this.IsPasscode == 1) {
        this.textValue = 'Password';
      } else {
        this.IsAlert = true;
        this.AlertType = 'success';
        setTimeout(() => this.IsAlert = false, 5000);
        this.AlertMessage = data.message;
        this.textValue = 'Passcode'
        this.timer = true;
        this.starttimer();
      }
      this.IsEmailVerified = true;
      this.IsShowEmail = false;
    }
    else {
      this.IsAlert = true;
      setTimeout(() => this.IsAlert = false, 5000);
      this.AlertMessage = data.message;
      if (!this.hideLogin) {
        this.LoginError = true;
        this.loginErrorMessage = 'please contact admin';
      }
    }
    this.spinner.hide();
  }
  async Verifypasscode() {
    this.spinner.show();
    let request = { "username": this.loginForm.get('email').value, "passcode": this.loginForm.get('passcode').value, "is_passcode": this.IsPasscode }
    let data: WebApiResponse = await this.loginservice.VerifyPasscode(request);
    if (data.code == 1) {
      const response: any = data.data;
      sessionStorage.setItem('access_token', response.token);
      sessionStorage.setItem('user_email', response.user_email);
      sessionStorage.setItem('entity_id', response.entity_id);
      this.router.navigateByUrl('subscription');
    }
    else {
      this.IsAlert = true;
      setTimeout(() => this.IsAlert = false, 5000);
      this.AlertMessage = "Invalid passcode";
    }
    this.spinner.hide();
  }
  async ResendOtp() {
    this.spinner.show();
    let request = { "email": this.loginForm.get('email').value }
    let data: WebApiResponse = await this.loginservice.ResendOtp(request);
    if (data.code == 1) {
      this.IsAlert = true;
      this.AlertType = 'success';
      setTimeout(() => this.IsAlert = false, 5000);
      this.AlertMessage = data.message;
      this.textValue = 'Passcode'
      this.timer = true;
      this.starttimer();
    }
    else {
      this.IsAlert = true;
      setTimeout(() => this.IsAlert = false, 5000);
      this.AlertMessage = "Something went wrong";
    }
    this.spinner.hide();
  }

  starttimer() {
    this.ResendOTP = false;
    let timerout = 60;
    let initiTime = 0;
    this.countdown = timerout.toString();
    this.Function(timerout, initiTime);
  }

  Function(timerout, initiTime) {
    if (this.timer) {
      initiTime++;
      let minutimer = (timerout - initiTime);
      if (minutimer > 0) {
        this.countdown = minutimer.toString();
        setTimeout(() => this.Function(timerout, initiTime), 1000);
      } else {
        this.timer = false;
        this.ResendOTP = true;
      }
    }
  }
}
