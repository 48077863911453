<!-- <div class="container py-4">
  <div class="row">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <div></div>
      <div class="fs-3 fw-bold">Subscriptions List</div>
      <div class="cursor-pointer">
        <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
      </div>
    </div>
  </div>
</div> -->
<header class="header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <!-- <img src="assets/images/logo-1.svg" class="img-fluid" style="width: 200px;"/> -->
        <!-- <div></div> -->
        <div class="fs-4 fw-bold">Subscriptions List</div>
        <div class="cursor-pointer">
          <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
          <!--<i class="fas fa-sign-out-alt fa-fw fs-3" routerLink="/login" title="Sign Out"></i>-->
        </div>
      </div>
    </div>
  </div>
</header>