import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebApiResponse } from '../model/ApiResponse';
import { AppConfig } from '../shared/AppConfig';

@Injectable()
export class AccountService {
  protected _appset = AppConfig.settings;

  constructor(private _httpClient: HttpClient) { }

  async ManageAccountInfo(data: any) {
    const url = this._appset.APIUrl + "ManageAccountInfo";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async UpdateDefaultCard(data: any) {
    const url = this._appset.APIUrl + "UpdateDefaultCard";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetCompanyDetials(data) {
    const url = this._appset.APIUrl + "FetchEntity";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  
  async UpdateShippingDetails(data: any) {
    const url = this._appset.APIUrl + "UpdateShippingDetails";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
  async GetCountryList() {
    const url = this._appset.APIUrl + "FetchCountries";
    return this._httpClient.get<WebApiResponse>(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }
  async GetStateList() {
    const url = this._appset.APIUrl + "FetchStates";
    return this._httpClient.get<WebApiResponse>(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }

  async GetCustomers(data: any) {
    const url = this._appset.APIUrl + "GetCustomerInfo";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }


  async FetchGatewayConfiguration(data: any) {
    const url = this._appset.APIUrl + "FetchGatewayConfiguration";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
}
