import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebApiResponse } from '../model/ApiResponse';
import { AppConfig } from '../shared/AppConfig';

@Injectable()
export class SubscriptionService {


  protected _appset = AppConfig.settings;
  constructor(private _httpClient: HttpClient) { }

  async GetSubscriptionList(data: any) {
    const url = this._appset.APIUrl + "GetSubscriptionList";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }

  async getplandetails(data: any) {
    const url = this._appset.APIUrl + "GetPlanForCheckout";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('ApiKey', data.apikey)
        .set('No-Auth','True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }

  

  async GetSubscriptionInvoiceDetail(data: any) {
    const url = this._appset.APIUrl + "GetSubscriptionInvoiceDetail";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }




  async GenerateSubscriptionInvoice(data: any) {
    const url = this._appset.APIUrl + "GenerateSubscriptionInvoice";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }


  async GetPlanAttachment(data: any) {
    const url = this._appset.APIUrl + "GetPlanAttachment";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
  async GetSubscriptionItemPricing(data: any) {
    const url = this._appset.APIUrl + "GetSubscriptionItemPricing";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
  async UpdateSubscriptionStatus(data: any) {
    const url = this._appset.APIUrl + "UpdateSubscriptionStatus";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetSchedulePlans(data: any) {
    const url = this._appset.APIUrl + "GetSchedulePlans";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async SaveSubscription(data: any) {
    const url = this._appset.APIUrl + "SaveSubscription";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
  async CreateCheckoutSubscription(data: any,apikey:any) {
    const url = this._appset.APIUrl + "CreateCheckoutSubscription";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('ApiKey', apikey)
        .set('No-Auth', 'True')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  

  async GetPlanPricing(data: any) {
    const url = this._appset.APIUrl + "GetPlanPricing";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetPlanPricingBySchedule(data: any) {
    const url = this._appset.APIUrl + "GetPlanPricingBySchedule";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetAddonsByPlanFrequency(data: any) {
    const url = this._appset.APIUrl + "GetAddonsByPlanFrequency";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }


  async GetAddonPricing(data: any) {
    const url = this._appset.APIUrl + "GetAddonPricing";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });
  }

  async GetAddonPricingBySchedule(data: any) {
    const url = this._appset.APIUrl + "GetAddonPricingBySchedule";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetPaymentHistory(data: any) {
    const url = this._appset.APIUrl + "GetPaymentHistory";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetPaymentMethod(data: any) {
    const url = this._appset.APIUrl + "GetPaymentMethod";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetSettings(data: any) {
    const url = this._appset.APIUrl + "GetCustomerSettings";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async GetOrderDetail(data: any) {
    const url = this._appset.APIUrl + "GetOrderDetail";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }

  async ManageOrderState(data: any) {
    const url = this._appset.APIUrl + "ManageOrderState";
    return this._httpClient.post<WebApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      let res = new WebApiResponse();
      res.code = 108;
      res.message = errorRes.Message;
      return res;
    });

  }
}
