import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { WebApiResponse } from '../model/ApiResponse';
import { InvoicePriceResponse, ItemType, PriceBreakdown, subscriptionaddon, subscriptioncharge, subscriptioncoupon, SubscriptionInvoiceDetailsModel, SubscriptionInvoiceModel } from '../model/subscription';
import { CustomerService } from '../services/customer.service';
import { SubscriptionService } from '../services/subscription.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editsubscription',
  templateUrl: './editsubscription.component.html',
  styleUrls: ['./editsubscription.component.css']
})
export class EditsubscriptionComponent implements OnInit {
  subscription_unique_id: string = "";
  subscription_name: string = "";
  is_active: number = 1;
  customer_name: string = "";
  customer_email: string = "";
  plan_name: string = "";
  plan_quantity: any;
  plan_amount: any;
  plan_currency: any;
  IsTieredPlan: boolean = false;
  pricelist: any;
  pricing_structure_name: string = "";
  plan_unique_id: any;
  pricing_structure: number = 0;
  IsMeteredPlan: boolean = false;
  addonlist: any = [];
  chargelist: any = [];
  couponlist: any = [];
  TotalUnits: number = 0;
  TotalAmounts: number = 0;
  SubscriptionStartDate: any;
  company_unique_id: any;
  customer_unique_id: any;
  customer_Accounts: any = [];
  frequency_name: any;
  product_unique_id: any;
  subscriptionForm: FormGroup;
  subscription_plan_price: any;
  next_subscription_price: any;
  Subscription_billing_date: string;
  plan_subscription_frequency: any;
  exist_subscription_price: number = 0;
  exist_subscription_plan_price: any;
  exist_addon: any;
  exist_charge: any;
  exist_coupon: any;
  new_total_subscription_price: number;
  total_sub_total: any; plan_pricing_structure: number;
  plan_free_quantity: number = 0;
  WhenCharge: string;
  ExistSubObj: any;
  invoice_total: number;
  invoiceitems: any = [];
  total_discount_price: any;
  total_credit_charges: any;
  IsChargeImmediately: boolean;
  immediate_charges: any;
  toggleChangePlan: any;
  ChargebleItems: any = [];
  IsPlanQuantity: boolean = false;
  IsTiered: boolean = false;
  ShowItemDetail: boolean = true;
  planlist: any = [];
  selectplancurrency: boolean = false;
  BillingFrequency: any = [];
  BillingCurrency: any = [];
  scheduleruniqueId: any;
  is_trial_enable: boolean;
  IsPlanFreeQuantity: boolean;
  toggleChangeAddon: boolean = false;
  plan_price_breakdown: any = [];
  toggleChangeCharge: boolean = false;
  selectedaddon: any = [];
  selectedcharge: any = [];
  account_last_four: any;
  iscouponerror: string = '';
  toggleshowCoupon: boolean = false;
  toggleshowCouponLabel: boolean = true;
    customerportalsettings: any;
  allowchangeplan: boolean = true;
  allowchangequantity: boolean = true;
    allowaddchargeaddon: boolean = true;
    AllowAddDeletecoupons: boolean = true;
    ShowAddCoupon: boolean = true;
  addons(): FormArray {
    return this.subscriptionForm.get("addon_list") as FormArray
  }
  charges(): FormArray {
    return this.subscriptionForm.get("charge_list") as FormArray
  }
  coupons(): FormArray {
    return this.subscriptionForm.get("coupon_list") as FormArray
  }

  getControlLabel(type: string) {
    return this.subscriptionForm.controls[type].value;
  }

  Addaddon(addon: any): FormGroup {
    return this.fb.group({
      subscription_addon_unique_id: addon.subscription_addon_unique_id,
      addon_schedule_unique_id: addon.addon_schedule_unique_id,
      addon_name: addon.addon_name,
      addon_unique_id: addon.addon_unique_id,
      subscription_unique_id: addon.subscription_unique_id,
      addon_frequency: addon.addon_frequency,
      addon_price: addon.addon_price,
      addon_price_breakdown: addon.addon_price_breakdown,
      addon_price_structure: addon.addon_price_structure,
      addon_quantity: addon.addon_quantity,
      is_plan_attachment: addon.is_plan_attachment,
      is_active: false
    })
  }
  AddCharge(charge: any): FormGroup {
    return this.fb.group({
      subscription_charge_unique_id: charge.subscription_charge_unique_id,
      charge_schedule_unique_id: charge.charge_schedule_unique_id,
      charge_name: charge.charge_name,
      charge_unique_id: charge.charge_unique_id,
      subscription_unique_id: charge.subscription_unique_id,
      charge_price: charge.charge_price,
      charge_quantity: charge.charge_quantity,
      is_plan_attachment: charge.is_plan_attachment,
      charge_price_breakdown: charge.charge_price_breakdown,
      charge_price_structure: charge.charge_price_structure,
      is_active: false
    })
  }
  AddCoupon(coupon: any): FormGroup {
    return this.fb.group({
      subscription_coupon_unique_id: coupon.subscription_coupon_unique_id,
      coupon_name: coupon.coupon_name,
      discount_type: coupon.discount_type,
      discount_unit: coupon.discount_unit,
      coupon_unique_id: coupon.coupon_unique_id,
      subscription_unique_id: coupon.subscription_unique_id,
      coupon_discount: coupon.coupon_discount,
      is_active: coupon.is_active
    })
  }

  constructor(private _subscriptionservice: SubscriptionService, private fb: FormBuilder,
    private activatedroute: ActivatedRoute, private _customerService: CustomerService,
    private router: Router, private _location: Location, private spinner: NgxSpinnerService) {
    this.subscription_unique_id = this.activatedroute.snapshot.params["SubscriptionUniqueId"];
    this.company_unique_id = this.activatedroute.snapshot.params["EntityId"];
    this.customer_unique_id = this.activatedroute.snapshot.params["CustomerUniqueId"];
    this.subscriptionForm = this.fb.group({
      "company_unique_id": this.company_unique_id,
      "subscription_unique_id": [''],
      "customer_unique_id": [''],
      "product_unique_id": [''],
      "plan_unique_id": [''],
      "subscription_name": ['', Validators.required],
      "subscription_frequency": [1],
      "subscription_currency": [''],
      "subscription_startdate": [],
      "subscription_nextbilldate": [],
      "subscription_expirydate": [],
      "subscription_plan_price": [0],
      "subscription_plan_price_breakdown": [],
      "subscription_plan_price_structure": [1],
      "subscription_plan_quantity": [1],
      "plan_name": [''],
      "billing_cycle": [1],
      "billed_untill": [],
      "is_auto_collect": ['1'],
      "is_active": true,
      "is_trial_enabled": [false],
      "trial_frequency": [1],
      "trial_frequency_number": [0],
      "addon_list": this.fb.array([]),
      "charge_list": this.fb.array([]),
      "coupon_list": this.fb.array([])
    });
  }

  ngOnInit() {
    this.ApplyCustomerPortalSettings();
    this.GetSubscriptionDetails();
    this.GetCustomerAccountInfo();
  }

  async showChangePlan() {
    this.ShowItemDetail = false;
    this.toggleChangeCharge = false;
    this.toggleChangeAddon = false;
    await this.GetProductplans();
    this.toggleChangePlan = true;
   
  }

  cancelChangePlan() {
    this.toggleChangePlan = false;
    this.ShowItemDetail = true;
  }

  showCoupon() {
    this.toggleshowCoupon = true;
    this.toggleshowCouponLabel = false;
  }

  cancelcoupon() {
    this.toggleshowCouponLabel = true;
    this.toggleshowCoupon = false;

  }

  async GetSubscriptionDetails() {
    if (sessionStorage.getItem('Subscription_' + this.subscription_unique_id) != undefined) {
      var data: any = JSON.parse(sessionStorage.getItem('Subscription_' + this.subscription_unique_id));
      this.ExistSubObj = data;
      this.product_unique_id = data.product_unique_id;
      this.subscription_name = data.subscription_name;
      this.subscription_unique_id = data.subscription_unique_id;
      this.customer_name = data.customer_name;
      this.customer_email = data.customer_email;
      this.is_active = data.is_active;
      this.plan_name = data.plan_name;
      this.plan_amount = data.subscription_plan_price;
      this.plan_currency = data.subscription_currency;
      this.plan_unique_id = data.plan_unique_id;
      this.pricing_structure = data.subscription_plan_price_structure;
      this.IsMeteredPlan = data.is_metered_plan;
      this.couponlist = data.coupon_list;
      if (data.subscription_plan_price_breakdown_response != "") {
        this.subscriptionForm.get('subscription_plan_price_breakdown')?.setValue(JSON.parse(data.subscription_plan_price_breakdown_response));
        this.plan_price_breakdown = JSON.parse(data.subscription_plan_price_breakdown_response);
      }

      this.subscriptionForm.patchValue(data);
      this.frequency_name = data.subscription_frequency_name;
      this.SubscriptionStartDate = moment(data.subscription_startdate).format("MM/DD/YYYY hh:MM A");
      this.subscription_plan_price = data.subscription_plan_price;
      this.exist_subscription_plan_price = data.subscription_plan_price;
      this.exist_subscription_price = this.exist_subscription_price + this.subscription_plan_price;
      this.exist_addon = data.addon_list;
      this.exist_charge = data.charge_list;
      this.exist_coupon = data.coupon_list;
      if (data.subscription_plan_price_structure > 2) {
        this.IsPlanQuantity = true;
        this.IsTiered = true;
      }
      else if (data.subscription_plan_price_structure == 2) {
        this.IsPlanQuantity = true;
        this.IsTiered = false;
      }
      else {
        this.IsPlanQuantity = false;
        this.IsTiered = false;
      }
      if (data.addon_list.length > 0) {
        for (var i of data.addon_list) {
          this.addons().push(this.Addaddon(i));
          this.exist_subscription_price = this.exist_subscription_price + i.addon_price;
        }
      }
      if (data.charge_list.length > 0) {
        for (var j of data.charge_list) {
          this.charges().push(this.AddCharge(j));
          this.exist_subscription_price = this.exist_subscription_price + j.charge_price;
        }
      }
      if (data.coupon_list.length > 0) {
        for (var k of data.coupon_list) {
          this.coupons().push(this.AddCoupon(k));
        }
      }
      for (var l = 0; l < this.addons().length; l++) {
        let control = <FormArray>this.subscriptionForm.controls.addon_list;
        let fg: any = control.get([l]);
        if (this.exist_addon[l].addon_unique_id == fg.get('addon_unique_id').value) {
          if (this.exist_addon[l].addon_price_breakdown_response != "") {
            fg.get('addon_price_breakdown').setValue(JSON.parse(this.exist_addon[l].addon_price_breakdown_response));
          }
        }

      }
      for (var m = 0; m < this.charges().length; m++) {
        let control = <FormArray>this.subscriptionForm.controls.charge_list;
        let fg: any = control.get([m]);
        if (this.exist_charge[m].charge_unique_id == fg.get('charge_unique_id').value) {
          if (this.exist_charge[m].charge_price_breakdown_response != "") {
            fg.get('charge_price_breakdown').setValue(JSON.parse(this.exist_charge[m].charge_price_breakdown_response));
          }
        }

      }
      this.next_subscription_price = this.exist_subscription_price;
      this.next_subscription_price = this.next_subscription_price.toFixed(2);
      this.Subscription_billing_date = moment(this.subscriptionForm.get('subscription_nextbilldate')?.value).format('MM-DD-YYYY');
      this.plan_subscription_frequency = data.subscription_frequency;

      this.subscriptionForm.get('is_auto_collect')?.setValue(data.is_auto_collect.toString());
      await this.HideFlatFeeAddons();
      await this.HideFlatFeeCharges();
      await this.ShowBillingBreakdown();
      if (data.subscription_plan_price_structure != 1 && data.subscription_plan_price_structure != 2) {
        this.IsTieredPlan = true;
      }
      else {
        this.IsTieredPlan = false;
      }
      if (this.pricing_structure == 3) {
        this.pricing_structure_name = "Tiered";
      }
      if (this.pricing_structure == 5) {
        this.pricing_structure_name = "Volume";
      }
      if (this.pricing_structure == 4) {
        this.pricing_structure_name = "StairStep";
      }
    }

  }

  async ShowBillingBreakdown() {
    this.new_total_subscription_price = 0;
    this.total_sub_total = 0;
    this.total_discount_price = 0;
    this.total_credit_charges = 0;
    this.invoice_total = 0;
    var totalsubdays = Math.floor(moment.duration(moment(this.subscriptionForm.get('subscription_expirydate')?.value).diff(this.subscriptionForm.get('subscription_startdate')?.value)).asDays());
    var remainingdays = Math.floor(moment.duration(moment(this.subscriptionForm.get('subscription_expirydate')?.value).diff(moment(new Date()).format('MM-DD-YYYY'))).asDays());

    this.new_total_subscription_price = this.new_total_subscription_price + this.subscription_plan_price;
    if (this.addons().length > 0) {
      for (var i = 0; i < this.addons().length; i++) {
        this.new_total_subscription_price += this.addons().value[i].addon_price;
      }
    }
    if (this.charges().length > 0) {
      for (var i = 0; i < this.charges().length; i++) {
        this.new_total_subscription_price += this.charges().value[i].charge_price;
      }
    }
    for (var i = 0; i < this.coupons().length; i++) {
      var InvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
      if (this.coupons().value[i].discount_type == 1) {
        this.total_discount_price = this.total_discount_price + this.coupons().value[i].discount_unit;
        this.new_total_subscription_price = this.new_total_subscription_price - this.coupons().value[i].discount_unit;
      }
      else {
        var discountamount = (this.coupons().value[i].discount_unit / 100) * this.new_total_subscription_price;
        if (discountamount <= 0) {
          discountamount = Math.abs(discountamount);
        }
        this.total_discount_price = this.total_discount_price + discountamount;
        this.new_total_subscription_price = this.new_total_subscription_price - discountamount;
      }
    }
    if (this.exist_subscription_price < this.new_total_subscription_price) {
      this.next_subscription_price = this.exist_subscription_price;
      this.immediate_charges = 0;
      this.invoiceitems = [];
      if (!this.subscriptionForm.get('is_trial_enabled')?.value) {
        this.IsChargeImmediately = true;
        this.WhenCharge = "Immediately";
      }
      this.next_subscription_price = (this.next_subscription_price + (this.new_total_subscription_price - this.exist_subscription_price)).toFixed(2);

    }
    else if (this.exist_subscription_price == this.new_total_subscription_price) {
      this.IsChargeImmediately = false;
      this.invoiceitems = [];
      this.immediate_charges = 0;
      this.next_subscription_price = this.exist_subscription_price;
      this.next_subscription_price = this.next_subscription_price.toFixed(2);
    }
    else if (this.exist_subscription_price > this.new_total_subscription_price) {
      this.next_subscription_price = 0;
      this.immediate_charges = 0;
      this.IsChargeImmediately = false;
      this.next_subscription_price = this.new_total_subscription_price;
      this.next_subscription_price = this.next_subscription_price.toFixed(2);
    }
    if (this.IsChargeImmediately) {
      if (this.exist_subscription_plan_price < this.subscription_plan_price) {
        var planprice = 0;
        var ItemUnit = 0;
        this.invoiceitems = [];
        var newplanprice = 0;
        newplanprice = this.subscription_plan_price - this.exist_subscription_plan_price;
        var InvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
        var pricebreakdown = new Array<PriceBreakdown>();
        if (this.subscriptionForm.get('subscription_plan_price_structure')?.value > 2) {
          await this.AddSubscriptionPrice(this.subscriptionForm.get('subscription_plan_quantity')?.value, ItemType.Plan, 0);
          var pricerespo = await this.GetInvoicePriceBreakdown(this.subscriptionForm.get('subscription_plan_quantity')?.value, this.ExistSubObj.subscription_plan_quantity, this.subscriptionForm.get('subscription_plan_price_structure')?.value, this.pricelist, this.exist_subscription_plan_price);
          pricebreakdown = pricerespo.price_breakdown;
          newplanprice = pricerespo.price;
          this.total_credit_charges += pricerespo.credit;
        }
        planprice = (newplanprice / totalsubdays) * remainingdays;
        InvoiceDetailRequest.price_breakdown = pricebreakdown;
        InvoiceDetailRequest.item_unit = this.subscriptionForm.get('subscription_plan_quantity')?.value - this.ExistSubObj.subscription_plan_quantity;
        InvoiceDetailRequest.item_description = this.subscriptionForm.get('plan_name')?.value;
        InvoiceDetailRequest.total_amount = planprice;
        InvoiceDetailRequest.item_type = ItemType.Plan;
        this.invoiceitems.push(InvoiceDetailRequest);
      }
      if (this.addons().length > 0) {
        if (this.invoiceitems.length == 0) {
          this.invoiceitems = [];
        }
        else {
          this.invoiceitems = this.invoiceitems.filter((x: { item_type: number; }) => x.item_type == 1);
        }

        if (this.exist_addon.length == 0) {
          for (var i = 0; i < this.addons().length; i++) {
            var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
            var addonprice = 0;
            var newaddonprice = 0;
            newaddonprice = this.addons().value[i].addon_price;
            var pricebreakdown = new Array<PriceBreakdown>();
            if (this.addons().value[i].addon_price_structure > 2) {
              var result = this.ExistSubObj.sub_addon.filter(x => x.id == this.addons().value[i].addon_unique_id);
              this.pricelist = result[0].addon_schedular_list[0].tiers;
              await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
              var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, 0, this.addons().value[i].addon_price_structure, this.pricelist, 0);
              pricebreakdown = pricerespo.price_breakdown;
              newaddonprice = pricerespo.price;
              this.total_credit_charges += pricerespo.credit;
            }
            addonprice = (newaddonprice / totalsubdays) * remainingdays;

            AddonInvoiceDetailRequest.item_description = this.addons().value[i].addon_name;
            AddonInvoiceDetailRequest.item_unit = this.addons().value[i].addon_quantity;
            AddonInvoiceDetailRequest.total_amount = addonprice;
            AddonInvoiceDetailRequest.item_type = ItemType.Addon;
            this.invoiceitems.push(AddonInvoiceDetailRequest);
          }
        }
        else if (this.addons().length > this.exist_addon.length) {
          for (var i = 0; i < this.addons().length; i++) {
            if (this.exist_addon[i] != undefined) {
              if (this.addons().value[i].addon_price > this.exist_addon[i].addon_price) {
                var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
                var addonprice = 0;
                var ItemUnit = 0;
                var newaddonprice = 0;
                newaddonprice = this.addons().value[i].addon_price;
                ItemUnit = this.addons().value[i].addon_quantity - this.exist_addon[i].addon_quantity;
                var pricebreakdown = new Array<PriceBreakdown>();
                if (this.addons().value[i].addon_price_structure > 2) {

                  await this.GetAddonChargePricingModel(this.addons().value[i].addon_unique_id, 2);
                  await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
                  var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, this.exist_addon[i].addon_quantity, this.addons().value[i].addon_price_structure, this.pricelist, this.exist_addon[i].addon_price);
                  pricebreakdown = pricerespo.price_breakdown;
                  newaddonprice = pricerespo.price;
                  this.total_credit_charges += pricerespo.credit;
                }
                addonprice = (newaddonprice / totalsubdays) * remainingdays;
                AddonInvoiceDetailRequest.price_breakdown = pricebreakdown;
                AddonInvoiceDetailRequest.item_description = this.addons().value[i].addon_name;
                AddonInvoiceDetailRequest.item_unit = ItemUnit;
                AddonInvoiceDetailRequest.total_amount = addonprice;
                AddonInvoiceDetailRequest.item_type = ItemType.Addon;
                this.invoiceitems.push(AddonInvoiceDetailRequest);

              }
            }
            else {
              var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
              var addonprice = 0;
              var ItemUnit = 0;
              var newaddonprice = 0;
              newaddonprice = this.addons().value[i].addon_price;
              ItemUnit = this.addons().value[i].addon_quantity;
              var pricebreakdown = new Array<PriceBreakdown>();
              if (this.addons().value[i].addon_price_structure > 2) {
                var result = this.ExistSubObj.sub_addon.filter(x => x.id == this.addons().value[i].addon_unique_id);
                this.pricelist = result[0].addon_schedular_list[0].tiers;
                await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, 0, this.addons().value[i].addon_price_structure, this.pricelist, 0);
                pricebreakdown = pricerespo.price_breakdown;
                newaddonprice = pricerespo.price;
                this.total_credit_charges += pricerespo.credit;
              }
              addonprice = (newaddonprice / totalsubdays) * remainingdays;
              AddonInvoiceDetailRequest.price_breakdown = pricebreakdown;
              AddonInvoiceDetailRequest.item_description = this.addons().value[i].addon_name;
              AddonInvoiceDetailRequest.item_unit = this.addons().value[i].addon_quantity;
              AddonInvoiceDetailRequest.total_amount = addonprice;
              AddonInvoiceDetailRequest.item_type = ItemType.Addon;
              this.invoiceitems.push(AddonInvoiceDetailRequest);
            }
          }
        }
        else if (this.addons().length == this.exist_addon.length) {
          for (var i = 0; i < this.addons().length; i++) {
            if (this.addons().value[i].addon_price > this.exist_addon[i].addon_price) {
              var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
              var addonprice = 0;
              var ItemUnit = 0;
              var newaddonprice = 0;
              newaddonprice = this.addons().value[i].addon_price;
              ItemUnit = this.addons().value[i].addon_quantity - this.exist_addon[i].addon_quantity;
              var pricebreakdown = new Array<PriceBreakdown>();
              if (this.addons().value[i].addon_price_structure > 2) {
                await this.GetAddonChargePricingModel(this.addons().value[i].addon_unique_id, i);
                await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, 0);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, this.exist_addon[i].addon_quantity, this.addons().value[i].addon_price_structure, this.pricelist, this.exist_addon[i].addon_price);
                pricebreakdown = pricerespo.price_breakdown;
                newaddonprice = pricerespo.price;
                this.total_credit_charges += pricerespo.credit;
              }
              addonprice = (newaddonprice / totalsubdays) * remainingdays;
              AddonInvoiceDetailRequest.price_breakdown = pricebreakdown;
              AddonInvoiceDetailRequest.item_description = this.addons().value[i].addon_name;
              AddonInvoiceDetailRequest.item_unit = ItemUnit;
              AddonInvoiceDetailRequest.total_amount = addonprice;
              AddonInvoiceDetailRequest.item_type = ItemType.Addon;
              this.invoiceitems.push(AddonInvoiceDetailRequest);
            }
          }
        }
      }
      if (this.charges().length > 0) {
        if (this.invoiceitems.length == 0) {
          this.invoiceitems = [];
        }
        else {
          this.invoiceitems = this.invoiceitems.filter((x: { item_type: number; }) => (x.item_type == 1 || x.item_type == 2));
        }

        if (this.exist_charge.length == 0) {
          for (var i = 0; i < this.charges().length; i++) {
            var ChargeInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
            var chargeprice = 0;
            var ItemUnit = 0;
            var newchargeprice = 0;
            newchargeprice = this.charges().value[i].charge_price;
            ItemUnit = this.charges().value[i].charge_quantity;
            var pricebreakdown = new Array<PriceBreakdown>();
            if (this.charges().value[i].charge_price_structure > 2) {
              var result = this.ExistSubObj.sub_charge.filter(x => x.id == this.charges().value[i].charge_unique_id);
              this.pricelist = result[0].sub_charge_schedular.tiers;
              await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, 0);
              var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, 0, this.charges().value[i].charge_price_structure, this.pricelist, 0);
              pricebreakdown = pricerespo.price_breakdown;
              newchargeprice = pricerespo.price;
              this.total_credit_charges += pricerespo.credit;
            }
            chargeprice = (newchargeprice / totalsubdays) * remainingdays;
            ChargeInvoiceDetailRequest.price_breakdown = pricebreakdown;
            ChargeInvoiceDetailRequest.item_description = this.charges().value[i].charge_name;
            ChargeInvoiceDetailRequest.item_unit = ItemUnit;
            ChargeInvoiceDetailRequest.total_amount = chargeprice;
            ChargeInvoiceDetailRequest.item_type = ItemType.Charge;
            this.invoiceitems.push(ChargeInvoiceDetailRequest);
          }
        }
        else if (this.charges().length > this.exist_charge.length) {
          for (var i = 0; i < this.charges().length; i++) {
            if (this.exist_charge[i] != undefined) {
              if (this.charges().value[i].charge_price > this.exist_charge[i].charge_price) {
                var ChargeInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
                var chargeprice = 0;
                var ItemUnit = 0;
                var newchargeprice = 0;
                newchargeprice = this.charges().value[i].charge_price;
                ItemUnit = this.charges().value[i].charge_quantity - this.exist_charge[i].charge_quantity;
                var pricebreakdown = new Array<PriceBreakdown>();
                if (this.charges().value[i].charge_price_structure > 2) {
                  await this.GetAddonChargePricingModel(this.charges().value[i].charge_unique_id, 3);
                  await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, 0);
                  var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, this.exist_charge[i].charge_quantity, this.charges().value[i].charge_price_structure, this.pricelist, this.exist_charge[i].charge_price);
                  pricebreakdown = pricerespo.price_breakdown;
                  newchargeprice = pricerespo.price;
                  this.total_credit_charges += pricerespo.credit;
                }
                chargeprice = (newchargeprice / totalsubdays) * remainingdays;
                ChargeInvoiceDetailRequest.price_breakdown = pricebreakdown;
                ChargeInvoiceDetailRequest.item_description = this.charges().value[i].charge_name;
                ChargeInvoiceDetailRequest.item_unit = ItemUnit;
                ChargeInvoiceDetailRequest.total_amount = chargeprice;
                ChargeInvoiceDetailRequest.item_type = ItemType.Charge;
                this.invoiceitems.push(ChargeInvoiceDetailRequest);
              }
            }
            else {
              var ChargeInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
              var chargeprice = 0;
              var ItemUnit = 0;
              var newchargeprice = 0;
              newchargeprice = this.charges().value[i].charge_price;
              ItemUnit = this.charges().value[i].charge_quantity;
              var pricebreakdown = new Array<PriceBreakdown>();
              if (this.charges().value[i].charge_price_structure > 2) {
                var result = this.ExistSubObj.sub_charge.filter(x => x.id == this.charges().value[i].charge_unique_id);
                this.pricelist = result[0].sub_charge_schedular.tiers;
                await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, 0);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, 0, this.charges().value[i].charge_price_structure, this.pricelist, 0);
                pricebreakdown = pricerespo.price_breakdown;
                newchargeprice = pricerespo.price;
                this.total_credit_charges += pricerespo.credit;
              }
              chargeprice = (newchargeprice / totalsubdays) * remainingdays;
              ChargeInvoiceDetailRequest.price_breakdown = pricebreakdown;
              ChargeInvoiceDetailRequest.item_description = this.charges().value[i].charge_name;
              ChargeInvoiceDetailRequest.item_unit = ItemUnit;
              ChargeInvoiceDetailRequest.total_amount = chargeprice;
              ChargeInvoiceDetailRequest.item_type = ItemType.Charge;
              this.invoiceitems.push(ChargeInvoiceDetailRequest);
            }
          }
        }
        else if (this.charges().length == this.exist_charge.length) {
          for (var i = 0; i < this.charges().length; i++) {
            if (this.charges().value[i].charge_price > this.exist_charge[i].charge_price) {
              var ChargeInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
              var chargeprice = 0;
              var ItemUnit = 0;
              var newchargeprice = 0;
              newchargeprice = this.charges().value[i].charge_price;
              ItemUnit = this.charges().value[i].charge_quantity - this.exist_charge[i].charge_quantity;
              var pricebreakdown = new Array<PriceBreakdown>();
              if (this.charges().value[i].charge_price_structure > 2) {
                await this.GetAddonChargePricingModel(this.charges().value[i].charge_unique_id, 3);
                await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, 0);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, this.exist_charge[i].charge_quantity, this.charges().value[i].charge_price_structure, this.pricelist, this.exist_charge[i].charge_price);
                pricebreakdown = pricerespo.price_breakdown;
                newchargeprice = pricerespo.price;
                this.total_credit_charges += pricerespo.credit;
              }
              chargeprice = (newchargeprice / totalsubdays) * remainingdays;
              ChargeInvoiceDetailRequest.price_breakdown = pricebreakdown;
              ChargeInvoiceDetailRequest.item_description = this.charges().value[i].charge_name;
              ChargeInvoiceDetailRequest.item_unit = ItemUnit;
              ChargeInvoiceDetailRequest.total_amount = chargeprice;
              ChargeInvoiceDetailRequest.item_type = ItemType.Charge;
              this.invoiceitems.push(ChargeInvoiceDetailRequest);
            }
          }
        }
      }
      for (var k of this.invoiceitems) {
        this.total_sub_total += k.total_amount;
      }
      if (this.coupons().length > 0) {
        if (this.coupons().length == 2) {
          var newcouponlist = [];
          var fixamountcoupon = this.coupons().value.filter((x: { discount_type: any; }) => x.discount_type == 1);
          if (fixamountcoupon != null) {
            newcouponlist.push(fixamountcoupon);
          }
          var percentagecoupon = this.coupons().value.filter((x: { discount_type: any; }) => x.discount_type == 2);
          if (percentagecoupon != null) {
            newcouponlist.push(percentagecoupon);
          }
          this.coupons().clear();
          this.coupons().push(this.AddCoupon(fixamountcoupon[0]));
          this.coupons().push(this.AddCoupon(percentagecoupon[0]));
        }
        for (var i = 0; i < this.coupons().length; i++) {
          if (this.exist_coupon.filter(x => x.coupon_unique_id == this.coupons().value[i].coupon_unique_id).length == 0) {
            var InvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
            if (this.coupons().value[i].discount_type == 1) {
              this.total_discount_price = this.total_discount_price + this.coupons().value[i].discount_unit;
              InvoiceDetailRequest.total_amount = this.coupons().value[i].discount_unit;
              InvoiceDetailRequest.item_description = this.coupons().value[i].coupon_name;
              InvoiceDetailRequest.item_type = ItemType.Coupon;
              this.invoiceitems.push(InvoiceDetailRequest);
              this.total_sub_total = this.total_sub_total - this.coupons().value[i].discount_unit;
              let control = <FormArray>this.subscriptionForm.controls.coupon_list;
              let fg: any = control.get([i]);
              fg.get('coupon_discount').setValue(this.coupons().value[i].discount_unit);

            }
            else {

              var discountamount = (this.coupons().value[i].discount_unit / 100) * this.new_total_subscription_price;
              if (discountamount <= 0) {
                discountamount = Math.abs(discountamount);
              }
              this.total_discount_price = this.total_discount_price + discountamount;
              InvoiceDetailRequest.item_description = this.coupons().value[i].coupon_name;
              InvoiceDetailRequest.item_unit = this.coupons().value[i].discount_unit;
              InvoiceDetailRequest.total_amount = this.total_sub_total - discountamount;
              InvoiceDetailRequest.item_type = ItemType.Coupon;
              this.invoiceitems.push(InvoiceDetailRequest);
              this.total_sub_total = this.total_sub_total - discountamount;
              let control = <FormArray>this.subscriptionForm.controls.coupon_list;
              let fg: any = control.get([i]);
              fg.get('coupon_discount').setValue(discountamount);

            }

          }
        }
      }
      this.immediate_charges = this.total_sub_total - (this.total_credit_charges + this.total_discount_price);
      this.total_sub_total = this.total_sub_total.toFixed(2);



      if (this.immediate_charges < 0) {
        this.immediate_charges = (0).toFixed(2);
      } else {
        this.immediate_charges = this.immediate_charges.toFixed(2);
      }
      if (this.total_credit_charges > 0 || this.total_discount_price > 0) {
        this.total_credit_charges = this.total_credit_charges.toFixed(2);
        this.total_discount_price = this.total_discount_price.toFixed(2);
      }
      else {
        this.total_credit_charges = (0).toFixed(2);
        this.total_discount_price = (0).toFixed(2);
      }
      this.invoice_total = this.immediate_charges;
    }
    for (var k of this.invoiceitems) {
      if (k.item_unit == 0) {
        k.item_unit = "-";
      }
      if (k.total_amount < 0) {
        k.total_amount = (0).toFixed(2);
      }
      else {
        k.total_amount = k.total_amount.toFixed(2);
      }


    }
  }

  async viewplanbreakdown() {
    await this.GetPlanPricingModel();
    var pricebreakdown = new Array<PriceBreakdown>();
    await this.AddSubscriptionPrice(this.subscriptionForm.get('subscription_plan_quantity')?.value, 1, 0);
    for (var m of this.pricelist) {
      var pricebdown = new PriceBreakdown();
      if (m.unit > 0) {
        pricebdown.price = m.price;
        pricebdown.unit = m.unit;
        pricebreakdown.push(pricebdown);
      }
    }
    this.plan_price_breakdown = pricebreakdown;
    this.subscriptionForm.get('subscription_plan_price_breakdown')?.setValue(pricebreakdown);
    await this.ShowSubscriptionPriceChange();
  }

  async viewaddonbreakdown(i: number) {

    await this.GetAddonChargePricingModel(this.addons().value[i].addon_unique_id, 2);
    await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, 2, i);
    var pricebreakdown = new Array<PriceBreakdown>();
    for (var m of this.pricelist) {
      var pricebdown = new PriceBreakdown();
      if (m.unit > 0) {
        pricebdown.price = m.price;
        pricebdown.unit = m.unit;
        pricebreakdown.push(pricebdown);
      }
    }
    let control = <FormArray>this.subscriptionForm.controls.addon_list;
    let fg: any = control.get([i]);
    fg.get('addon_price_breakdown').setValue(pricebreakdown);
    await this.ShowSubscriptionPriceChange();
  }
  async viewchargebreakdown(i: number) {

    await this.GetAddonChargePricingModel(this.charges().value[i].charge_unique_id, 3);
    await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, 3, i);
    var pricebreakdown = new Array<PriceBreakdown>();
    for (var m of this.pricelist) {
      var pricebdown = new PriceBreakdown();
      if (m.unit > 0) {
        pricebdown.price = m.price;
        pricebdown.unit = m.unit;
        pricebreakdown.push(pricebdown);
      }
    }
    let control = <FormArray>this.subscriptionForm.controls.charge_list;
    let fg: any = control.get([i]);
    fg.get('charge_price_breakdown').setValue(pricebreakdown);
    await this.ShowSubscriptionPriceChange();
  }

  async GetProductplans() {
    var rawvalue = this.subscriptionForm.getRawValue();
    var data: any = this.ExistSubObj.sub_plan;
    this.planlist = data;
    this.planlist = this.planlist.filter((x: { id: any; plan_schedular_list: any }) => x.id != rawvalue.plan_unique_id && x.plan_schedular_list.length > 0);


  }

  async onplanselect(plan_unique_id: any) {
    var selectedplan = this.planlist.filter(x => x.id == plan_unique_id);
    this.plan_unique_id = selectedplan[0].id;
    this.plan_name = selectedplan[0].plan_name;
    this.IsMeteredPlan = selectedplan[0].is_metered_plan;
    await this.GetCurrencyFrequency(this.plan_unique_id);
    this.selectplancurrency = true;
  }
  async onselectfreuency(e: any) {
    var split = JSON.parse(e.target.value);
    this.scheduleruniqueId = split.plan_schedule_unique_id
    this.plan_subscription_frequency = split.billing_frequency;
    this.frequency_name = split.frequency_name;
    this.plan_free_quantity = split.free_quantity;
    this.subscriptionForm.get('is_trial_enabled')?.setValue(split.is_trial_enabled);
    this.subscriptionForm.get('trial_frequency')?.setValue(split.trial_frequency);
    this.subscriptionForm.get('trial_frequency_number')?.setValue(split.trial_frequency_number);
    await this.GetPlanpricingbyschedule();
    if (this.subscriptionForm.get('is_trial_enabled')?.value) {
      this.is_trial_enable = true;
      this.subscriptionForm.get('subscription_nextbilldate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(this.subscriptionForm.get('trial_frequency')?.value * this.subscriptionForm.get('trial_frequency_number')?.value, 'days'));
      this.subscriptionForm.get('subscription_expirydate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(parseInt(this.subscriptionForm.get('trial_frequency')?.value) * parseInt(this.subscriptionForm.get('trial_frequency_number')?.value), 'days'));
    }
    else {
      this.is_trial_enable = false;
      this.subscriptionForm.get('subscription_nextbilldate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(this.plan_subscription_frequency, 'days'));
      this.subscriptionForm.get('subscription_expirydate')?.setValue(moment(this.subscriptionForm.get('subscription_startdate')?.value).add(this.plan_subscription_frequency, 'days'));
    }
    if (!this.subscriptionForm.get('is_trial_enabled')?.value && moment(new Date()).format("MM/DD/YYYY") == moment(this.subscriptionForm.get('subscription_startdate')?.value).format("MM/DD/YYYY")) {
      this.WhenCharge = "Immediately";
      this.IsChargeImmediately = true;
    }
    else if (this.subscriptionForm.get('is_trial_enabled')?.value) {
      this.WhenCharge = "on " + moment(this.subscriptionForm.get('subscription_nextbilldate')?.value).format("MM/DD/YYYY hh:MM A");
      this.IsChargeImmediately = false;
    }
    else {
      this.WhenCharge = "on " + moment(this.subscriptionForm.get('subscription_startdate')?.value).format("MM/DD/YYYY hh:MM A");
    }
  }

  async AssignPlan() {
    this.subscriptionForm.get('plan_unique_id')?.setValue(this.plan_unique_id);
    this.subscriptionForm.get('plan_name')?.setValue(this.plan_name);
    this.ShowItemDetail = true;
    this.toggleChangePlan = false;
    await this.GetPlanAttachment();
    await this.ShowBillingBreakdown();
    await this.viewplanbreakdown();
    await this.HideFlatFeeAddons();
    await this.HideFlatFeeCharges();
  }

  async GetPlanAttachment() {
    this.spinner.show();
    var request = { "plan_unique_id": this.subscriptionForm.get('plan_unique_id')?.value, "company_unique_id": this.company_unique_id };
    const response: WebApiResponse = await this._subscriptionservice.GetPlanAttachment(request);
    if (response.code == 1) {
      var data: any = response.data;
      var attachaddonlist = data.filter((x: { item_type: number; }) => x.item_type == 2);
      if (attachaddonlist.length > 0) {
        await this.GetAddon();
        var result = this.addonlist.filter(function (o1: any) {
          return attachaddonlist.some(function (o2: any) {
            return o1.addon_unique_id === o2.item_id;
          });
        })
        if (result.length > 0) {
          for (var i of result) {
            i.is_plan_attachment = true;
            await this.onaddonselect(i);
          }
        }
      }
      var attachchargelist = data.filter((x: { item_type: number; }) => x.item_type == 3);
      if (attachchargelist.length > 0) {
        await this.GetCharges();
        var resultcharge = this.chargelist.filter(function (o1: any) {
          return attachchargelist.some(function (o2: any) {
            return o1.charge_unique_id === o2.item_id;
          });
        })
        if (resultcharge.length > 0) {
          for (var j of resultcharge) {
            j.is_plan_attachment = true;
            await this.onchargeselect(j);
          }
        }
      }
    }
    this.spinner.hide();
  }

  async GetCharges() {
    this.toggleChangeAddon = false;
    this.ShowItemDetail = false;
    this.toggleChangeCharge = true;
    var data: any = this.ExistSubObj.sub_charge;
    this.chargelist = data;
    this.chargelist = this.chargelist.filter(x => x.sub_charge_schedular.tiers.length > 0);
    this.chargelist = this.chargelist.filter((objFromA: { id: any; }) => {
      return !this.charges().value.find(function (objFromB: { charge_unique_id: any; }) {
        return objFromA.id === objFromB.charge_unique_id
      })
    })
  }
  onaddonchoose(item, ischecked) {
    if (ischecked) {
      this.selectedaddon.push(item);
    }
    else {
      var index = this.selectedaddon.findIndex(i => i.id === item.id);
      if (index != -1) {
        this.selectedaddon.splice(index);
      }
    }
  }
  onchargechoose(item, ischecked) {
    if (ischecked) {
      this.selectedcharge.push(item);
    }
    else {
      var index = this.selectedcharge.findIndex(i => i.id === item.id);
      if (index != -1) {
        this.selectedcharge.splice(index);
      }
    }
  }


  async GetAddonPricingBySchedule(addon_schedule_unique_id: any) {
    var requestschedule = { "addon_schedule_unique_id": addon_schedule_unique_id }
    const responseAddonprice: WebApiResponse = await this._subscriptionservice.GetAddonPricingBySchedule(requestschedule);
    if (responseAddonprice.code == 1) {
      var data: any = responseAddonprice.data;
      this.pricelist = data;
      for (var i of this.pricelist) {
        if (i.unit_to == 0) {
          i.unit_to = "& above";
        }
      }
      if (this.pricelist[0].pricing_structure == 3) {
        this.pricing_structure_name = "Tiered";
      }
      if (this.pricelist[0].pricing_structure == 5) {
        this.pricing_structure_name = "Volume";
      }
      if (this.pricelist[0].pricing_structure == 4) {
        this.pricing_structure_name = "StairStep";
      }
      if (this.pricelist[0].pricing_structure != 1) {
        for (var j of this.pricelist) {
          if (j == 0) {
            j.unit = 1;
            j.amount = j.price * 1;
          }
          else {
            j.unit = '-';
            j.amount = 0;
          }
        }
      }

      return data;
    }
  }

  async save() {
    this.spinner.show();
    this.subscriptionForm.get('subscription_frequency')?.setValue(this.plan_subscription_frequency);
    this.subscriptionForm.get('subscription_plan_price')?.setValue(this.subscription_plan_price);
    if (this.IsChargeImmediately && this.invoiceitems.length == 0) {
      await this.ShowBillingBreakdown();
    }
    if (this.subscriptionForm.get('billing_cycle')?.value == 1) {
      this.subscriptionForm.get('billed_untill')?.setValue(null);
    }

    const response: WebApiResponse = await this._subscriptionservice.SaveSubscription(this.subscriptionForm.value);
    if (response.code == 1) {
      if (response.data != null && this.IsChargeImmediately) {
        await this.ShowBillingBreakdown();
        await this.GenerateSubscriptionInvoice(response.data);
      }
      this.Redirectback();
    }
    this.spinner.hide();
  }
  Redirectback() {
    this._location.back();
  }

  async GenerateSubscriptionInvoice(subscription_unique_id: any) {
    var request = new SubscriptionInvoiceModel();
    request.company_unique_id = this.company_unique_id;
    request.subscription_unique_id = subscription_unique_id;
    request.customer_unique_id = this.subscriptionForm.get('customer_unique_id')?.value;
    request.invoice_from = this.subscriptionForm.get('subscription_startdate')?.value;
    request.invoice_to = this.subscriptionForm.get('subscription_expirydate')?.value;
    request.billing_cycle = this.subscriptionForm.get('billing_cycle')?.value;
    request.invoice_amount = this.invoice_total;
    request.discount_amount = this.total_discount_price;
    request.credit_amount = this.total_credit_charges;
    for (var i of this.invoiceitems) {
      if (i.item_unit == '-') {
        i.item_unit = 0;
      }
    }
    request.invoice_details = this.invoiceitems;
    await this._subscriptionservice.GenerateSubscriptionInvoice(request);
  }

  async GetPlanpricingbyschedule() {
    var request = { "plan_schedule_unique_id": this.scheduleruniqueId, "company_unique_id": this.company_unique_id }
    const response: WebApiResponse = await this._subscriptionservice.GetPlanPricingBySchedule(request);
    if (response.code == 1) {
      var data: any = response.data;
      if (this.addons().length > 0) {
        var rawvalue = this.subscriptionForm.getRawValue();
        var addonlist = rawvalue.addon_list;
        let control = <FormArray>this.subscriptionForm.controls.addon_list;
        for (let i = 0; i < addonlist.length; i++) {
          let fg: any = control.get([i]);
          if (fg.get('addon_frequency')?.value != this.plan_subscription_frequency) {
            this.addons().removeAt(i);
          }
        }
      }
      this.pricelist = [];
      this.pricelist = data;
      this.subscriptionForm.get('subscription_plan_price_structure')?.setValue(this.pricelist[0].pricing_structure);
      for (var i of this.pricelist) {
        if (i.unit_to == 0) {
          i.unit_to = "& above";
        }
      }
      if (this.pricelist[0].pricing_structure == 3) {
        this.pricing_structure_name = "Tiered";
      }
      if (this.pricelist[0].pricing_structure == 5) {
        this.pricing_structure_name = "Volume";
      }
      if (this.pricelist[0].pricing_structure == 4) {
        this.pricing_structure_name = "StairStep";
      }
      if (this.pricelist[0].pricing_structure > 2) {
        this.IsPlanQuantity = true;
        this.IsTiered = true;
        this.subscription_plan_price = data.price;
        for (var j of this.pricelist) {
          if (j == 0) {
            j.unit = 1;
            j.amount = j.price * 1;
          }
          else {
            j.unit = '-';
            j.amount = 0;
          }
        }
      }
      else {
        if (this.pricelist[0].pricing_structure == 1) {
          this.IsPlanQuantity = false;
        }
        else {
          this.IsPlanFreeQuantity = true;
          this.IsPlanQuantity = true;
        }
        if (this.plan_free_quantity > 1) {
          this.subscription_plan_price = 0;
        }
        else {
          this.subscription_plan_price = data.price;
        }

      }
    }
  }

  async GetCurrencyFrequency(plan_unique_id: any) {
    this.BillingFrequency = [];
    this.BillingCurrency = [];
    var planschedular = this.planlist.filter(x => x.id == plan_unique_id);
    var data: any = planschedular[0].plan_schedular_list;
    for (var i of data) {
      if (this.BillingCurrency.length == 0) {
        this.BillingCurrency.push(i.billing_currency);
      }
      else {
        for (var j of this.BillingCurrency) {
          if (j != i.billing_currency) {
            this.BillingCurrency.push(i);
          }
        }
      }
    }
    for (var k of data) {
      var found = false;
      if (this.BillingFrequency.length == 0) {
        found = false;
      }
      else {
        for (var l of this.BillingFrequency) {
          if (l.frequency_name == k.frequency_name) {
            found = true;
          }
        }
      }
      if (!found) {
        this.BillingFrequency.push(k);
      }
    }

  }
  async ShowSubscriptionPriceChange() {
    this.new_total_subscription_price = 0;
    this.total_sub_total = 0;
    this.total_discount_price = 0;
    this.invoice_total = 0;
    var totalsubdays = Math.floor(moment.duration(moment(this.subscriptionForm.get('subscription_expirydate')?.value).diff(this.subscriptionForm.get('subscription_startdate')?.value)).asDays());
    var remainingdays = Math.floor(moment.duration(moment(this.subscriptionForm.get('subscription_expirydate')?.value).diff(moment(new Date()).format('MM-DD-YYYY'))).asDays());
    if (remainingdays == 0 || remainingdays == -1) {
      remainingdays = 1;
    }
    this.new_total_subscription_price = this.new_total_subscription_price + this.subscription_plan_price;
    if (this.addons().length > 0) {
      for (var i = 0; i < this.addons().length; i++) {
        this.new_total_subscription_price += this.addons().value[i].addon_price;
      }
    }
    if (this.charges().length > 0) {
      for (var i = 0; i < this.charges().length; i++) {
        this.new_total_subscription_price += this.charges().value[i].charge_price;
      }
    }
    if (this.exist_subscription_price < this.new_total_subscription_price) {
      this.next_subscription_price = this.exist_subscription_price;
      this.immediate_charges = 0;
      this.ChargebleItems = [];
      if (!this.subscriptionForm.get('is_trial_enabled')?.value) {
        this.IsChargeImmediately = true;
        this.WhenCharge = "Immediately";
      }
      this.next_subscription_price = (this.next_subscription_price + (this.new_total_subscription_price - this.exist_subscription_price)).toFixed(2);

    }
    else if (this.exist_subscription_price == this.new_total_subscription_price) {
      this.IsChargeImmediately = false;
      this.ChargebleItems = [];
      this.immediate_charges = 0;
      this.next_subscription_price = this.exist_subscription_price;
    }
    else if (this.exist_subscription_price > this.new_total_subscription_price) {
      this.next_subscription_price = 0;
      this.immediate_charges = 0;
      this.IsChargeImmediately = false;
      this.next_subscription_price = this.new_total_subscription_price;
    }
    if (this.IsChargeImmediately) {
      if (this.exist_subscription_plan_price < this.subscription_plan_price) {
        var planprice = 0;
        planprice = ((this.subscription_plan_price - this.exist_subscription_plan_price) / totalsubdays) * remainingdays;
        this.ChargebleItems = [];
        var InvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
        InvoiceDetailRequest.total_amount = planprice;
        InvoiceDetailRequest.item_type = ItemType.Plan;
        this.ChargebleItems.push(InvoiceDetailRequest);
      }
      if (this.addons().length > 0) {
        if (this.ChargebleItems.length == 0) {
          this.ChargebleItems = [];
        }
        else {
          this.ChargebleItems = this.ChargebleItems.filter((x: { item_type: number; }) => x.item_type == 1);
        }

        if (this.exist_addon.length == 0) {
          for (var i = 0; i < this.addons().length; i++) {
            var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
            var addonprice = 0;
            var newaddonprice = 0;
            newaddonprice = this.addons().value[i].addon_price;
            if (this.addons().value[i].addon_price_structure > 2) {
              var result = this.ExistSubObj.sub_addon.filter(x => x.id == this.addons().value[i].addon_unique_id);
              this.pricelist = result[0].addon_schedular_list[0].tiers;
              await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
              var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, 0, this.addons().value[i].addon_price_structure, this.pricelist, 0);
              newaddonprice = pricerespo.price;
            }
            addonprice = (newaddonprice / totalsubdays) * remainingdays;
            AddonInvoiceDetailRequest.total_amount = addonprice;
            AddonInvoiceDetailRequest.item_type = ItemType.Addon;
            this.ChargebleItems.push(AddonInvoiceDetailRequest);
          }
        }
        else if (this.addons().length > this.exist_addon.length) {
          for (var i = 0; i < this.addons().length; i++) {
            if (this.exist_addon[i] != undefined) {
              if (this.addons().value[i].addon_price > this.exist_addon[i].addon_price) {
                var addonprice = 0;
                var newaddonprice = 0;
                newaddonprice = this.addons().value[i].addon_price;
                if (this.addons().value[i].addon_price_structure > 2) {

                  await this.GetAddonChargePricingModel(this.addons().value[i].addon_unique_id, 2);
                  await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
                  var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, this.exist_addon[i].addon_quantity, this.addons().value[i].addon_price_structure, this.pricelist, this.exist_addon[i].addon_price);
                  newaddonprice = pricerespo.price;
                }
                addonprice = (newaddonprice / totalsubdays) * remainingdays;
                var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
                AddonInvoiceDetailRequest.total_amount = addonprice;
                AddonInvoiceDetailRequest.item_type = ItemType.Addon;
                this.ChargebleItems.push(AddonInvoiceDetailRequest);

              }
            }
            else {
              var addonprice = 0;
              var newaddonprice = 0;
              newaddonprice = this.addons().value[i].addon_price;
              if (this.addons().value[i].addon_price_structure > 2) {
                var result = this.ExistSubObj.sub_addon.filter(x => x.id == this.addons().value[i].addon_unique_id);
                this.pricelist = result[0].addon_schedular_list[0].tiers;
                await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, 0, this.addons().value[i].addon_price_structure, this.pricelist, 0);
                newaddonprice = pricerespo.price;
              }
              addonprice = (newaddonprice / totalsubdays) * remainingdays;
              var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
              AddonInvoiceDetailRequest.total_amount = addonprice;
              AddonInvoiceDetailRequest.item_type = ItemType.Addon;
              this.ChargebleItems.push(AddonInvoiceDetailRequest);
            }
          }
        }
        else if (this.addons().length == this.exist_addon.length) {
          for (var i = 0; i < this.addons().length; i++) {
            if (this.addons().value[i].addon_price > this.exist_addon[i].addon_price) {
              var addonprice = 0;
              var newaddonprice = 0;
              newaddonprice = this.addons().value[i].addon_price;
              if (this.addons().value[i].addon_price_structure > 2) {
                await this.GetAddonChargePricingModel(this.addons().value[i].addon_unique_id, 2);
                await this.AddSubscriptionPrice(this.addons().value[i].addon_quantity, ItemType.Addon, i);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.addons().value[i].addon_quantity, this.exist_addon[i].addon_quantity, this.addons().value[i].addon_price_structure, this.pricelist, this.exist_addon[i].addon_price);
                newaddonprice = pricerespo.price;
              }
              addonprice = (newaddonprice / totalsubdays) * remainingdays;
              var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
              AddonInvoiceDetailRequest.total_amount = addonprice;
              AddonInvoiceDetailRequest.item_type = ItemType.Addon;
              this.ChargebleItems.push(AddonInvoiceDetailRequest);
            }
          }
        }
      }
      if (this.charges().length > 0) {
        if (this.ChargebleItems.length == 0) {
          this.ChargebleItems = [];
        }
        else {
          this.ChargebleItems = this.ChargebleItems.filter((x: { item_type: number; }) => (x.item_type == 1 || x.item_type == 2));
        }

        if (this.exist_charge.length == 0) {
          for (var i = 0; i < this.charges().length; i++) {
            var ChargeRequest = new SubscriptionInvoiceDetailsModel();
            var chargeprice = 0;
            var newchargeprice = 0;
            newchargeprice = this.charges().value[i].charge_price;
            if (this.charges().value[i].charge_price_structure > 2) {
              var result = this.ExistSubObj.sub_charge.filter(x => x.id == this.charges().value[i].charge_unique_id);
              this.pricelist = result[0].sub_charge_schedular.tiers;
              await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, i);
              var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, 0, this.charges().value[i].charge_price_structure, this.pricelist, 0);
              newchargeprice = pricerespo.price;
            }
            chargeprice = (newchargeprice / totalsubdays) * remainingdays;
            ChargeRequest.total_amount = chargeprice;
            ChargeRequest.item_type = ItemType.Charge;
            this.ChargebleItems.push(ChargeRequest);
          }
        }
        else if (this.charges().length > this.exist_charge.length) {
          for (var i = 0; i < this.charges().length; i++) {
            if (this.exist_charge[i] != undefined) {
              if (this.charges().value[i].charge_price > this.exist_charge[i].charge_price) {
                var chargeprice = 0;
                var newchargeprice = 0;
                newchargeprice = this.charges().value[i].charge_price;
                if (this.charges().value[i].charge_price_structure > 2) {
                  await this.GetAddonChargePricingModel(this.charges().value[i].charge_unique_id, 3);
                  await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, i);
                  var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, this.exist_charge[i].charge_quantity, this.charges().value[i].charge_price_structure, this.pricelist, this.exist_charge[i].charge_price);
                  newchargeprice = pricerespo.price;
                }
                chargeprice = (newchargeprice / totalsubdays) * remainingdays;
                var ChargeRequest = new SubscriptionInvoiceDetailsModel();
                ChargeRequest.total_amount = chargeprice;
                ChargeRequest.item_type = ItemType.Charge;
                this.ChargebleItems.push(ChargeRequest);

              }
            }
            else {
              var chargeprice = 0;
              var newchargeprice = 0;
              newchargeprice = this.charges().value[i].charge_price;
              if (this.charges().value[i].charge_price_structure > 2) {
                var result = this.ExistSubObj.sub_charge.filter(x => x.id == this.charges().value[i].charge_unique_id);
                this.pricelist = result[0].sub_charge_schedular.tiers;
                await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, i);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, 0, this.charges().value[i].charge_price_structure, this.pricelist, 0);
                newchargeprice = pricerespo.price;
              }
              chargeprice = (newchargeprice / totalsubdays) * remainingdays;
              var AddonInvoiceDetailRequest = new SubscriptionInvoiceDetailsModel();
              var ChargeRequest = new SubscriptionInvoiceDetailsModel();
              ChargeRequest.total_amount = chargeprice;
              ChargeRequest.item_type = ItemType.Charge;
              this.ChargebleItems.push(ChargeRequest);
            }
          }
        }
        else if (this.charges().length == this.exist_charge.length) {
          for (var i = 0; i < this.charges().length; i++) {
            if (this.charges().value[i].charge_price > this.exist_charge[i].charge_price) {
              var chargeprice = 0;
              var newchargeprice = 0;
              newchargeprice = this.charges().value[i].charge_price;
              if (this.charges().value[i].charge_price_structure > 2) {
                await this.GetAddonChargePricingModel(this.charges().value[i].charge_unique_id, 3);
                await this.AddSubscriptionPrice(this.charges().value[i].charge_quantity, ItemType.Charge, i);
                var pricerespo = await this.GetInvoicePriceBreakdown(this.charges().value[i].charge_quantity, this.exist_charge[i].charge_quantity, this.charges().value[i].charge_price_structure, this.pricelist, this.exist_charge[i].charge_price);
                newchargeprice = pricerespo.price;
              }
              chargeprice = (newchargeprice / totalsubdays) * remainingdays;
              var ChargeRequest = new SubscriptionInvoiceDetailsModel();
              ChargeRequest.total_amount = chargeprice;
              ChargeRequest.item_type = ItemType.Charge;
              this.ChargebleItems.push(ChargeRequest);
            }
          }
        }
      }
      for (var k of this.ChargebleItems) {
        this.immediate_charges += k.total_amount;
      }
      if (this.immediate_charges < 0) {
        this.immediate_charges = (0).toFixed(2);
      } else {
        this.immediate_charges = this.immediate_charges.toFixed(2);
      }
    }

  }

  cancelchangeaddon() {
    this.toggleChangeAddon = false;
    this.ShowItemDetail = true;
  }
  cancelchangecharge() {
    this.toggleChangeCharge = false;
    this.ShowItemDetail = true;
  }

  async Assigneaddon() {
    for (var i of this.selectedaddon) {
      i.is_plan_attachment = false;
      this.onaddonselect(i);
    }
    this.toggleChangeAddon = false;
    this.ShowItemDetail = true;
  }
  async Assignecharge() {
    for (var i of this.selectedcharge) {
      i.is_plan_attachment = false;
      this.onchargeselect(i);
    }
    this.toggleChangeAddon = false;
    this.toggleChangeCharge = false;
    this.ShowItemDetail = true;
  }

  async onaddonselect(event: any) {
    var obj = new subscriptionaddon();
    var addon: any = this.ExistSubObj.sub_addon.filter(x => x.id == event.id);
    if (addon.length > 0) {
      var presponse = addon[0].addon_schedular_list.filter(x => x.billing_frequency == this.plan_subscription_frequency);
      if (presponse[0].tiers[0].pricing_structure != 1) {
        var pricebreakdown = Array<PriceBreakdown>();
        obj.addon_price = presponse[0].tiers[0].price;
        obj.addon_quantity = 1;
        var pricebdown = new PriceBreakdown();
        pricebdown.unit = 1;
        pricebdown.price = presponse[0].tiers[0].price;
        pricebreakdown.push(pricebdown);
        obj.addon_price_breakdown = new Array<PriceBreakdown>();
        obj.addon_price_breakdown = pricebreakdown;
      }
      else {
        obj.addon_quantity = 0;
        obj.addon_price = presponse[0].tiers[0].price;
      }
      obj.addon_price_structure = presponse[0].tiers[0].pricing_structure;
      obj.addon_schedule_unique_id = presponse[0].addon_schedule_unique_id;
      obj.addon_frequency = presponse[0].billing_frequency;
      obj.addon_name = event.addon_name;
      obj.addon_unique_id = event.id;
      obj.is_plan_attachment = event.is_plan_attachment;
      obj.subscription_unique_id = this.subscriptionForm.get('subscription_unique_id')?.value;
      this.addons().push(this.Addaddon(obj));
      for (var i = 0; i < this.addons().length; i++) {
        let control = <FormArray>this.subscriptionForm.controls.addon_list;
        let fg: any = control.get([i]);
        
        if (obj.addon_unique_id == fg.get('addon_unique_id').value) {
          fg.get('addon_price_breakdown').setValue(pricebreakdown);
        }
      }
    }
    await this.ShowBillingBreakdown();
    await this.HideFlatFeeAddons();

  }

  async onchargeselect(event: any) {
    var obj = new subscriptioncharge();

    var data: any = this.ExistSubObj.sub_charge.filter(x => x.id == event.id);
    if (data.length > 0) {
      if (data[0].sub_charge_schedular.tiers[0].pricing_structure != 1) {
        var pricebreakdown = Array<PriceBreakdown>();
        obj.charge_price = data[0].sub_charge_schedular.tiers[0].price;
        obj.charge_quantity = 1;
        var pricebdown = new PriceBreakdown();
        pricebdown.unit = 1;
        pricebdown.price = data[0].sub_charge_schedular.tiers[0].price;
        pricebreakdown.push(pricebdown);
        obj.charge_price_breakdown = new Array<PriceBreakdown>();
        obj.charge_price_breakdown = pricebreakdown;
      }
      else {
        obj.charge_quantity = 0;
        obj.charge_price = data[0].sub_charge_schedular.tiers[0].price;
      }
      obj.charge_price_structure = data[0].sub_charge_schedular.tiers[0].pricing_structure;
      obj.charge_schedule_unique_id = data[0].charge_schedule_unique_id;
      obj.charge_name = event.charge_name;
      obj.charge_unique_id = event.id;
      obj.is_plan_attachment = event.is_plan_attachment;
      obj.subscription_unique_id = this.subscriptionForm.get('subscription_unique_id')?.value;
      this.charges().push(this.AddCharge(obj));
      for (var i = 0; i < this.charges().length; i++) {
        let control = <FormArray>this.subscriptionForm.controls.charge_list;
        let fg: any = control.get([i]);
        if (obj.charge_unique_id == fg.get('charge_unique_id').value) {
          fg.get('charge_price_breakdown').setValue(pricebreakdown);
        }

      }
    }

    await this.ShowBillingBreakdown();
    await this.HideFlatFeeCharges();
    await this.HideFlatFeeAddons();
  }

  async GetAddon() {
    this.selectedaddon = [];
    this.toggleChangePlan = false;
    this.toggleChangeCharge = false;
    this.ShowItemDetail = false;
    this.toggleChangeAddon = true;
   
    var data: any = this.ExistSubObj.sub_addon;
    this.addonlist = data.filter(x => x.addon_schedular_list.length > 0);
    this.addonlist = this.addonlist.filter((objFromA: { id: any; }) => {
      return !this.addons().value.find(function (objFromB: { addon_unique_id: any; }) {
        return objFromA.id === objFromB.addon_unique_id
      })
    })
    for (var i of this.addonlist) {
      var addon = i.addon_schedular_list.filter(x => x.billing_frequency == this.plan_subscription_frequency);
      if (addon.length == 0) {
        const index = this.addonlist.indexOf(i);
        if (index > -1) {
          this.addonlist.splice(index, 1);
        }
      }
    }

  }

  async GetPlanPricingModel() {
    var request = { "subscription_unique_id": this.subscription_unique_id, "item_unique_id": this.subscriptionForm.get('plan_unique_id')?.value, "item_type": 1, "company_unique_id": this.company_unique_id }
    const response: WebApiResponse = await this._subscriptionservice.GetSubscriptionItemPricing(request);
    if (response.code == 1) {
      var data: any = response.data;
      this.pricelist = [];
      this.pricelist = data;
      this.plan_pricing_structure = this.pricelist[0].pricing_structure;
      if (this.plan_pricing_structure == 3) {
        this.pricing_structure_name = "Tiered";
      }
      if (this.plan_pricing_structure == 5) {
        this.pricing_structure_name = "Volume";
      }
      if (this.plan_pricing_structure == 4) {
        this.pricing_structure_name = "StairStep";
      }
      for (var i of this.pricelist) {
        if (i.unit_to == 0) {
          i.unit_to = "& above";
        }
      }
    }
  }
  async GetInvoicePriceBreakdown(NewQuantity: any, OldQuantity: any, PricingStructure: any, PriceList: any, ExistPrice: any) {
    var response = new InvoicePriceResponse();
    var price = 0;
    var credit = 0;
    var listpricebreakdown = Array<PriceBreakdown>();
    if (PricingStructure == '3') {
      var totalunit = 0;
      for (var i of PriceList) {
        if (i.unit_to > 0) {
          totalunit += i.unit;
          if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to) && (NewQuantity >= i.unit_from && NewQuantity <= i.unit_to)) {
            var unit = NewQuantity - OldQuantity;
            OldQuantity = 0;
            if (unit > 0) {
              var pricepertier = i.price * unit;
              price += pricepertier;
              var pricebdown = new PriceBreakdown();
              pricebdown.price = i.price;
              pricebdown.unit = unit;
              listpricebreakdown.push(pricebdown);
            }
          }
          else if ((NewQuantity >= i.unit_from && NewQuantity <= i.unit_to) || ((OldQuantity != i.unit_to) && (i.unit_to > totalunit))) {
            if (i.unit > 0) {
              var unitone = i.unit;
              var pricepertierone = i.price * unitone;
              price += pricepertierone;
              var pricebdown = new PriceBreakdown();
              pricebdown.price = i.price;
              pricebdown.unit = unitone;
              listpricebreakdown.push(pricebdown);
              NewQuantity = 0;
            }
          }
          else if (((OldQuantity != i.unit_to) && (i.unit_to == totalunit && i.unit_to > OldQuantity))) {
            if (i.unit > 0) {
              var unitonet = 0;
              if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to)) {
                unitonet = i.unit_to - OldQuantity;
              }
              else {
                unitonet = i.unit;
              }

              var pricepertierone = i.price * unitonet;
              price += pricepertierone;
              var pricebdown = new PriceBreakdown();
              pricebdown.price = i.price;
              pricebdown.unit = unitonet;
              listpricebreakdown.push(pricebdown);
              NewQuantity = 0;
            }
          }
        }
        else if (i.unit > 0) {
          var unitonerr = 0;
          if (NewQuantity == 0) {
            unitonerr = i.unit;
          }
          else {
            unitonerr = NewQuantity - OldQuantity;
          }
          var pricepertierone = i.price * unitonerr;
          price += pricepertierone;
          var pricebdown = new PriceBreakdown();
          pricebdown.price = i.price;
          pricebdown.unit = unitonerr;
          listpricebreakdown.push(pricebdown);
          OldQuantity = 0;
        }

      }
    }
    else if (PricingStructure == '4') {
      for (var i of PriceList) {
        if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to) && (NewQuantity >= i.unit_from && NewQuantity <= i.unit_to)) {
          var unit = i.unit - OldQuantity;
          price += i.price;
        }
        else {
          if (i.unit > 0) {
            var unitoner = NewQuantity;
            price += i.price;
            credit = credit + ExistPrice;
          }
        }
      }
    }
    else if (PricingStructure == '5') {
      for (var i of PriceList) {
        if ((OldQuantity >= i.unit_from && OldQuantity <= i.unit_to) && (NewQuantity >= i.unit_from && NewQuantity <= i.unit_to)) {
          var unit = i.unit - OldQuantity;
          price += unit * i.price;
        }
        else {
          if (i.unit > 0) {
            var unitonere = NewQuantity;
            price += unitonere * i.price;
            credit = credit + ExistPrice;
          }
        }
      }
    }
    response.price_breakdown = listpricebreakdown;
    response.price = price;
    response.credit = credit;
    return response;
  }

  async AddSubscriptionPrice(quantityParam: any, Type: number, index: number) {
    var quantity = quantityParam;
    this.TotalUnits = quantity;
    this.TotalAmounts = 0;
    if (Type == 1) {
      if (this.plan_free_quantity >= quantity) {
        quantity = 0;
      }
      else {
        quantity = quantity - this.plan_free_quantity;
      }
    }
    if (this.pricelist[0].pricing_structure == 3) {
      var remainingquantity = 0;
      for (var i = 0; i < this.pricelist.length; i++) {
        if (i == 0) {
          if (quantity > this.pricelist[i].unit_to) {
            var newquantity = 0;
            remainingquantity = quantity - this.pricelist[i].unit_to;
            newquantity = quantity - remainingquantity;
            this.pricelist[i].unit = newquantity;
            this.pricelist[i].amount = this.pricelist[i].price * newquantity;
          }
          else {
            this.pricelist[i].unit = quantity;
            this.pricelist[i].amount = this.pricelist[i].price * quantity;
            remainingquantity = 0;
          }
        }
        else {
          if (remainingquantity > 0) {
            var newquantity = 0;
            if (this.pricelist[i].unit_to > 0) {
              var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
              if (remainingquantity > count) {
                remainingquantity = remainingquantity - count;
                this.pricelist[i].unit = count;
                this.pricelist[i].amount = this.pricelist[i].price * count;
              }
            }
            else {
              this.pricelist[i].unit = remainingquantity;
              this.pricelist[i].amount = this.pricelist[i].price * remainingquantity;
              remainingquantity = 0;
            }
          }
          else {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;
          }
        }

      }

    }
    if (this.pricelist[0].pricing_structure == 4) {
      for (var i = 0; i < this.pricelist.length; i++) {
        var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (quantity == 0) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else if (isNaN(count)) {
          var j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = quantity;
            this.pricelist[i].amount = this.pricelist[i].price;
          }
        }
        else if (quantity >= this.pricelist[i].unit_from && quantity <= this.pricelist[i].unit_to) {
          this.pricelist[i].unit = quantity;
          this.pricelist[i].amount = this.pricelist[i].price;
          quantity = 0;

        }
        else {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
      }
    }
    if (this.pricelist[0].pricing_structure == 5) {
      for (var i = 0; i < this.pricelist.length; i++) {
        var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (quantity == 0) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else if (isNaN(count)) {
          var j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = quantity;
            this.pricelist[i].amount = this.pricelist[i].price * quantity;
          }
        }
        else if (quantity >= this.pricelist[i].unit_from && quantity <= this.pricelist[i].unit_to) {
          this.pricelist[i].unit = quantity;
          this.pricelist[i].amount = this.pricelist[i].price * quantity;
          quantity = 0;

        }
        else {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
      }
    }
    if (this.pricelist[0].pricing_structure > 2) {
      for (var i = 0; i < this.pricelist.length; i++) {
        this.TotalAmounts += this.pricelist[i].amount;
      }
    }
    else if (this.pricelist[0].pricing_structure == 2) {
      this.TotalAmounts = this.pricelist[0].price * quantity;
    }
    else if (this.pricelist[0].pricing_structure == 1) {
      this.TotalAmounts = this.pricelist[0].price;
    }

    if (Type == 1) {
      this.subscription_plan_price = this.TotalAmounts;
    }

    if (Type == 2) {
      let control = <FormArray>this.subscriptionForm.controls.addon_list;
      let fg: any = control.get([index]);
      fg.get('addon_price').setValue(this.TotalAmounts);
    }

    else if (Type == 3) {
      let control = <FormArray>this.subscriptionForm.controls.charge_list;
      let fg: any = control.get([index]);
      fg.get('charge_price').setValue(this.TotalAmounts);
    }
  }
  async GetAddonChargePricingModel(ItemuniqueId: string, Itemtype: number) {
    var request = { "subscription_unique_id": this.subscription_unique_id, "item_unique_id": ItemuniqueId, "item_type": Itemtype, "company_unique_id": this.company_unique_id }
    const response: WebApiResponse = await this._subscriptionservice.GetSubscriptionItemPricing(request);
    if (response.code == 1) {
      var data: any = response.data;
      this.pricelist = [];
      this.pricelist = data;
      this.plan_pricing_structure = this.pricelist[0].pricing_structure;
      if (this.plan_pricing_structure == 3) {
        this.pricing_structure_name = "Tiered";
      }
      if (this.plan_pricing_structure == 5) {
        this.pricing_structure_name = "Volume";
      }
      if (this.plan_pricing_structure == 4) {
        this.pricing_structure_name = "StairStep";
      }
      for (var i of this.pricelist) {
        if (i.unit_to == 0) {
          i.unit_to = "& above";
        }
      }
    }
  }


  async HideFlatFeeAddons() {
    setTimeout(() => {
      var rawvalue = this.subscriptionForm.getRawValue();
      var addonlist = rawvalue.addon_list;
      let control = <FormArray>this.subscriptionForm.controls.addon_list;
      for (let i = 0; i < addonlist.length; i++) {
        let fg: any = control.get([i]);
        if (fg.get('addon_price_structure')?.value == 1) {
          const output = document.getElementById('addonquantity' + i);
          const breakdown = document.getElementById('addonbreakdown' + i);
          if (breakdown) {
            breakdown.style.display = 'none';
          }
          if (output) {
            output.style.display = 'none';
          }
        }
        else {
          const output = document.getElementById('addonquantity' + i);
          const breakdown = document.getElementById('addonbreakdown' + i);
          if (breakdown) {
            if (fg.get('addon_price_structure')?.value == 2) {
              breakdown.style.display = 'none';
            }
            else {
              breakdown.style.display = 'block';
            }
          }

          if (output) {
            output.style.display = 'block';
          }
        }
      }
    }, 10);
  }

  async HideFlatFeeCharges() {
    setTimeout(() => {
      var rawvalue = this.subscriptionForm.getRawValue();
      var chargelist = rawvalue.charge_list;
      let control = <FormArray>this.subscriptionForm.controls.charge_list;
      for (let i = 0; i < chargelist.length; i++) {
        let fg: any = control.get([i]);
        if (fg.get('charge_price_structure')?.value == 1) {
          const output = document.getElementById('chargequantity' + i);
          if (output) {
            output.style.display = 'none';
          }
          const breakdown = document.getElementById('chargebreakdown' + i);
          if (breakdown) {
            breakdown.style.display = 'none';
          }
        }
        else {
          const output = document.getElementById('chargequantity' + i);
          if (output) {
            output.style.display = 'block';
          }

          const breakdown = document.getElementById('chargebreakdown' + i);
          if (breakdown) {
            if (fg.get('charge_price_structure')?.value == 2) {
              breakdown.style.display = 'none';

            }
            else {
              breakdown.style.display = 'block';
            }
          }
        }
      }
    }, 10);
  }



  async GetCustomerAccountInfo() {
    var request = { company_unique_id: this.company_unique_id, customer_unique_id: this.customer_unique_id }
    const response: WebApiResponse = await this._customerService.GetAccountDetails(request);
    if (response.code == 1) {
      var data: any = response.data;
      this.customer_Accounts = data.filter(x => x.is_default);;
      this.account_last_four = this.customer_Accounts[0].account_last_four;
    }
  }

  async GetPricingModel(ItemId: string, ItemType: number, Quantity: any) {
    var request = { "company_unique_id": this.company_unique_id, "subscription_unique_id": this.subscription_unique_id, "item_unique_id": ItemId, "item_type": ItemType }
    const response: WebApiResponse = await this._subscriptionservice.GetSubscriptionItemPricing(request);
    if (response.code == 1) {
      var data: any = response.data;
      this.pricelist = [];
      this.pricelist = data;
      this.pricing_structure = this.pricelist[0].pricing_structure;
      for (var i of this.pricelist) {
        if (i.unit_to == 0) {
          i.unit_to = "& above";
        }
      }
    }
    this.ShowPricingBreakdown(Quantity);
  }

  ShowPricingBreakdown(Quantity: number) {
    this.TotalAmounts = 0;
    this.TotalUnits = Quantity;
    if (this.pricelist[0].pricing_structure == 3) {
      var remainingquantity = 0;
      for (var i = 0; i < this.pricelist.length; i++) {
        if (i == 0) {
          if (Quantity > this.pricelist[i].unit_to) {
            var newquantity = 0;
            remainingquantity = Quantity - this.pricelist[i].unit_to;
            newquantity = Quantity - remainingquantity;
            this.pricelist[i].unit = newquantity;
            this.pricelist[i].amount = this.pricelist[i].price * newquantity;
          }
          else {
            this.pricelist[i].unit = Quantity;
            this.pricelist[i].amount = this.pricelist[i].price * Quantity;
            remainingquantity = 0;
          }
        }
        else {
          if (remainingquantity > 0) {
            var newquantity = 0;
            var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
            if (remainingquantity > count) {
              remainingquantity = remainingquantity - count;

              this.pricelist[i].unit = count;
              this.pricelist[i].amount = this.pricelist[i].price * count;
            }
            else {
              this.pricelist[i].unit = remainingquantity;
              this.pricelist[i].amount = this.pricelist[i].price * remainingquantity;
              remainingquantity = 0;
            }
          }
          else {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;
          }
        }

      }

    }
    if (this.pricelist[0].pricing_structure == 4) {
      for (var i = 0; i < this.pricelist.length; i++) {
        var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (isNaN(count)) {
          var j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = Quantity;
            this.pricelist[i].amount = this.pricelist[i].price;
          }
        }
        else if (Quantity > count) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else {
          this.pricelist[i].unit = Quantity;
          this.pricelist[i].amount = this.pricelist[i].price;
        }
      }
    }
    if (this.pricelist[0].pricing_structure == 5) {
      for (var i = 0; i < this.pricelist.length; i++) {
        var count = (this.pricelist[i].unit_to - this.pricelist[i].unit_from) + 1;
        if (Quantity == 0) {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
        else if (isNaN(count)) {
          var j = i - 1;
          if (this.pricelist[j].unit != '-') {
            this.pricelist[i].unit = '-';
            this.pricelist[i].amount = 0;

          } else {
            this.pricelist[i].unit = Quantity;
            this.pricelist[i].amount = this.pricelist[i].price * Quantity;
          }
        }
        else if (Quantity >= this.pricelist[i].unit_from && Quantity <= this.pricelist[i].unit_to) {
          this.pricelist[i].unit = Quantity;
          this.pricelist[i].amount = this.pricelist[i].price * Quantity;
          Quantity = 0;

        }
        else {
          this.pricelist[i].unit = '-';
          this.pricelist[i].amount = 0;

        }
      }
    }
    for (var i = 0; i < this.pricelist.length; i++) {
      this.TotalAmounts += this.pricelist[i].amount;
    }
  }
  async oncouponselect(event: any) {
    var obj = new subscriptioncoupon();
    obj.subscription_unique_id = this.subscriptionForm.get('subscription_unique_id')?.value;
    obj.coupon_unique_id = event.coupon_unique_id;
    obj.coupon_name = event.coupon_name;
    obj.is_active = event.is_active;
    obj.discount_type = event.discount_type;
    if (obj.discount_type == 1) {
      obj.coupon_discount = event.discount_unit;
    }
    obj.discount_unit = event.discount_unit;
    this.coupons().push(this.AddCoupon(obj));
    await this.ShowBillingBreakdown();
  }

  async validatecoupon(couponcode) {
    var request = { company_unique_id: this.company_unique_id, coupon_promo_code: couponcode };
    var response:any = await this._customerService.GetCouponByPromoCode(request);
    if (response.code == 1) {
      var couponuniqueid = response.data.coupon_unique_id;
      if (this.coupons().length == 0) {
        this.oncouponselect(response.data);
      } else {
        for (var i = 0; i < this.coupons().length; i++) {
          let control = <FormArray>this.subscriptionForm.controls.coupon_list;
          let fg: any = control.get([i]);
          if (couponuniqueid != fg.get('coupon_unique_id').value) {
            this.oncouponselect(response.data);
          }
          else {
            this.iscouponerror = 'Coupon Already Added';
          }
        }
      }
      const ele = document.getElementById('promocode');
      (<HTMLInputElement>document.getElementById("promocode")).value = "";
    }
    else {
      this.iscouponerror = 'Invalid Coupon';
    }
    this.spinner.hide();
  }
  async removeaddon(i: number) {
    this.addons().removeAt(i);
    await this.ShowBillingBreakdown();
  }
  async removecharge(i: number) {
    this.charges().removeAt(i);
    await this.ShowBillingBreakdown();
  }
  async removecoupon(i: number) {
    this.coupons().removeAt(i);
    await this.ShowBillingBreakdown();
  }

  ApplyCustomerPortalSettings() {
    if (sessionStorage.getItem('SettingJson')) {
      var settings = JSON.parse(sessionStorage.getItem('SettingJson'));
      this.customerportalsettings = settings.CustomerPortalSettings;
      console.log(this.customerportalsettings);
      if (this.customerportalsettings.allowCustomerToChangePlans == '0') {
        this.allowchangeplan = false;
      }
      if (this.customerportalsettings.allowCustomerToChangePlanAddonChargequantity == '0') {
        this.allowchangequantity = false;
      }
      if (this.customerportalsettings.allowCustomerToAddRemoveAddonCharge == '0') {
        this.allowaddchargeaddon = false;
      }
      if (this.customerportalsettings.allowCustomerToRemoveCoupons == '0') {
        this.AllowAddDeletecoupons = false;
        this.ShowAddCoupon = false;
      }
    }

  }

}
