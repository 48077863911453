import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WebApiResponse } from '../model/ApiResponse';
import { AccountService } from '../services/account.service';
import { CustomerService } from '../services/customer.service';
import { DOCUMENT, Location } from '@angular/common';
export declare var CardTokenize: Function;

@Component({
  selector: 'app-paymentmethod',
  templateUrl: './paymentmethod.component.html',
  styleUrls: ['./paymentmethod.component.css']
})
export class PaymentmethodComponent implements OnInit {
  customer_unique_id: string;
  entity_id: any;
  card_list: any = [];
  cardForm: FormGroup;
  IsAddCard: boolean = false;
  CardType: string = '';
  hideCardError: boolean = true;
  image: string = '';
  CardTypeVisa = '';
  Showcards: boolean = true;
  isedit: boolean = false;
  card_type: string = '';
  last_four: any = '';
  cardobj: any;
  is_default_card: boolean = false;
  AddCardBtn: boolean = true;
  GatewaySettings: any;
  static GetInstance: any;

  constructor(private activatedroute: ActivatedRoute,
    private _customerService: CustomerService,
    private _fb: FormBuilder,
    private _accountservice: AccountService, private _location: Location, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {
    PaymentmethodComponent.GetInstance = this;
    this.customer_unique_id = this.activatedroute.snapshot.params["CustomerUniqueId"];
    this.entity_id = this.activatedroute.snapshot.params["EntityId"];

    this.cardForm = this._fb.group({
      "account_first_four": [''],
      "account_last_four": [''],
      "account_number": ['', Validators.required],
      "account_type": [''],
      "account_expiry_date": [''],
      "expMonth": ['', [Validators.required]],
      "expYear": ['', [Validators.required]],
      "account_token": [''],
      "account_cvv": [''],
      "entity_id": [''],
      "customer_unique_id": [''],
      "company_unique_id": [''],
      "is_default": false
    });


  }

  ngOnInit() {
    this.cardForm.get('customer_unique_id').setValue(this.customer_unique_id);
    this.cardForm.get('entity_id').setValue(this.entity_id);
    this.GetCustomerAccountInfo();
  }

  async LoadPaymentTokenScript() {

    let checkoutScript = this._renderer2.createElement('script');
    checkoutScript.type = "text/javascript";
    checkoutScript.src = this.GatewaySettings.gateway_jsurl;
    checkoutScript.setAttribute("data-paymenttokenkey", this.GatewaySettings.gateway_jskey);
    this._renderer2.appendChild(this._document.body, checkoutScript);

  }
  async ShowAddCard() {
    this.IsAddCard = true;
    this.AddCardBtn = false;
    await this.fetchgatewayconfiguration();
    await this.LoadPaymentTokenScript();
  }
  async GetCustomerAccountInfo() {
    let request = { company_unique_id: this.entity_id, customer_unique_id: this.customer_unique_id }
    const response: WebApiResponse = await this._customerService.GetAccountDetails(request);
    if (response.code == 1) {
      this.card_list = response.data;
    }
    else {
      this.IsAddCard = true;
    }
  }

  Redirectback() {
    this._location.back();
  }


  async fetchgatewayconfiguration() {
    let request = { entity_id: 0};
    let response = await this._accountservice.FetchGatewayConfiguration(request);
    if (response.code == 1) {
      this.GatewaySettings = response.data;
    }
  }

  async Save() {
    if (this.cardForm.invalid) {
      return;
    }
    this.cardForm.get('company_unique_id').setValue(sessionStorage.getItem('company_unique_id'));
    this.cardForm.get('account_type').setValue(this.CardType);
    this.cardForm.get('account_first_four').setValue(this.cardForm.get('account_number').value.substring(0, 5));
    this.cardForm.get('account_last_four').setValue(this.cardForm.get('account_number').value.substring(this.cardForm.get('account_number').value.length - 4));
    this.cardForm.get('account_expiry_date')?.setValue(this.cardForm.get('expMonth')?.value + this.cardForm.get('expYear')?.value);
    let n = document.getElementById('onepayResultToken') as HTMLInputElement;
    n.value = '';
    let result = CardTokenize("cardNumber", "expMonth", "expYear", "securityCode", "cardError", "onepayResultToken");
    if (result) {
      let res = (document.getElementById('onepayResultToken') as HTMLInputElement).value;
      let data = JSON.parse(res);
      PaymentmethodComponent.GetInstance.cardForm.get('account_number')?.setValue('');
      if (data.result_code == 1) {
        PaymentmethodComponent.GetInstance.cardForm.get('account_token')?.setValue(data.token);
        const respons: WebApiResponse = await PaymentmethodComponent.GetInstance._accountservice.ManageAccount(PaymentmethodComponent.GetInstance.cardForm.value);
        if (respons.code == 1) {
          this.cardForm.reset();
          this.ngOnInit();
          this.CardType = '';
        }
        else {
          alert(data.message);
        }
      }
    }
  }


  onKeyCardNumber(event) {
    this.CardType = '';
    this.hideCardError = true;
    var number = this.cardForm.get('account_number').value;

    var re = new RegExp("^4");
    var revisa = new RegExp("^4");
    if (number.match(re) != null) {
      var number = this.cardForm.get('account_number').value;
      if (number.length <= 8) {
        if (number.match(re) != null) {
          this.image = "assets/images/visa.svg";
          this.CardType = 'VI';
          return;
        }
      }
      if (number.length >= 16) {
        if (this.luhnCheck(number)) {
          this.image = "assets/images/visa.svg";
          this.CardType = 'VI';
          return;
        }
        else {
          if (this.CardType == '') {
            this.hideCardError = false;
            return;
          }
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    if (number.length <= 8) {
      if (/^(5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12})$/.test(number)) {
        this.image = "assets/images/mastercard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (/^(5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12})$/.test(number) && number.length >= 16) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/mastercard.svg";
        this.CardType = 'MC';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    // AMEX
    re = new RegExp("^3[47]");
    if (number.length <= 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/American.svg";
        this.CardType = 'AX';
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 15) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/American.svg";
        this.CardType = 'AX';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    var number = this.cardForm.get('account_number').value;
    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.length == 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/Discover.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 16) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/Discover.svg";
        this.CardType = 'DC';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          this.image = "";
          return;
        }
      }
    }

    // Diners
    var number = this.cardForm.get('account_number').value;
    re = new RegExp("^36");
    if (number.length <= 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/diners-clubcard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 14) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        this.CardType = 'DS';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.length <= 8) {
      if (number.match(re) != null) {
        this.image = "assets/images/diners-clubcard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 14) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/diners-clubcard.svg";
        this.CardType = 'DS';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.length == 6) {
      if (number.match(re) != null) {
        this.image = "assets/images/Jcbcard.svg";
        return;
      }
    }
    var number = this.cardForm.get('account_number').value;
    if (number.match(re) != null && number.length >= 15) {
      if (this.luhnCheck(number)) {
        this.image = "assets/images/Jcbcard.svg";
        this.CardType = 'JC';
        return;
      }
      else {
        if (this.CardType == '') {
          this.hideCardError = false;
          return;
        }
      }
    }
    if (number.length >= 16) {
      if (this.CardType == '') {
        this.hideCardError = false;
        return;
      }
    }
    if (number.length == 0) {
      this.image = "";
      this.CardType = '';
    }
  }
  luhnCheck(number): boolean {
    let odd = true;
    let i = 0;
    let sum = 0;
    let digit: number;

    if (!number) {
      return false;
    }
    const digits = number.split('').reverse();
    const length = digits.length;
    for (i; i < length; i++) {
      digit = parseInt(digits[i], 10);
      if (odd = !odd) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  }

  editcard(card) {
    this.isedit = true;
    this.cardobj = card;
    this.Showcards = false;
    this.AddCardBtn = false;
    this.IsAddCard = false;
    this.is_default_card = card.is_default;
    this.card_type = card.account_type;
    this.last_four = card.account_last_four;
  }
  async changecarddefault(Isdefault) {

    let request = { is_default: Isdefault, entity_id: this.entity_id, account_info_unique_id: this.cardobj.account_info_unique_id, customer_unique_id: this.customer_unique_id };
    let data: WebApiResponse = await this._accountservice.UpdateDefaultCard(request);
    if (data.code == 1) {
      this.Redirectback();
    }
    else {
      alert('Card not saved');
    }
  }

  Redirectbackcard() {
    this.cardobj = null;
    this.ngOnInit();
    this.Showcards = true;
    this.isedit = false;
    this.AddCardBtn = true;
  }
}
