export class SubscriptionInvoiceDetailsModel {
  public subscription_invoice_detail_id: number = 0;
  public subscription_invoice_id: number = 0;
  public item_description: string = "";
  public item_count: number = 0;
  public item_unit: any;
  public item_price: number = 0;
  public total_amount: number = 0;
  public item_type: number = 0;
  public item_unique_id: string = "";
  public price_breakdown: Array<PriceBreakdown> = [];
}
export class PriceBreakdown {
  public price: number = 0;
  public unit: number = 0;
}

export class InvoicePriceResponse {
  public price_breakdown: Array<PriceBreakdown> = [];
  public price: number = 0;
  public credit: number = 0;
}
export class SubscriptionInvoiceModel {
  public subscription_invoice_id: number = 0;
  public subscription_invoice_unique_id: string = "";
  public entity_id: number = 0;
  public customer_unique_id: string = "";
  public subscription_unique_id: string = "";
  public invoice_from: Date | undefined;
  public invoice_to: Date | undefined;
  public billing_cycle: number = 0;
  public paid_status: number = 0;
  public invoice_amount: number = 0;
  public invoice_date: Date | undefined;
  public transaction_id: string = "";
  public payment_type: number = 0;
  public failed_count: number = 0;
  public discount_amount: number = 0;
  public credit_amount: number = 0;
  public created_date: Date | undefined;
  public invoice_details: any = [];
  public company_unique_id: string;
}
export class subscriptionaddon {
  public subscription_addon_unique_id: string = "";
  public subscription_unique_id: string = "";
  public addon_schedule_unique_id: string = "";
  public addon_name: string = "";
  public addon_unique_id: string = "";
  public addon_price: number = 0;
  public addon_price_breakdown: Array<PriceBreakdown> = [];
  public addon_price_structure: number = 0;
  public addon_quantity: number = 0;
  public addon_frequency: number = 0;
  public addon_pricing_list: Array<PlanPriceModel> = [];
  public is_active: boolean = false;
  public is_plan_attachment: boolean = false;
}
export class subscriptioncharge {
  public subscription_charge_unique_id: string = "";
  public subscription_unique_id: string = "";
  public charge_schedule_unique_id: string = "";
  public charge_name: string = "";
  public charge_unique_id: string = "";
  public charge_price: number = 0;
  public charge_price_breakdown: Array<PriceBreakdown> = [];
  public charge_price_structure: number = 0;
  public charge_quantity: number = 0;
  public is_active: boolean = false;
  public is_plan_attachment: boolean = false;
}
export class subscriptioncoupon {
  public subscription_coupon_unique_id: string = "";
  public subscription_unique_id: string = "";
  public coupon_name: string = "";
  public discount_type: number = 0;
  public discount_unit: number = 0;
  public coupon_unique_id: string = "";
  public is_active: boolean = false;
  public coupon_discount: number = 0;
}
export class PlanPriceModel {
  public plan_price_unique_id: string = '';
  public pricing_structure: number = 0;
  public price: any;
  public unit_from: number = 0;
  public unit_to: any;
  public is_active: boolean = false;
}
export enum ItemType {
  Plan = 1,
  Addon = 2,
  Charge = 3,
  Coupon = 4,
  Customer = 5,
  User = 6

}
export class SettingRequest {
  public setting_json: string | undefined;
  public setting_type: number | undefined;
  public entity_id: number | undefined;
  public company_unique_id: string | undefined;
}
