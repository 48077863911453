import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { EditsubscriptionComponent } from './editsubscription/editsubscription.component';
import { ViewsubscriptionComponent } from './viewsubscription/viewsubscription.component';
import { PaymentHistoryComponent } from './paymenthistory/paymenthistory.component';
import { ShippingdetailsComponent } from './shippingdetails/shippingdetails.component';
import { PaymentmethodComponent } from './paymentmethod/paymentmethod.component';
import { ViewinvoiceComponent } from './viewinvoice/viewinvoice.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { VieworderComponent } from './vieworder/vieworder.component';
import { VieworderinvoiceComponent } from './vieworderinvoice/vieworderinvoice.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'viewsubscription/:EntityId/:SubscriptionUniqueId/:CustomerUniqueId', component: ViewsubscriptionComponent },
  { path: 'editsubscription/:EntityId/:SubscriptionUniqueId/:CustomerUniqueId', component: EditsubscriptionComponent },
  { path: 'paymenthistory/:EntityId/:CustomerUniqueId/:SubscriptionUniqueId', component: PaymentHistoryComponent },
  { path: 'shippingdetails/:EntityId/:CustomerUniqueId', component: ShippingdetailsComponent },
  { path: 'paymentmethod/:EntityId/:CustomerUniqueId', component: PaymentmethodComponent },
  { path: 'viewinvoice/:EntityId/:InvoiceId', component: ViewinvoiceComponent },
  { path: 'vieworderinvoice/:EntityId/:InvoiceId', component: VieworderinvoiceComponent  },
  { path: 'vieworder/:EntityId/:OrderId', component: VieworderComponent },
  { path: 'checkout', component: CheckoutComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
