<header class="header">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <div class="fs-5 fw-bold cursor-pointer" (click)="Redirectback()">
      <i class="fas fa-chevron-left me-2"></i>View Subscription 
    </div>
    <div class="cursor-pointer">
      <img src="assets/images/sign-out.svg" alt="Sign Out" title="Sign Out" routerLink="/login" />
    </div>
  </div>
</header>
<div class="container-xxl px-lg-5 pt-20">
  <div class="section">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
    <div class="row" style="margin-top: -20px;" *ngIf="IsPauseConfirmation">
      <div class="col-12">
        <div class="p-3 mb-2 rounded-2 bg-danger bg-gradient text-white d-flex justify-content-start align-items-center">
          <div>Do you want to Pause your subscription</div>
          <div>
            <button class="btn btn-sm btn-outline-light ms-3" (click)="changesubscriptionstatus(2)">Yes</button>
            <button class="btn btn-sm btn-outline-light ms-3" (click)="pausecancel()">No</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: -20px;" *ngIf="IsCancelConfirmation">
      <div class="col-12">
        <div class="p-3 mb-2 rounded-2 bg-danger bg-gradient text-white d-flex justify-content-start align-items-center">
          <div>Do you want to Cancel your subscription</div>
          <div>
            <button class="btn btn-sm btn-outline-light ms-3" (click)="changesubscriptionstatus(3)">Yes</button>
            <button class="btn btn-sm btn-outline-light ms-3" (click)="pausecancel()">No</button>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
      <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <img src="..." class="rounded me-2" alt="...">
          <strong class="me-auto">Bootstrap</strong>
          <small>11 mins ago</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          Hello, world! This is a toast message.
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-8 d-flex mb-3">
        <div class="row flex-grow-1">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel 1mb-6 1mb-xl-9 flex-grow-1">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row justify-content-between bg-primary bg-opacity-25 p-4 fw-bold fs-5 gx-5 border-top-left-radius-sm border-top-right-radius-sm mx-0">
                      <div class="col">Items</div>
                      <div class="col-3 col-md-2 text-end">Quantity</div>
                      <div class="col-3 col-md-2 text-end">Amount</div>
                    </div>
                    <div class="row justify-content-between p-4 fs-6 gx-5 mx-0">
                      <div class="col">
                        {{plan_name}}
                        <div *ngFor="let i of  plan_price_breakdown">
                          <div class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                        </div>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span *ngIf="!IsMeteredPlan">{{plan_quantity}} </span>
                        <span *ngIf="IsMeteredPlan">
                          Metered
                        </span>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span *ngIf="!IsMeteredPlan">
                          $ {{plan_amount}}
                        </span>
                        <span *ngIf="IsMeteredPlan">
                          <strong>-</strong>
                        </span>
                      </div>
                    </div>
                    <div class="row justify-content-between p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let addon of addonlist">
                      <div class="col">
                        <div>{{addon.addon_name}}</div>
                        <div *ngFor="let pb of addon.addon_price_breakdown">
                          <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                        </div>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span *ngIf="!addon.is_metered_addon && addon.addon_quantity > 0">
                          {{addon.addon_quantity}}
                        </span>
                        <span *ngIf="addon.addon_quantity == 0">
                          -
                        </span>
                        <span *ngIf="addon.is_metered_addon">
                          Metered
                        </span>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span *ngIf="!addon.is_metered_addon">
                          $ {{addon.addon_price}}
                        </span>
                        <span *ngIf="addon.is_metered_addon">
                          <strong>-</strong>
                        </span>
                      </div>
                    </div>
                    <div class="row justify-content-between p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let charge of chargelist">
                      <div class="col">
                        <div>{{charge.charge_name}}</div>
                        <div *ngFor="let pb of charge.charge_price_breakdown">
                          <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                        </div>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span *ngIf="charge.charge_price_structure == 1">- </span>
                        <span *ngIf="charge.charge_price_structure != 1">
                          {{charge.charge_quantity}}
                        </span>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span>
                          $ {{charge.charge_price}}
                        </span>
                      </div>
                    </div>
                    <div class="row justify-content-between p-4 fs-6 gx-5 mx-0 border-top border-bottom" *ngFor="let charge of chargelist">
                      <div class="col">
                        <div>{{coupon.coupon_name}}</div>
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        
                      </div>
                      <div class="col-3 col-md-2 text-end">
                        <span>
                          $ {{coupon.coupon_discount}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="1shadow-panel 1mt-3 1px-4">
                  <table class="table align-middle table-row-dashed gy-4">
                    <thead>
                      <tr class="border-bottom bg-primary bg-opacity-25 border-gray-200 fs-5 text-gray-800 fw-bold">
                        <th class="border-top-left-radius-sm ps-5" scope="col">Item</th>
                        <th scope="col">No. of Units</th>
                        <th class="border-top-right-radius-sm pe-5 text-end" scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody class="border-0">
                      <tr>
                        <td class="align-middle ps-5">
                          {{plan_name}}
                          <div *ngFor="let i of  plan_price_breakdown">
                            <div class="text-success fs-7 fw-bold">$ {{i.price}} * {{i.unit}}</div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span *ngIf="!IsMeteredPlan">{{plan_quantity}} </span>
                          <span *ngIf="IsMeteredPlan">
                            Metered
                          </span>
                        </td>
                        <td class="align-middle text-end pe-5">
                          <span *ngIf="!IsMeteredPlan">
                            $ {{plan_amount}}
                          </span>
                          <span *ngIf="IsMeteredPlan">
                            <strong>-</strong>
                          </span>
                        </td>
                      </tr>
                      <tr *ngFor="let addon of addonlist">
                        <td class="align-middle ps-5">
                          {{addon.addon_name}}<br />
                          <div *ngFor="let pb of addon.addon_price_breakdown">
                            <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span *ngIf="!addon.is_metered_addon && addon.addon_quantity > 0">
                            {{addon.addon_quantity}}
                          </span>
                          <span *ngIf="addon.addon_quantity == 0">
                            -
                          </span>
                          <span *ngIf="addon.is_metered_addon">
                            Metered
                          </span>
                        </td>
                        <td class="align-middle text-end pe-5">
                          <span *ngIf="!addon.is_metered_addon">
                            $ {{addon.addon_price}}
                          </span>
                          <span *ngIf="addon.is_metered_addon">
                            <strong>-</strong>
                          </span>
                        </td>
                      </tr>
                      <tr *ngFor="let charge of chargelist">
                        <td class="align-middle ps-5">
                          {{charge.charge_name}}<br />
                          <div *ngFor="let pb of charge.charge_price_breakdown">
                            <div class="text-success fs-7 fw-bold">$ {{pb.price}} * {{pb.unit}}</div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span *ngIf="charge.charge_price_structure == 1">- </span>
                          <span *ngIf="charge.charge_price_structure != 1">
                            {{charge.charge_quantity}}
                          </span>
                        </td>
                        <td class="align-middle text-end pe-5">$   {{charge.charge_price}}</td>
                      </tr>
                      <tr *ngFor="let coupon of couponlist">
                        <td class="align-middle ps-5">
                          {{coupon.coupon_name}}
                        </td>
                        <td class="align-middle">
                        </td>
                        <td class="align-middle text-end pe-5">
                          $ {{coupon.coupon_discount}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex flex-column">
        <div class="row flex-grow-1 mb-3">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Next Invoice</div>
              </div>
              <div class="card-body p-6">
                <div class="row">
                  <div class="col-6">
                    <div class="fw-bold">{{NextBillingDate}}</div>
                    <div>{{frequency_name}}</div>
                  </div>
                  <div class="col-6 d-flex justify-content-end text-center">
                    <div class="">
                      <div class="badge bg-primary p-2 text-dark bg-opacity-25 fs-3">$ {{next_subscription_price}}  *</div>
                      <div class="text-muted" style="font-size: 12px;">* Excluding Tax</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex-grow-1 mb-3" *ngIf="customer_default_card.length > 0">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Payment Method</div>
              </div>
              <div class="card-body p-6">
                <div class="row align-items-center">
                  <div class="col-8 d-flex align-items-center">
                    <div class="me-2">
                      <img *ngIf="account_type == 'VI'" src="assets/images/visa.svg" style="width: 50px;" />
                      <img *ngIf="account_type == 'MC'" src="assets/images/mastercard.svg" style="width: 50px;" />
                      <img *ngIf="account_type == 'AX'" src="assets/images/American.svg" style="width: 50px;" />
                      <img *ngIf="account_type == 'DS'" src="assets/images/Discover.svg" style="width: 50px;" />
                    </div>
                    <div class="fw-bold">XXXX XXXX XXXX {{account_last_four}}</div>
                  </div>
                  <div class="col-4 d-flex justify-content-end align-items-end">
                    <div class="">
                      Expires {{expiry_date | CardMonth}}  20{{expiry_date | slice:2}} 
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="customer_default_card.length == 0">
                  <button class="btn btn-primary">Add Card</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex-grow-1 mb-3">
          <div class="col-md-12 d-flex">
            <div class="card shadow-panel flex-grow-1">
              <div class="card-header d-flex bg-primary bg-opacity-25 align-items-center text-center px-6">
                <div class="fs-5 text-gray-800 fw-bold mb-1">Quick Links</div>
              </div>
              <div class="card-body p-6">
                <div class="row" *ngIf="subscription_status == 1 || subscription_status == 0">
                  <div class="col-sm-12">
                    <div class="fs-6 mb-3" (click)="editsubscription()" *ngIf="allowchangesubscription">
                      <a class="text-dark first after">Edit Subscription</a>
                    </div>
                    <div class="fs-6 mb-3" (click)="pausesubscriptionconfirm()" *ngIf="allowpausesubscription">
                      <a class="text-dark first after">Pause Subscription</a>
                    </div>
                    <div class="fs-6 mb-3" (click)="cancelsubscriptionconfirm()" *ngIf="allowcancelsubscription">
                      <a class="text-dark first after">Cancel Subscription</a>
                    </div>
                    <div class="fs-6 mb-3" (click)="showPaymentMethod()">
                      <a class="text-dark first after">Payment Method</a>
                    </div>
                    <div class="fs-6 mb-3" (click)="showPaymentHistory()">
                      <a class="text-dark first after">Payment History</a>
                    </div>
                    <div class="fs-6 mb-3" (click)="showShippingDetails()">
                      <a class="text-dark first after">Update billing details</a>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="subscription_status == 2 && allowreactivatesubscription">
                  <div class="col-md-6">
                    <div class="fs-6 mb-3" (click)="changesubscriptionstatus(1)">
                      <a class="text-dark first after">Resume Subscription</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="view-tiered-breakdown" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mw-650px">
    <div class="modal-content">
      <div class="modal-header">
        <div class="fs-5 fw-bold">{{pricing_structure_name}} Pricing Breakdown</div>
        <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
          <i class="fas fa-times fa-fw"></i>
        </div>
      </div>
      <div class="modal-body py-10">
        <table class="table table-rounded table-row-bordered border gy-4 gs-7">
          <thead>
            <tr class="fw-bolder fs-6 text-gray-800">
              <th colspan="1">Units: From - To</th>
              <th colspan="1">Unit</th>
              <th colspan="1" class="text-center">Price Per Unit</th>
              <th colspan="1" class="text-end">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let price of pricelist; let i=index">
              <td>
                <label>{{price.unit_from}} - {{price.unit_to}}</label>
              </td>
              <td>
                <label>{{price.unit}}</label>
              </td>
              <td class="text-center">
                <label>{{price.price}}</label>
              </td>
              <td class="text-end">
                <label>${{price.amount}}</label>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <strong>Total</strong>
              </td>
              <td class="text-center">
                {{TotalUnits}}
              </td>
              <td class="text-end">
                $ {{TotalAmounts}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
