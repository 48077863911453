import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { WebApiResponse } from '../model/ApiResponse';
import { SubscriptionService } from '../services/subscription.service';
import { Location } from '@angular/common';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-viewinvoice',
  templateUrl: './viewinvoice.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./viewinvoice.component.css']
})
export class ViewinvoiceComponent implements OnInit {
  customer_info: any;
  sub_invoice: any;
  sub_invoice_details: any;
  total_amount: any = 0;
  total_after_discount: any = 0;
  discount_amount: any = 0;
  invoice_id: any;
  entity_unique_id: any;
  invoice_date: any;
  credit_amount: any;
  name: any;
  shipping_address1: any;
  shipping_address2: any;
  shipping_city: any;
  shipping_state: any;
  shipping_country: any;
  shipping_zipcode: any;
  company_name: any;
  company_address1: any;
  company_address2: any;
  company_country: any;
  company_state: any;
  company_city: any;
  company_zipcode: any;
  paid_status: any;
  company_logo: any = 'assets/images/Infify-logo.png';
  invoice_from: string;
  invoice_to: string;
  subscription_invoice_id: any;
  customer_unique_id: any;
  entity_info: any;
    displaycustid: any;

  constructor(private _subscriptionservice: SubscriptionService, private activatedroute: ActivatedRoute,
    private _location: Location, private accountservice: AccountService) {
    this.invoice_id = this.activatedroute.snapshot.params["InvoiceId"];
    this.entity_unique_id = this.activatedroute.snapshot.params["EntityId"];

  }

  async ngOnInit() {
    if (this.invoice_id != '' && this.invoice_id != undefined) {
      await this.GetSubscriptionInvoiceDetail(this.invoice_id);
    }
  }


  async GetSubscriptionInvoiceDetail(invoice_id) {
    let request = { entity_unique_id: this.entity_unique_id, subscription_invoice_unique_id: invoice_id }
    const response: WebApiResponse = await this._subscriptionservice.GetSubscriptionInvoiceDetail(request);
    if (response.code == 1) {
      let data: any = response.data;
      this.customer_info = data.customer_info;
      this.entity_info = data.entity_info;
      this.sub_invoice = data.subscriptionInvoice;
      this.sub_invoice_details = data.invoice_details;
      this.name = this.customer_info.name;
      this.shipping_address1 = this.customer_info.shipping_address1;
      this.shipping_address2 = this.customer_info.shipping_address2;
      this.shipping_city = this.customer_info.shipping_city;
      this.shipping_state = this.customer_info.shipping_state;
      this.shipping_country = this.customer_info.shipping_country;
      this.shipping_zipcode = this.customer_info.shipping_zipcode;
      this.customer_unique_id = this.customer_info.customer_unique_id;
      this.displaycustid = this.customer_unique_id.substring(0, 7);
      this.company_name = this.entity_info.entity_name;
      this.company_address1 = this.entity_info.entity_address1;
      this.company_address2 = this.entity_info.entity_address2;
      this.company_country = this.entity_info.entity_country_name;
      this.company_state = this.entity_info.entity_state_name;
      this.company_city = this.entity_info.entity_city;
      this.company_zipcode = this.entity_info.entity_zipcode;
      if (this.entity_info.entity_logo != undefined && this.entity_info.entity_logo != '') {
        this.company_logo = this.entity_info.entity_logo;
      }
      else {
        this.company_logo = 'assets/images/logo1.png';
      }
      this.sub_invoice = data.subscriptionInvoice;
      this.sub_invoice_details = data.invoice_details;
      this.subscription_invoice_id = this.sub_invoice.subscription_invoice_id;
      this.invoice_date = moment(this.sub_invoice.invoice_date).format('MM/DD/YYYY');
      this.invoice_from = moment(this.sub_invoice.invoice_from).format('MM/DD/YYYY');
      this.invoice_to = moment(this.sub_invoice.invoice_to).format('MM/DD/YYYY');
      this.paid_status = this.sub_invoice.paid_status;
      for (let i of this.sub_invoice_details) {
        if (i.item_type == 4) {
          this.discount_amount += i.total_amount;
        }
        else {
          this.total_amount += i.total_amount;
          if (i.response_price_breakdown != null && i.response_price_breakdown != '') {
            i.response_price_breakdown = JSON.parse(i.response_price_breakdown);
            for (let j of i.response_price_breakdown) {
              j.price = j.price.toFixed(2);
            }
          }
          i.item_price = i.item_price.toFixed(2);
          i.total_amount = i.total_amount.toFixed(2);
        }

      }
      this.total_after_discount = this.total_amount - (this.discount_amount + this.sub_invoice.credit_amount);
      this.total_amount = this.total_amount.toFixed(2);
      this.total_after_discount = this.total_after_discount.toFixed(2);
      this.discount_amount = this.discount_amount.toFixed(2);
      this.credit_amount = this.sub_invoice.credit_amount.toFixed(2);
    }

  }

  Redirectback() {
    this._location.back();
  }

}
