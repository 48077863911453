<div class="container-xxl mx-0 px-lg-5 pt-20">
  <div class="section" *ngIf="IsLinkValid">
    <div class="1row 1justify-content-center">
      <div class="1col-md-8">
        <div class="1row">
          <div class="1col-md-12">
            <div class="shadow-panel p-4">
              <div class="d-flex justify-content-end mb-3">
                <div (click)="pdf.saveAs('SubscriptionInvoice.pdf')" class="btn 1btn-sm btn-primary btn-print">Download</div>
              </div>
              <kendo-pdf-export #pdf paperSize="A4" [scale]="0.7" margin="5mm">
                <div class="card border-0 mb-5 mb-xl-8">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="row mb-3">
                        <div class="col-md-12">
                          <img alt="Logo" [src]="company_logo" class="h-50px logo" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7">
                          <div>
                            <div>{{company_name}}</div>
                            <div>{{company_address1}} {{company_address2}}</div>
                            <div>{{company_city}}, {{company_state}} {{company_zipcode}}</div>
                            <div>{{company_country}}</div>
                            <div class="w-100px"><hr /></div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div>
                            <h2>Sales Order</h2>
                          </div>
                          <div>Id <span class="fw-bold"># {{display_order_id}}</span></div>
                          <div>Date <span class="fw-bold">{{order_date}}</span></div>
                          <div>Amount <span class="fw-bold">$ {{total_amount}} (USD)</span></div>
                          <div>Customer ID <span class="fw-bold">{{displaycustid}}</span></div>
                          <div class="w-100px"><hr /></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7">
                          <div>
                            <div>SHIPPED TO</div>
                          </div>
                        </div>

                      </div>
                      <div class="row mt-10">
                        <div class="col-md-12">
                          <div class="table-responsive mb-9">
                            <table class="table mb-3 border-bottom border-secondary" aria-describedby="Invoice Details">
                              <thead>
                                <tr class="border-bottom border-top border-secondary fs-6 fw-bold">
                                  <th class="min-w-175px pb-2">Items</th>
                                  <th class="min-w-70px text-end pb-2">Selling Price</th>
                                  <th class="min-w-80px text-end pb-2">Quantity</th>
                                  <th class="min-w-100px text-end pb-2">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="fw-bold fs-6 text-end" *ngFor="let item of itemlist">
                                  <td>
                                    <div class="d-flex align-items-center">
                                      {{item.item_name}}
                                    </div>
                                  </td>
                                  <td class="pt-6">$ {{item.selling_price}}</td>
                                  <td class="pt-6">{{item.units}}</td>
                                  <td class="pt-6">$ {{item.amount}} </td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table mb-3" aria-describedby="Invoice Total">
                              <tbody>
                                <tr class="fw-bold fs-6 text-end">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2">Amount</td>
                                  <td class="min-w-100px text-end pb-2">$ {{amount}}</td>
                                </tr>
                                <tr class="fw-bold fs-6 text-end">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2">Discount</td>
                                  <td class="min-w-100px text-end pb-2">$ {{discount}}</td>
                                </tr>
                                <tr class="fw-bold fs-6 text-end py-3">
                                  <td class="min-w-175px pb-2"></td>
                                  <td class="min-w-70px text-end pb-2"></td>
                                  <td class="min-w-80px text-end pb-2 border-top border-bottom border-secondary">Total</td>
                                  <td class="min-w-100px text-end pb-2 border-top border-bottom border-secondary">$ {{total_amount}}</td>
                                </tr>
                                <tr></tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </kendo-pdf-export>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="1row 1justify-content-center">
      <div class="1col-md-8">
        <div class="1row">
          <div class="1col-md-12">
            <div class="shadow-panel p-4">
              <label class="form-label">Notes</label>
              <textarea type="text" class="form-control" [(ngModel)]="notes" placeholder="Enter order notes" name="notes"></textarea>
              <span class="text-danger" *ngIf="isnoteserror">Please enter the notes to reject this order.</span>
              <div class="d-flex justify-content-end mt-3">
                <button class="btn 1btn-sm btn-primary me-2" (click)="onclickapproveorder()">Approve</button>
                <button class="btn 1btn-sm btn-primary me-2" (click)="onclickrejectorder()">Reject</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section" *ngIf="IsLinkInvalid">
    <div class="1row 1justify-content-center">
      <div class="1col-md-8">
        <div class="1row">
          <div class="1col-md-12">
            <div class="shadow-panel p-4">
              <div class="d-flex justify-content-end mb-3">
                Invalid Link
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section" *ngIf="IsRejected">
    <div class="1row 1justify-content-center">
      <div class="1col-md-8">
        <div class="1row">
          <div class="1col-md-12">
            <div class="shadow-panel p-4">
              <div class="d-flex justify-content-end mb-3">
                Order is rejected.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
